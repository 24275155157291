import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdAddCircle,
  MdAddCircleOutline,
  MdArrowBack,
  MdArrowBackIos,
  MdCampaign,
  MdCheckCircle,
  MdClose,
  MdDelete,
  MdMenu,
  MdOutlinePerson,
  MdPerson,
  MdPerson2,
  MdPerson3,
  MdPersonOutline,
  MdPersonPinCircle,
  MdPlayArrow,
  MdPlayCircle,
  MdUploadFile,
  MdWeb,
} from "react-icons/md";

import "../styles/Creator.css";
import TopNavbar from "../components/navbars/DesktopTopNavbar";
import { ServicesBar } from "../components/cards/ServicesBar";
import { FaInstagram } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router";
import MediaPlayer from "../components/mediaPlayer/MediaPlayer";
import { isEmpty } from "../functions/isEmpty";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import { ProductData } from "../contexts/ProductData";
import StoryElement from "../components/CatalogComponent";
import CatalogComponent from "../components/CatalogComponent";

export default function Ads({
  isMobile,
  apiURL,
  authToken,
  services,
  creators,
  activeProductID,
  cart,
  setCart,
  setGlobalActiveCreator,
  templates,
}) {
  const [creatorData, setCreatorData] = useState([]);
  const [categories, setCategories] = useState([
    { name: "Lebensmittel" },
    { name: "Kleidung" },
    { name: "Sport" },
    { name: "Getränke" },
  ]);
  const [activeCategory, setActiveCategory] = useState({
    name: "Lebensmittel",
    subCategories: [
      {
        title: "Essen",
        elements: [
          { name: "Zubereiten" },
          { name: "Verkosten" },
          { name: "Nährwerte vorstellen" },
          { name: "Nährwerte vorstellen" },
          { name: "Nährwerte vorstellen" },
        ],
      },
      {
        title: "Alltag",
        elements: [{ name: "Im Alltag integrieren" }],
      },
    ],
  });

  let location = useLocation().pathname;
  let navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className="home">
      <div className="home_left">
        <div className="home_left_title">
          <div className="home_left_title_left">
            <div className="home_left_title_headline">Content wählen</div>
          </div>
        </div>
        <div className="home_left_scrollbar">
          <div className="home_left_ads">
            <div className="home_left_adstitle"></div>
            <div className="home_left_ads_flexwrapper">
              <div className="home_left_ads_flexwrapper_left">
                <div className="home_left_ads_flexwrapper_left_categories">
                  {categories.map((category) => (
                    <div className="home_left_ads_flexwrapper_left_categories_element">
                      {category.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className="home_left_ads_flexwrapper_right">
                <div className="home_left_ads_flexwrapper_right_subcategories">
                  {activeCategory.subCategories.map((subCat) => (
                    <div className="home_left_ads_flexwrapper_right_subcategories_subcategory">
                      <div className="home_left_ads_flexwrapper_right_subcategories_subcategory_title">
                        {subCat.title}
                      </div>
                      <div className="home_left_ads_flexwrapper_right_subcategories_subcategory_title_elements">
                        {subCat.elements.map((el) => (
                          <div className="home_left_ads_flexwrapper_right_subcategories_subcategory_title_elements_element">
                            <div className="home_left_ads_flexwrapper_right_subcategories_subcategory_title_elements_element_top">
                              <PlaceholderIcon />
                            </div>
                            <div className="home_left_ads_flexwrapper_right_subcategories_subcategory_title_elements_element_bottom">
                              {el.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home_right">
        <div className="home_right_top">
          <div className="home_right_top_headline">
            <div className="home_right_top_headline_left">
              <div className="home_right_top_headline_left_top">
                Influencer Kampagne{" "}
              </div>
              <div className="home_right_top_headline_left_bottom"></div>
            </div>
            <div className="home_right_top_headline_right">
              <div className="home_right_top_headline_right_homebtn">
                <PersonIcon />
              </div>
            </div>
          </div>

          <div className="home_right_top_navpoints">
            {location == "/" || location.substring(0, 6) == "/creator" ? (
              <div className="home_right_top_navpoints_navpoint_active">
                <CampaignIcon /> Creator
              </div>
            ) : (
              <div
                className="home_right_top_navpoints_navpoint"
                onClick={() => navigate("/")}
              >
                <CampaignIcon /> Creator
              </div>
            )}
            {location.substring(0, 4) == "/ads" ? (
              <div className="home_right_top_navpoints_navpoint_active">
                <ContentIcon /> Content
              </div>
            ) : (
              <div
                className="home_right_top_navpoints_navpoint"
                onClick={() => navigate("/ads")}
              >
                <ContentIcon /> Content
              </div>
            )}
            {location.substring(0, 8) == "/landing" ? (
              <div className="home_right_top_navpoints_navpoint_active">
                <UploadIcon /> Materialien
              </div>
            ) : (
              <div
                className="home_right_top_navpoints_navpoint"
                onClick={() => navigate("/landing")}
              >
                <UploadIcon /> Materialien
              </div>
            )}
          </div>
          <div className="home_right_top_pricing">
            <div className="home_right_top_pricing_top">
              <div className="home_right_top_pricing_top_topline">
                Abrechnungsart:
              </div>
              <div className="home_right_top_pricing_top_bottomline">
                €/View
              </div>
            </div>
            <div className="home_right_top_pricing_bottom">
              <div className="home_right_top_pricing_bottom_left">€/ View</div>
              <div className="home_right_top_pricing_bottom_right">1,00€</div>
            </div>
          </div>
          <div className="home_right_bottom_cta">Kampagne starten</div>
        </div>
        <div className="home_right_bottom"></div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const DeleteIcon = styled(MdDelete)`
  font-size: 20px;
  color: #575757;
`;

const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: 1px;
  margin-left: 10px;
`;

const ArrowTopIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  transform: rotateY(180deg) rotate(90deg);
  margin-bottom: -10px;
  margin-left: 10px;
`;

const ArrowBottomIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  transform: rotateY(180deg) rotate(-90deg);
  margin-bottom: 10px;
  margin-left: 10px;
`;

const AddIcon = styled(MdAdd)`
  font-size: 38px;
  color: #ffffff;
  margin-top: 4px;
`;

const RoundAddIcon = styled(MdAddCircle)`
  font-size: 36px;
  color: #f58700;
  margin-top: 4px;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 26px;
  color: #575757;
`;

const PlayIcon = styled(MdPlayArrow)`
  font-size: 33px;
  color: #575757;
`;

const CheckIcon = styled(MdCheckCircle)`
  font-size: 50px;
  color: #575757;
`;

const PersonIcon = styled(MdPerson)`
  font-size: 33px;
  color: #575757;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #575757;
`;

const CampaignIcon = styled(MdCampaign)`
  font-size: 33px;
  color: #575757;
`;

const ContentIcon = styled(MdPlayCircle)`
  font-size: 33px;
  color: #575757;
`;

const WebsiteIcon = styled(MdWeb)`
  font-size: 33px;
  color: #575757;
`;

const PlaceholderIcon = styled(MdCampaign)`
  font-size: 57px;
  color: #575757;
`;

const UploadIcon = styled(MdUploadFile)`
  font-size: 33px;
  color: #575757;
`;
