import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdImage,
  MdMenu,
  MdSearch,
  MdSettings,
} from "react-icons/md";

import "../styles/Profile.css";
import TopNavbar from "../components/navbars/DesktopTopNavbar";
import { ServicesBar } from "../components/cards/ServicesBar";
import { isEmpty } from "../functions/isEmpty";
import { useNavigate } from "react-router";
import Bottomnavbar from "../components/navbars/Bottomnavbar";
import Spinner from "../components/CTA/Spinner";
import DesktopTopNavbar from "../components/navbars/DesktopTopNavbar";
import Footerbar from "../components/navbars/Footerbar";

export default function Payments({
  isMobile,
  apiURL,
  authToken,
  services,
  companyData,
  serviceCategories,
  destroySession,
  reload,
  setReload,
  topnavBusiness,
  setTopnavBusiness,
}) {
  const [profile, setProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [changesHaveBeenMade, setChangesHaveBeenMade] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(companyData)) {
      setProfile(companyData);
    }

    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [companyData, authToken]);

  const handleUpload = async (imgURL) => {
    setIsLoading(true);
    const resa = await fetch(
      `${apiURL}/api/creators/index.php?id=` + companyData.id,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          bankingIBAN: profile.bankingIBAN,
          bankingBIC: profile.bankingBIC,
          bankingHolderName: profile.bankingHolderName,
          bankingTaxNumber: profile.bankingTaxNumber,
          firstname: profile.firstname,
          lastname: profile.lastname,
          streetname: profile.streetname,
          streetnumber: profile.streetnumber,
          zip: profile.zip,
          city: profile.city,
        }),
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setChangesHaveBeenMade(false);
  };

  useEffect(() => {
    if (!isEmpty(companyData)) {
      if (companyData != profile) {
        setChangesHaveBeenMade(true);
      } else {
        setChangesHaveBeenMade(false);
      }
    }
  }, [companyData, profile]);

  const changeIBAN = (value) => {
    let helpObject = { ...profile };
    helpObject.bankingIBAN = value;
    setProfile(helpObject);
  };

  const changeBic = (value) => {
    let helpObject = { ...profile };
    helpObject.bankingBIC = value;
    setProfile(helpObject);
  };

  const changeHolderName = (value) => {
    let helpObject = { ...profile };
    helpObject.bankingHolderName = value;
    setProfile(helpObject);
  };

  const changeTaxNumber = (value) => {
    let helpObject = { ...profile };
    helpObject.bankingTaxNumber = value;
    setProfile(helpObject);
  };

  const changeFirstname = (value) => {
    let helpObject = { ...profile };
    helpObject.firstname = value;
    setProfile(helpObject);
  };

  const changeLastname = (value) => {
    let helpObject = { ...profile };
    helpObject.lastname = value;
    setProfile(helpObject);
  };

  const changeStreetname = (value) => {
    let helpObject = { ...profile };
    helpObject.streetname = value;
    setProfile(helpObject);
  };

  const changeStreetnumber = (value) => {
    let helpObject = { ...profile };
    helpObject.streetnumber = value;
    setProfile(helpObject);
  };

  const changeZip = (value) => {
    let helpObject = { ...profile };
    helpObject.zip = value;
    setProfile(helpObject);
  };

  const changeCity = (value) => {
    let helpObject = { ...profile };
    helpObject.city = value;
    setProfile(helpObject);
  };

  return (
    <div className="profile">
      <DesktopTopNavbar
        topnavBusiness={topnavBusiness}
        setTopnavBusiness={setTopnavBusiness}
        authToken={authToken}
      />

      <div className="home_left">
        <div className="profile_toprow">
          <div className="profile_toprow_left">Auszahlungen</div>
          <div className="profile_toprow_right">
            <div
              className="profile_toprow_right_nav"
              onClick={() => navigate("/profile")}>
              Zurück zum Profil
            </div>
          </div>
        </div>
        <div className="profile_payments_paymentaccount">
          <div className="">
            Rechnungsdaten
            <div className="profile_payments_paymentaccount_inputbox">
              <input
                type="text"
                value={profile.firstname}
                placeholder="Vorname"
                className="profile_payments_paymentaccount_input"
                onChange={(e) => changeFirstname(e.target.value)}
              />
            </div>
            <div className="profile_payments_paymentaccount_inputbox">
              <input
                type="text"
                value={profile.lastname}
                placeholder="Nachname"
                className="profile_payments_paymentaccount_input"
                onChange={(e) => changeLastname(e.target.value)}
              />
            </div>
            <div className="profile_payments_paymentaccount_inputbox">
              <input
                type="text"
                value={profile.streetname}
                placeholder="Straßennummer"
                className="profile_payments_paymentaccount_input"
                onChange={(e) => changeStreetname(e.target.value)}
              />
            </div>
            <div className="profile_payments_paymentaccount_inputbox">
              <input
                type="text"
                value={profile.streetnumber}
                placeholder="Hausnummer"
                className="profile_payments_paymentaccount_input"
                onChange={(e) => changeStreetnumber(e.target.value)}
              />
            </div>
            <div className="profile_payments_paymentaccount_inputbox">
              <input
                type="text"
                value={profile.zip}
                placeholder="Postleitzahl"
                className="profile_payments_paymentaccount_input"
                onChange={(e) => changeZip(e.target.value)}
              />
            </div>
            <div className="profile_payments_paymentaccount_inputbox">
              <input
                type="text"
                value={profile.city}
                placeholder="Stadt"
                className="profile_payments_paymentaccount_input"
                onChange={(e) => changeCity(e.target.value)}
              />
            </div>
          </div>
          <div className="">
            Auszahlungskonto
            <div className="profile_payments_paymentaccount_inputbox">
              <input
                type="text"
                value={profile.bankingHolderName}
                placeholder="Kontoinhaber"
                className="profile_payments_paymentaccount_input"
                onChange={(e) => changeHolderName(e.target.value)}
              />
            </div>
            <div className="profile_payments_paymentaccount_inputbox">
              <input
                type="text"
                value={profile.bankingIBAN}
                placeholder="IBAN"
                className="profile_payments_paymentaccount_input"
                onChange={(e) => changeIBAN(e.target.value)}
              />
            </div>
            <div className="profile_payments_paymentaccount_inputbox">
              <input
                type="text"
                value={profile.bankingBIC}
                placeholder="BIC"
                className="profile_payments_paymentaccount_input"
                onChange={(e) => changeBic(e.target.value)}
              />
            </div>
            <div className="profile_payments_paymentaccount_inputbox">
              <input
                type="text"
                value={profile.bankingTaxNumber}
                placeholder="Steuernummer oder SteuerID"
                className="profile_payments_paymentaccount_input"
                onChange={(e) => changeTaxNumber(e.target.value)}
              />
            </div>
            {changesHaveBeenMade && (
              <div className="profile_uploadbtn" onClick={() => handleUpload()}>
                Speichern
              </div>
            )}
          </div>
        </div>

        <div className="profile_payments">Noch keine Auszahlung bereit</div>
      </div>
      <Footerbar />
    </div>
  );
}

const SettingsIcon = styled(MdSettings)`
  font-size: 25px;
  color: #575757;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const CheckIcon = styled(MdCheckCircleOutline)`
  font-size: 33px;
  color: #757575;
`;

const CheckIconFilled = styled(MdCheckCircle)`
  font-size: 33px;
  color: #f58700;
`;
