import React, { useEffect } from "react";

const TikTokEmbed = ({ accountname, isMobile }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [accountname]);

  return (
    <>
      {isMobile ? (
        <blockquote
          className="tiktok-embed"
          cite={"https://www.tiktok.com/@" + accountname}
          data-unique-id={accountname}
          data-embed-from="embed_page"
          data-embed-type="creator"
          style={{
            width: "100%",
            borderRadius: "8px",
            borderRight: "1px solid #f3f3f3",
          }}
          allow="accelerometer"
          scrolling="no"
        >
          <section>
            <a
              target="_blank"
              href={
                "https://www.tiktok.com/@" +
                accountname +
                "?refer=creator_embed"
              }
            >
              @{accountname}
            </a>
          </section>
        </blockquote>
      ) : (
        <blockquote
          className="tiktok-embed"
          cite={"https://www.tiktok.com/@" + accountname}
          data-unique-id={accountname}
          data-embed-from="embed_page"
          data-embed-type="creator"
          style={{
            width: "48%",
            borderRadius: "8px",
            borderRight: "1px solid #f3f3f3",
          }}
          allow="accelerometer"
          scrolling="no"
        >
          <section>
            <a
              target="_blank"
              href={
                "https://www.tiktok.com/@" +
                accountname +
                "?refer=creator_embed"
              }
            >
              @{accountname}
            </a>
          </section>
        </blockquote>
      )}
    </>
  );
};

export default TikTokEmbed;
