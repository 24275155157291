import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import useFetch from "./functions/useFetch";

import Home from "./screens/Home";
import Bottomnavbar from "./components/navbars/Bottomnavbar";
import * as authentication from "./functions/authentication";
import useScreenType from "./functions/useScreenType";
import { LanguageProvider } from "./contexts/LanguageContext";
import Checkout from "./screens/Checkout";
import Login from "./screens/Login";
import Profile from "./screens/Profile";
import Settings from "./screens/Settings";
import Creator from "./screens/Creator";
import Cart from "./screens/Cart";
import Service from "./screens/Service";
import Landingpage from "./screens/Ladingpage";
import Landing from "./screens/Landing";
import Ads from "./screens/Ads";
import Content from "./screens/Content";
import ContentList from "./screens/Collabos";
import Collabos from "./screens/Collabos";
import Payments from "./screens/Payments";
import Einstellungen from "./screens/Settings";
import Influencersetup from "./screens/Influencersetup";
import Legal from "./screens/Legal";
import Edit from "./screens/Edit";

const developerMode = true;

export default function App() {
  let apiURL = "https://cloud.letsfluence.com";
  if (developerMode) {
    apiURL = "https://developercloud.letsfluence.com";
  }

  /* Auth Token */
  const [authToken, setAuthToken] = useState("");

  /* userdata */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const [activeProductID, setActiveProductID] = useState(1);
  const [filterBranch, setFilterBranch] = useState("fashion");
  const [filterProductOrService, setFilterProductOrService] =
    useState("products");
  const [filterProductStart, setFilterProductStart] = useState(1);
  const [filterProductEnd, setFilterProductEnd] = useState(500);
  const [filteredCreators, setFilteredCreators] = useState([]);

  const [activeCreator, setActiveCreator] = useState([]);
  const [cart, setCart] = useState([]);
  const [activeOrder, setActiveOrder] = useState({});

  const [isUploading, setIsUploading] = useState(false);
  const [reload, setReload] = useState(false);
  const [topnavBusiness, setTopnavBusiness] = useState("");
  const [navigation, setNavigation] = useState("");

  const [localCollabos, setLocalCollabos] = useState([]);
  const [hasLocalCollabos, setHasLocalCollabos] = useState(false);
  const [edit, setEdit] = useState("");

  const [favorites, setFavorites] = useState([]);
  const [subPage, setSubPage] = useState("search"); // mobile tab nav: Search Influencer / My List

  const isMobile = useScreenType();

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmentora");
      localStorage.removeItem("@letsfluencelocalCollabos");
      setAuthToken();
      setLocalCollabos([]);
      setCompanyData([]);
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmentora");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmentora")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };
    const res = await fetch(`${apiURL}/api/authenticate`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(`${apiURL}/api/signup.php`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const categories = [
    "Beauty",
    "Alltag",
    "Lifestyle",
    "Mode",
    "Reisen",
    "Sport",
    "Comedy",
    "Ernährung",
    "Gesundheit",
    "Finanzen",
    "Immobilien",
    "Autos",
    "Familie",
    "Outdoor",
  ];

  const {
    data: userData,
    loading: loadingUser,
    error: errorUser,
  } = useFetch(
    `${apiURL}/api/users/`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: companyData,
    loading: loadingCompanyData,
    error: errorCompanyData,
    setData: setCompanyData,
  } = useFetch(
    `${apiURL}/api/companies/?id=` + userData.companyID,
    authToken,
    "GET",
    null,
    [userData, authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: creators,
    loading: loadingCreatorData,
    error: errorCreatorData,
  } = useFetch(
    `${apiURL}/api/creators/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [reload] // Additional dependencies, if needed
  );

  const {
    data: templates,
    loading: loadingTemplates,
    error: errorTemplates,
  } = useFetch(
    `${apiURL}/api/templates/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: services,
    loading: loadingServices,
    error: errorServices,
  } = useFetch(
    `${apiURL}/api/services/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: products,
    loading: loadingProducts,
    error: errorProducts,
  } = useFetch(
    `${apiURL}/api/products/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const uploadLocalCollaboToLocalStorage = () => {
    localStorage.setItem(
      "@letsfluencelocalCollabos",
      JSON.stringify(localCollabos)
    );
  };

  useEffect(() => {
    let localcoll = JSON.parse(
      localStorage.getItem("@letsfluencelocalCollabos")
    );
    if (!isEmpty(localcoll)) {
      setLocalCollabos(localcoll);
    }
  }, []);

  useEffect(() => {
    uploadLocalCollaboToLocalStorage();
  }, [localCollabos]);

  useEffect(() => {
    let newArray = [];
    creators.map((creator) => newArray.push(creator));
    // Iterate through each element in "creators"
    newArray = newArray.filter((creator) => {
      // Parse the "products" string into a JSON array
      let productsArray = JSON.parse(creator.products);

      // Check if the given number is present in any of the objects in the "products" array
      return productsArray.some((product) => product == activeProductID);
    });

    setFilteredCreators(newArray);
  }, [activeProductID, creators]);

  useEffect(async () => {
    if (
      !isEmpty(companyData) &&
      topnavBusiness != "" &&
      topnavBusiness != companyData[0].business
    ) {
      const resa = await fetch(
        `${apiURL}/api/creators/?id=` + companyData[0].id,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken?.JWT}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            business: topnavBusiness,
          }),
        }
      );
    }
  }, [topnavBusiness, companyData]);

  useEffect(async () => {
    if (!isEmpty(companyData) && companyData[0].business != "") {
      setTopnavBusiness(companyData[0].business);
    }
  }, [companyData]);

  useEffect(async () => {
    if (
      !isEmpty(localCollabos) &&
      !isEmpty(companyData) &&
      !isEmpty(localStorage.getItem("@letsfluencelocalCollabos")) &&
      hasLocalCollabos
    ) {
      // für jeden localCollabo einen anlegen
      for (let i = 0; i < localCollabos.length; i++) {
        const resa = await fetch(`${apiURL}/api/collabos`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken?.JWT}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            buyerID: companyData[0].id,
            buyerName: companyData[0].companyname,
            buyerImageURL: JSON.parse(companyData[0].pictures)[0].url,
            sellerID: localCollabos[i].sellerID,
            sellerName: localCollabos[i].sellerName,
            sellerImageURL: localCollabos[i].sellerImageURL,
            type: localCollabos[i].type,
            channels: localCollabos[i].channels,
            plan: localCollabos[i].plan,
            pricePerWeek: localCollabos[i].pricePerWeek,
          }),
        });

        await resa.text().then((value) => setNavigation("/content"));
      }
      setLocalCollabos([]);
      localStorage.removeItem("@letsfluencelocalCollabos");
    }
  }, [hasLocalCollabos]);

  useEffect(() => {
    if (
      !isEmpty(localCollabos) &&
      !isEmpty(companyData) &&
      !isEmpty(localStorage.getItem("@letsfluencelocalCollabos"))
    ) {
      setHasLocalCollabos(true);
    } else {
      setHasLocalCollabos(false);
    }
  }, [companyData]);

  return (
    <div className="app">
      <LanguageProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  products={products}
                  filterBranch={filterBranch}
                  setFilterBranch={setFilterBranch}
                  filterProductOrService={filterProductOrService}
                  setFilterProductOrService={setFilterProductOrService}
                  filterProductStart={filterProductStart}
                  setFilterProductStart={setFilterProductStart}
                  filterProductEnd={filterProductEnd}
                  setFilterProductEnd={setFilterProductEnd}
                  filteredCreators={filteredCreators}
                  cart={cart}
                  topnavBusiness={topnavBusiness}
                  setTopnavBusiness={setTopnavBusiness}
                  navigation={navigation}
                  setNavigation={setNavigation}
                  companyData={companyData[0]}
                  categories={categories}
                  favorites={favorites}
                  setFavorites={setFavorites}
                  subPage={subPage}
                  setSubPage={setSubPage}
                />
              }
            />
            <Route
              path="/ads"
              element={
                <Ads
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  activeProductID={activeProductID}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />
            <Route
              path="/content"
              element={
                <Collabos
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  activeProductID={activeProductID}
                  cart={cart}
                  setCart={setCart}
                  companyData={companyData[0]}
                  topnavBusiness={topnavBusiness}
                  setTopnavBusiness={setTopnavBusiness}
                  localCollabos={localCollabos}
                  setLocalCollabos={setLocalCollabos}
                  navigation={navigation}
                  setNavigation={setNavigation}
                />
              }
            />
            <Route
              path="/content/:id"
              element={
                <Content
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  activeProductID={activeProductID}
                  cart={cart}
                  setCart={setCart}
                  companyData={companyData[0]}
                  topnavBusiness={topnavBusiness}
                  setTopnavBusiness={setTopnavBusiness}
                  localCollabos={localCollabos}
                  setLocalCollabos={setLocalCollabos}
                />
              }
            />
            <Route
              path="/landing"
              element={
                <Landing
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  activeProductID={activeProductID}
                  cart={cart}
                  setCart={setCart}
                  companyData={companyData[0]}
                />
              }
            />
            <Route
              path="/profile"
              element={
                <Profile
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  services={services}
                  companyData={companyData[0]}
                  destroySession={destroySession}
                  reload={reload}
                  setReload={setReload}
                  topnavBusiness={topnavBusiness}
                  setTopnavBusiness={setTopnavBusiness}
                  edit={edit}
                  setEdit={setEdit}
                />
              }
            />
            <Route
              path="/influencersetup"
              element={
                <Influencersetup
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  services={services}
                  companyData={companyData[0]}
                  destroySession={destroySession}
                  reload={reload}
                  setReload={setReload}
                  topnavBusiness={topnavBusiness}
                  setTopnavBusiness={setTopnavBusiness}
                  categories={categories}
                />
              }
            />
            <Route
              path="/edit"
              element={
                <Edit
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  services={services}
                  companyData={companyData[0]}
                  destroySession={destroySession}
                  reload={reload}
                  setReload={setReload}
                  topnavBusiness={topnavBusiness}
                  setTopnavBusiness={setTopnavBusiness}
                  edit={edit}
                  setEdit={setEdit}
                  categories={categories}
                />
              }
            />
            <Route
              path="/payments"
              element={
                <Payments
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  services={services}
                  companyData={companyData[0]}
                  destroySession={destroySession}
                  reload={reload}
                  setReload={setReload}
                  topnavBusiness={topnavBusiness}
                  setTopnavBusiness={setTopnavBusiness}
                />
              }
            />
            <Route
              path="/service/:id"
              element={
                <Service
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                />
              }
            />
            <Route
              path="/creator/:id"
              element={
                <Creator
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  activeProductID={activeProductID}
                  cart={cart}
                  setCart={setCart}
                  reload={reload}
                  setReload={setReload}
                  companyData={companyData[0]}
                  topnavBusiness={topnavBusiness}
                  setTopnavBusiness={setTopnavBusiness}
                  localCollabos={localCollabos}
                  setLocalCollabos={setLocalCollabos}
                />
              }
            />
            <Route
              path="/cart"
              element={
                <Cart
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  services={services}
                  activeCreator={activeCreator}
                  cart={cart}
                  setCart={setCart}
                  activeOrder={activeOrder}
                  setActiveOrder={setActiveOrder}
                />
              }
            />

            <Route
              path="/checkout"
              element={
                <Checkout
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  activeOrder={activeOrder}
                  setActiveOrder={setActiveOrder}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />

            <Route
              path="/login"
              element={
                <Login
                  isMobile={isMobile}
                  authToken={authToken}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  signUp={signUp}
                  username={username}
                  setUsername={setUsername}
                  signIn={signIn}
                  topnavBusiness={topnavBusiness}
                  setTopnavBusiness={setTopnavBusiness}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  companyData={companyData[0]}
                />
              }
            />
            <Route
              path="/settings"
              element={
                <Settings
                  destroySession={destroySession}
                  companyData={companyData[0]}
                  authToken={authToken}
                  isMobile={isMobile}
                  apiURL={apiURL}
                  reload={reload}
                  setReload={setReload}
                />
              }
            />
            <Route
              path="/legal"
              element={
                <Legal
                  destroySession={destroySession}
                  companyData={companyData[0]}
                  authToken={authToken}
                />
              }
            />

            <Route
              path="/landing"
              element={<Landingpage destroySession={destroySession} />}
            />
          </Routes>
          <></>
        </Router>
      </LanguageProvider>
    </div>
  );
}
