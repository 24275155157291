import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowDropDown,
  MdCampaign,
  MdCancel,
  MdChildCare,
  MdClose,
  MdFacebook,
  MdFavorite,
  MdFavoriteBorder,
  MdHome,
  MdMenu,
  MdMood,
  MdOutlineAirplanemodeActive,
  MdOutlineCampaign,
  MdOutlineComputer,
  MdOutlineFitnessCenter,
  MdOutlineFoodBank,
  MdOutlineGamepad,
  MdOutlinePets,
  MdOutlineRestaurant,
  MdOutlineShoppingBag,
  MdOutlineSpa,
  MdPerson,
  MdPersonOutline,
  MdSearch,
} from "react-icons/md";

import "../../styles/Card.css";
import { useNavigate } from "react-router";
import {
  FaInstagram,
  FaLinkedin,
  FaMastodon,
  FaPinterest,
  FaPlay,
  FaSnapchat,
  FaTiktok,
  FaTwitch,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { isEmpty } from "../../functions/isEmpty";
import { TbWorldWww } from "react-icons/tb";
import MediaPlayer from "../mediaPlayer/MediaPlayer";

export default function FollowerNumber({
  followerInstagram,
  followerYoutube,
  followerTiktok,
  followerFacebook,
  followerLinkedIn,
  followerSnapchat,
}) {
  const [number, setNumber] = useState(0);

  function formatNumber(number) {
    if (number > 1000 && number < 1000000) {
      return Math.floor((number / 1000) * 10) / 10 + "k";
    } else if (number >= 1000000) {
      return Math.floor((number / 1000000) * 10) / 10 + "Mio";
    } else {
      return number.toString();
    }
  }

  useEffect(() => {
    let count = 0;
    if (!isEmpty(followerInstagram)) {
      count = count + parseInt(followerInstagram);
    }
    if (!isEmpty(followerTiktok)) {
      count = count + parseInt(followerTiktok);
    }
    if (!isEmpty(followerYoutube)) {
      count = count + parseInt(followerYoutube);
    }
    if (!isEmpty(followerFacebook)) {
      count = count + parseInt(followerFacebook);
    }
    if (!isEmpty(followerLinkedIn)) {
      count = count + parseInt(followerLinkedIn);
    }
    if (!isEmpty(followerSnapchat)) {
      count = count + parseInt(followerSnapchat);
    }

    count = formatNumber(count);

    setNumber(count);
  }, [
    followerInstagram,
    followerYoutube,
    followerTiktok,
    followerFacebook,
    followerLinkedIn,
    followerSnapchat,
  ]);

  return number;
}

const PersonIcon = styled(MdPersonOutline)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BackIcon = styled(MdArrowBackIos)`
  font-size: 27px;
  color: #f58700;
  margin-right: -15px;
`;

const CampaignIcon = styled(MdOutlineCampaign)`
  font-size: 26px;
  color: #ffffff;
`;

const CampaignIconGrey = styled(MdOutlineCampaign)`
  font-size: 26px;
  color: #696969;
`;

const CancelIcon = styled(MdClose)`
  font-size: 26px;
  color: #696969;
  margin-top: 3px;
`;

const FavoriteIcon = styled(MdFavoriteBorder)`
  font-size: 26px;
  color: #ffffff;
`;

const FavoriteIconActive = styled(MdFavorite)`
  font-size: 26px;
  color: #f58700;
`;

const InstgramIcon = styled(FaInstagram)`
  font-size: 20px;
  color: #8c8c8c;
`;

const TikTokIcon = styled(FaTiktok)`
  font-size: 17px;
  color: #8c8c8c;
  margin-left: 3px;
  margin-bottom: 2px;
  margin-top: 2px;
`;

const YouTubeIcon = styled(FaYoutube)`
  font-size: 20px;
  color: #8c8c8c;
  margin-left: 3px;
`;

const UGCIcon = styled(FaPlay)`
  font-size: 13px;
  color: #8c8c8c;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const SnapchatIcon = styled(FaSnapchat)`
  font-size: 20px;
  color: #8c8c8c;
  margin-top: 2px;
  margin-bottom: 3px;
`;

const FacebookIcon = styled(MdFacebook)`
  font-size: 20px;
  color: #8c8c8c;
`;
