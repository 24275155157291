import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdArrowBack, MdArrowBackIos, MdMenu } from "react-icons/md";

import "../styles/Checkout.css";
import TopNavbar from "../components/navbars/DesktopTopNavbar";
import { ServicesBar } from "../components/cards/ServicesBar";
import { useNavigate } from "react-router";
import TextInput from "../components/inputs/TextInput";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import { isEmpty } from "../functions/isEmpty";
import Footerbar from "../components/navbars/Footerbar";

export default function Checkout({
  isMobile,
  apiURL,
  activeOrder,
  setActiveOrder,
  cart,
  setCart,
  authToken,
}) {
  const [plan, setPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companyname, setCompanyname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [streetname, setStreetname] = useState("");
  const [streetnumber, setStreetnumber] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [ccnumber, setCcnumber] = useState("");
  const [expireDateMonth, setExpireDateMonth] = useState("");
  const [expireDateYear, setExpireDateYear] = useState("");
  const [CVC, setCVC] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(cart)) {
      // find all unpaid weeks
      let newCart = { ...cart };
      let plan = JSON.parse(newCart.plan);
      let filteredWeeks = [];

      for (let i = 0; i < plan.weeks.length; i++) {
        if (plan.weeks[i].started == 0) {
          filteredWeeks.push(plan.weeks[i]);
        }
      }
      plan.weeks = filteredWeeks;
      newCart.plan = JSON.stringify(plan);
      setPlan(plan);
    } else {
      navigate("/");
    }
  }, [cart]);

  const handlePayButton = async () => {
    let newCart = { ...cart };
    let plan = JSON.parse(newCart.plan);

    for (let i = 0; i < plan.weeks.length; i++) {
      if (plan.weeks[i].started == 0) {
        plan.weeks[i].started = 1;
      }
    }

    newCart.plan = JSON.stringify(plan);

    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const resa = await fetch(`${apiURL}/api/collabos/?id=` + cart.id, {
      method: "POST",
      headers: {
        //  Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        plan: newCart.plan,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    navigate("/content/" + cart.id);
  };

  return (
    <>
      {isMobile ? (
        <div className="checkout">
          <DefaultTopNav isMobile={isMobile} cart={cart} />

          <div className="checkout_wrapper">
            <div className="checkout_left">
              <div className="checkout_left_left">
                <div className="checkout_columns">
                  <div className="checkout_columns_left">
                    Billing Data
                    <div className="checkout_columns_left_inputs">
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Companyname (optional)"}
                          value={companyname}
                          setValue={setCompanyname}
                          placeholder="Enter Companyname"
                        />
                      </div>
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Vorname"}
                          value={firstname}
                          setValue={setFirstname}
                          placeholder="Enter Firstname"
                        />
                        <TextInput
                          name={"Nachname"}
                          value={lastname}
                          setValue={setLastname}
                          placeholder="Enter Lastname"
                        />
                      </div>
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Straße"}
                          value={streetname}
                          setValue={setStreetname}
                          placeholder="Enter Streetname"
                        />
                        <TextInput
                          name={"Hausnummer"}
                          value={streetnumber}
                          setValue={setStreetnumber}
                          placeholder="Enter Streetnumber"
                        />
                      </div>
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Postleitzahl"}
                          value={zip}
                          setValue={setZip}
                          placeholder="Enter Zip"
                        />
                        <TextInput
                          name={"Stadt"}
                          value={city}
                          setValue={setCity}
                          placeholder="Enter City"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="checkout_columns_right">
                    Payment - Creditcard
                    <div className="checkout_columns_left_inputs_row">
                      <TextInput
                        name={"Creditcard Number"}
                        value={ccnumber}
                        setValue={setCcnumber}
                        placeholder="Enter Creditcard Number"
                      />
                    </div>
                    <div className="checkout_columns_left_inputs_row">
                      <TextInput
                        name={"Expire Month"}
                        value={expireDateMonth}
                        setValue={setExpireDateMonth}
                        placeholder="Expire Date"
                      />
                      <TextInput
                        name={"Expire Year"}
                        value={expireDateYear}
                        setValue={setExpireDateYear}
                        placeholder="Expire Year"
                      />
                      <TextInput
                        name={"CVC"}
                        value={CVC}
                        setValue={setCVC}
                        placeholder="CVC"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="checkout_left_right"></div>
            </div>

            <div className="checkout_right">
              <div className="checkout_right_title">Your Order</div>
              <div className="checkout_right_ad">
                <div className="checkout_right_ad_imagebox">
                  <img
                    src={
                      !isEmpty(activeOrder) &&
                      JSON.parse(activeOrder?.template?.stories)[0].url
                    }
                    className="checkout_right_ad_imagebox_img"
                  />
                </div>
                <div className="checkout_right_ad_name">
                  {activeOrder?.template?.title_en}
                </div>
              </div>
              <div className="checkout_right_creator">
                <div className="checkout_right_creator_imagebox">
                  <img
                    src={
                      !isEmpty(activeOrder) &&
                      JSON.parse(activeOrder?.creator?.pictures)[0].url
                    }
                    className="checkout_right_creator_imagebox_img"
                  />
                </div>
                <div className="checkout_right_creator_name">
                  {activeOrder?.creator?.companyname}
                </div>
              </div>
              <div className="checkout_right_pay">
                <div className="checkout_right_pay_price">250,00 €</div>
                <div className="checkout_cta" onClick={() => handlePayButton()}>
                  Pay
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="checkout">
          <div className="defaulttopnav">
            <div className="topnavbar_top">
              <div className="topnavbar_top_left">
                <div
                  className="topnavbar_top_left_logo"
                  onClick={() => navigate("/")}
                >
                  {/*
                <img
                src="../../../logo-desktop.png"
                className="topnavbar_top_left_logo"
              />
              */}
                  letsfluence
                </div>
              </div>
              <div className="topnavbar_top_navbar"></div>
            </div>
          </div>

          <div className="checkout_wrapper">
            <div className="padding-left">
              <div className="service_toprow_title">
                <div className="wrapper_left">
                  <div
                    className="creator_wrapper_one_button"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBack />
                  </div>
                </div>
              </div>
            </div>

            <div className="checkout_left">
              <div className="checkout_left_left">
                <div className="checkout_columns">
                  <div className="checkout_columns_left">
                    Billing Data
                    <div className="checkout_columns_left_inputs">
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Companyname (optional)"}
                          value={companyname}
                          setValue={setCompanyname}
                          placeholder="Enter Companyname"
                        />
                      </div>
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Vorname"}
                          value={firstname}
                          setValue={setFirstname}
                          placeholder="Enter Firstname"
                        />
                        <TextInput
                          name={"Nachname"}
                          value={lastname}
                          setValue={setLastname}
                          placeholder="Enter Lastname"
                        />
                      </div>
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Straße"}
                          value={streetname}
                          setValue={setStreetname}
                          placeholder="Enter Streetname"
                        />
                        <TextInput
                          name={"Hausnummer"}
                          value={streetnumber}
                          setValue={setStreetnumber}
                          placeholder="Enter Streetnumber"
                        />
                      </div>
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Postleitzahl"}
                          value={zip}
                          setValue={setZip}
                          placeholder="Enter Zip"
                        />
                        <TextInput
                          name={"Stadt"}
                          value={city}
                          setValue={setCity}
                          placeholder="Enter City"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="checkout_columns_right">
                    Payment - Creditcard
                    <div className="checkout_columns_left_inputs_row">
                      <TextInput
                        name={"Creditcard Number"}
                        value={ccnumber}
                        setValue={setCcnumber}
                        placeholder="Enter Creditcard Number"
                      />
                    </div>
                    <div className="checkout_columns_left_inputs_row">
                      <TextInput
                        name={"Expire Month"}
                        value={expireDateMonth}
                        setValue={setExpireDateMonth}
                        placeholder="Expire Date"
                      />
                      <TextInput
                        name={"Expire Year"}
                        value={expireDateYear}
                        setValue={setExpireDateYear}
                        placeholder="Expire Year"
                      />
                      <TextInput
                        name={"CVC"}
                        value={CVC}
                        setValue={setCVC}
                        placeholder="CVC"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="checkout_left_right"></div>
            </div>

            <div className="checkout_right">
              <div className="checkout_right_title">Your Order</div>

              <div className="checkout_right_creator">
                <div className="checkout_right_creator_imagebox">
                  <img
                    src={!isEmpty(cart) && cart.sellerImageURL}
                    className="checkout_right_creator_imagebox_img"
                  />
                </div>
                <div className="checkout_right_creator_name">
                  {!isEmpty(cart) && cart.sellerName}
                </div>
              </div>
              {!isEmpty(plan) &&
                plan.weeks.map((weekObject, index) => (
                  <div className="checkout_right_ad">
                    <div className="checkout_right_ad_imagebox"></div>
                    <div className="checkout_right_ad_name">
                      Week {weekObject.weeknumber}
                      {" - "}
                      {!isEmpty(cart) && cart.pricePerWeek} €
                    </div>
                  </div>
                ))}

              <div className="checkout_right_pay">
                <div className="checkout_right_pay_price">
                  {!isEmpty(cart) &&
                    !isEmpty(plan) &&
                    plan.weeks.length * cart.pricePerWeek}
                  {" €"}
                </div>
                <div className="checkout_cta" onClick={() => handlePayButton()}>
                  Pay
                </div>
              </div>
            </div>
          </div>
          <div className="placeholder120px"></div>
          <Footerbar />
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: 3px;
  margin-left: 10px;
`;
