import React from "react";
// import "./MediaPlayer.css"; // Passe den Pfad entsprechend an

const MediaPlayer = ({ url, className, type, autoplay, givenId }) => {
  const fileExtension = url.split(".").pop().toLowerCase();

  let mediaComponent;

  if (["jpg", "jpeg", "png"].includes(fileExtension)) {
    mediaComponent = (
      <img
        src={url}
        alt="Media"
        className={`${className}`}
        playsInline={true}
      />
    );
  } else if (["mov", "mp4"].includes(fileExtension)) {
    mediaComponent = (
      <video
        controls={type !== "card"}
        preload="metadata"
        className={`${className}`}
        id={givenId}
        autoPlay={autoplay}
        playsInline={true}
        muted={true}
        loop={true}
      >
        <source src={url + "#t=0.01"} type={`video/mp4`} />
        Your browser does not support the video tag.
      </video>
    );
  } else {
    mediaComponent = <p className={`unsupported-media ${className}`}>...</p>;
  }

  return <div className={`media-container ${className}`}>{mediaComponent}</div>;
};

export default MediaPlayer;
