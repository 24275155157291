import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdAddCircle,
  MdAddCircleOutline,
  MdArrowBack,
  MdArrowBackIos,
  MdCampaign,
  MdCheck,
  MdClose,
  MdCreditCard,
  MdDelete,
  MdHome,
  MdLocalShipping,
  MdMenu,
  MdOutlinePerson,
  MdPerson,
  MdPerson2,
  MdPerson3,
  MdPersonOutline,
  MdPersonPinCircle,
  MdPlayArrow,
  MdPlayCircle,
  MdSearch,
  MdShoppingCart,
  MdUploadFile,
  MdVideocam,
  MdWeb,
} from "react-icons/md";

import "../styles/Creator.css";
import "../styles/Home.css";
import TopNavbar from "../components/navbars/DesktopTopNavbar";
import { ServicesBar } from "../components/cards/ServicesBar";
import { FaInstagram } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router";
import MediaPlayer from "../components/mediaPlayer/MediaPlayer";
import { isEmpty } from "../functions/isEmpty";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import { ProductData } from "../contexts/ProductData";
import StoryElement from "../components/CatalogComponent";
import CatalogComponent from "../components/CatalogComponent";
import useFetch from "../functions/useFetch";
import DesktopTopNavbar from "../components/navbars/DesktopTopNavbar";
import FollowerNumber from "../components/cards/FolllowerNumber";
import TikTokEmbed from "../components/cards/TikTokEmbed";
import Footerbar from "../components/navbars/Footerbar";

export default function Creator({
  isMobile,
  apiURL,
  authToken,
  services,
  creators,
  activeProductID,
  cart,
  setCart,
  setGlobalActiveCreator,
  templates,
  reload,
  setReload,
  companyData,
  topnavBusiness,
  setTopnavBusiness,
  localCollabos,
  setLocalCollabos,
  uploadLocalCollaboToLocalStorage,
}) {
  const [creatorData, setCreatorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progressMobile, setProgressMobile] = useState(0);

  const [coop, setCoop] = useState("");

  let location = useLocation().pathname;
  let navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!isEmpty(creators)) {
      let creator = creators.find((element) => element.id === id);
      setCreatorData(creator);
    }
  }, [creators]);

  const {
    data: creations,
    loading: loadingCreations,
    error: errorCreations,
  } = useFetch(
    `${apiURL}/api/creations/?creatorID=` + id,
    authToken,
    "GET",
    null,
    [reload] // Additional dependencies, if needed
  );

  const handleUpload = async () => {
    let channels = [];
    if (
      !isEmpty(creatorData.instagramProfileURL) &&
      !isEmpty(creatorData.followerInstagram)
    ) {
      channels.push("Instagram");
    }
    if (
      !isEmpty(creatorData.tiktokProfileURL) &&
      !isEmpty(creatorData.followerTiktok)
    ) {
      channels.push("Tiktok");
    }
    if (
      !isEmpty(creatorData.youtubeProfileURL) &&
      !isEmpty(creatorData.followerYoutube)
    ) {
      channels.push("Youtube");
    }

    if (isEmpty(authToken)) {
      let helpObject = {
        id: "local" + (localCollabos.length + 1),
        buyerID: 0,
        buyerName: "Your Company",
        buyerImageURL:
          "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg",
        sellerID: id,
        sellerName: creatorData.companyname,
        sellerImageURL: JSON.parse(creatorData.pictures)[0].url,
        type: "fashion",
        pricePerWeek: creatorData.priceInstagramStory,
        channels: channels,
        plan: JSON.stringify({
          collabo: "requested",
          preperation: {
            products: [],
            services: [],
            company: [],
          },
          weeks: [
            {
              weeknumber: 1,
              content: {},
              note: "",
              adobject: {},
              started: 0,
              deliveryInstagramReels: "",
              deliveryInstagramStory: "",
              deliveryTiktokPost: "",
              deliveryTiktokStory: "",
            },
          ],
        }),
      };

      let helpCol = [...localCollabos];
      helpCol.push(helpObject);
      setLocalCollabos(helpCol);

      localStorage.setItem(
        "@letsfluencelocalCollabos",
        JSON.stringify(helpCol)
      );
      navigate("/content/local" + (parseInt(localCollabos.length) + 1));
      return;

      // create a local collabo
      // useEffect wenn local collabo sich ändert,
      // soll es im localstorage gespeichert werden
      // wenn es leer ist, eine abfrage gemacht werden
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const resa = await fetch(`${apiURL}/api/collabos`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        buyerID: companyData.id,
        buyerName: companyData.companyname,
        buyerImageURL: JSON.parse(companyData.pictures)[0].url,
        sellerID: id,
        sellerName: creatorData.companyname,
        sellerImageURL: JSON.parse(creatorData.pictures)[0].url,
        type: "fashion",
        channels: JSON.stringify(channels),
        pricePerWeek: creatorData.priceInstagramStory,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    navigate("/content/" + value);
  };

  useEffect(() => {
    // initialize coop by the companydata.category (which is not in mysql yet)
  }, [companyData]);

  const openProfile = (profileString, socialmedia) => {
    let url = "";
    if (
      profileString.substring(0, 5) != "https" &&
      profileString.substring(0, 3) != "www"
    ) {
      url = "https://www." + socialmedia + ".com/" + profileString;
    } else if (profileString.substring(0, 3) == "www") {
      url = "https://" + profileString;
    } else {
      url = profileString;
    }
    window.open(url, "_blank").focus();
  };

  const handleNavigationClick = (backOrForward) => {
    if (backOrForward == "forward") {
      if (progressMobile == 5) {
        return;
      } else {
        setProgressMobile(progressMobile + 1);
      }
    } else {
      if (progressMobile == 0) {
        navigate(-1);
      } else {
        setProgressMobile(progressMobile - 1);
      }
    }
  };

  const productTemplates = [
    {
      id: 1,
      title: "Termin Online buchen",
      img: "https://www.developercloud.letsfluence.com/api/images/placeholder/BookSpa.mp4",
      description:
        "Produkt wird ausgepackt, in einer Nahaufnahme dargestellt, anschließend benutzt und abschließend ein Fazit ausgesprochen",
    },

    {
      id: 2,
      title: "Produkte vergleichen",
      img: "https://www.developercloud.letsfluence.com/api/images/placeholder/TemplateVideo-2.mp4",

      description:
        "Zwei Produkte werden verglichen in Preis und Leistung. Anschließend gibt die Influencer:in ein persönliches Feedback",
    },
    {
      id: 3,
      title: "Produkt Platzierung Foto",
      img: "https://www.developercloud.letsfluence.com/api/images/placeholder/TemplateVideo-3.mp4",

      description:
        "Influencer:in posiert und dein Produkt ist gut sichtbar mit im Bild",
    },
    {
      id: 4,
      title: "Rabattaktion",
      img: "https://www.developercloud.letsfluence.com/api/images/placeholder/TemplateVideo-4.mp4",

      description:
        "Produkt wird gezeigt, die Benutzung erklärt, ein Rabattcode benannt und im Video dargestellt. Der Link zum Kaufen wird in den ersten Kommentaren geteilt",
    },
    {
      id: 1,
      title: "Selfie Story",
      img: "https://www.developercloud.letsfluence.com/api/images/placeholder/TemplateVideo-1.mp4",
      description:
        "Eine Geschichte wird erzählt in der dein Produkt gebraucht wurde",
    },
  ];

  const addToCart = (object, newValue) => {
    // Create a new object with the new key-value pair
    const updatedObject = { ...object, channel: newValue };

    // Create a new array with the updated object added to the cart
    const updatedCart = [...cart, updatedObject];

    // Update the cart state
    setCart(updatedCart);
  };

  const DateInput = ({ daysToAdd }) => {
    const [dateValue, setDateValue] = useState("");

    useEffect(() => {
      // Function to calculate the date
      const calculateDate = (days) => {
        const today = new Date();
        today.setDate(today.getDate() + days);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      // Calculate the date and set it to the state
      const newDate = calculateDate(daysToAdd);
      setDateValue(newDate);
    }, [daysToAdd]);

    return (
      <input
        type="date"
        value={dateValue}
        onChange={(e) => setDateValue(e.target.value)}
      />
    );
  };

  return (
    <>
      {isMobile ? (
        <div className="creator_mobile">
          <div className="hello_topnav">
            <div className="hello_topnav_topbar">
              <div className="hello_topnav_topbar_left">
                <div
                  className="hello_topnav_topbar_left_backButton"
                  onClick={() => handleNavigationClick("back")}
                >
                  <ArrowBack />
                </div>
              </div>
              <div className="hello_topnav_topbar_middle">
                <div className="hello_topnav_topbar_middle_image">
                  {!isEmpty(creatorData) && (
                    <div className="">
                      <img
                        className="hello_topnav_topbar_middle_image_img"
                        src={
                          !isEmpty(creatorData) &&
                          JSON.parse(creatorData.pictures)[0].url
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="hello_topnav_topbar_middle_text">
                  <div className="hello_topnav_topbar_middle_text_top">
                    {!isEmpty(creatorData) && creatorData.companyname}
                  </div>
                  <div className="hello_topnav_topbar_middle_text_bottom">
                    {!isEmpty(creatorData) && (
                      <FollowerNumber
                        followerInstagram={
                          !isEmpty(creatorData.followerInstagram)
                            ? creatorData.followerInstagram
                            : null
                        }
                        followerTiktok={
                          !isEmpty(creatorData.followerTiktok)
                            ? creatorData.followerTiktok
                            : null
                        }
                        followerYoutube={
                          !isEmpty(creatorData.followerYoutube)
                            ? creatorData.followerYoutube
                            : null
                        }
                        followerFacebook={
                          !isEmpty(creatorData.followerFacebook)
                            ? creatorData.followerFacebook
                            : null
                        }
                        followerLinkedIn={
                          !isEmpty(creatorData.followerLinkedIn)
                            ? creatorData.followerLinkedIn
                            : null
                        }
                        followerSnapchat={
                          !isEmpty(creatorData.followerSnapchat)
                            ? creatorData.followerSnapchat
                            : null
                        }
                      />
                    )}
                    {" Follower"}{" "}
                  </div>
                </div>
              </div>
              <div className="hello_topnav_topbar_right">
                <div
                  className="hello_topnav_topbar_right_ctabtn"
                  onClick={() => handleNavigationClick("forward")}
                >
                  Weiter
                </div>
              </div>
            </div>
            <div className="hello_topnav_bottombar">
              {progressMobile > 0 ? (
                <div className="hello_topnav_bottombar_box">
                  <div className="hello_topnav_bottombar_box_icon_active">
                    <AdIcon />
                  </div>
                  <div className="hello_topnav_bottombar_box_text">Werbung</div>
                </div>
              ) : (
                <div className="hello_topnav_bottombar_box">
                  <div className="hello_topnav_bottombar_box_icon">
                    <AdIcon />
                  </div>
                  <div className="hello_topnav_bottombar_box_text">Werbung</div>
                </div>
              )}
              {progressMobile > 2 ? (
                <>
                  <div className="hello_topnav_bottombar_bar_active"></div>
                  <div className="hello_topnav_bottombar_box">
                    <div className="hello_topnav_bottombar_box_icon_active">
                      <CheckIcon />
                    </div>
                    <div className="hello_topnav_bottombar_box_text">
                      Starten
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="hello_topnav_bottombar_bar"></div>
                  <div className="hello_topnav_bottombar_box">
                    <div className="hello_topnav_bottombar_box_icon">
                      <CheckIcon />
                    </div>
                    <div className="hello_topnav_bottombar_box_text">
                      Starten
                    </div>
                  </div>
                </>
              )}
              {progressMobile > 3 ? (
                <>
                  <div className="hello_topnav_bottombar_bar_active"></div>
                  <div className="hello_topnav_bottombar_box">
                    <div className="hello_topnav_bottombar_box_icon_active">
                      <ShipmentIcon />
                    </div>
                    <div className="hello_topnav_bottombar_box_text">
                      Versand
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="hello_topnav_bottombar_bar"></div>
                  <div className="hello_topnav_bottombar_box">
                    <div className="hello_topnav_bottombar_box_icon">
                      <ShipmentIcon />
                    </div>
                    <div className="hello_topnav_bottombar_box_text">
                      Versand
                    </div>
                  </div>
                </>
              )}
              {progressMobile > 4 ? (
                <>
                  <div className="hello_topnav_bottombar_bar_active"></div>
                  <div className="hello_topnav_bottombar_box">
                    <div className="hello_topnav_bottombar_box_icon_active">
                      <LiveICon />
                    </div>
                    <div className="hello_topnav_bottombar_box_text">Live!</div>
                  </div>
                </>
              ) : (
                <>
                  <div className="hello_topnav_bottombar_bar"></div>
                  <div className="hello_topnav_bottombar_box">
                    <div className="hello_topnav_bottombar_box_icon">
                      <LiveICon />
                    </div>
                    <div className="hello_topnav_bottombar_box_text">Live!</div>
                  </div>
                </>
              )}
            </div>
          </div>
          {progressMobile == 0 && (
            <div className="creator_mobile_body">
              <div className="creator_mobile_body_imagebox">
                {!isEmpty(creatorData) && (
                  <div className="">
                    <img
                      className="creator_mobile_body_imagebox_image"
                      src={
                        !isEmpty(creatorData) &&
                        JSON.parse(creatorData.pictures)[0].url
                      }
                    />
                  </div>
                )}
              </div>
              <div className="creator_mobile_body_text">
                <div className="creator_mobile_body_text_name">
                  {!isEmpty(creatorData) && creatorData.companyname}
                </div>
                <div className="creator_mobile_body_text_description">
                  {!isEmpty(creatorData) && creatorData.description}
                </div>
              </div>
              <div className="creator_mobile_body_followernumber">
                <div className="creator_mobile_body_followernumber_big">
                  {!isEmpty(creatorData) && (
                    <FollowerNumber
                      followerInstagram={
                        !isEmpty(creatorData.followerInstagram)
                          ? creatorData.followerInstagram
                          : null
                      }
                      followerTiktok={
                        !isEmpty(creatorData.followerTiktok)
                          ? creatorData.followerTiktok
                          : null
                      }
                      followerYoutube={
                        !isEmpty(creatorData.followerYoutube)
                          ? creatorData.followerYoutube
                          : null
                      }
                      followerFacebook={
                        !isEmpty(creatorData.followerFacebook)
                          ? creatorData.followerFacebook
                          : null
                      }
                      followerLinkedIn={
                        !isEmpty(creatorData.followerLinkedIn)
                          ? creatorData.followerLinkedIn
                          : null
                      }
                      followerSnapchat={
                        !isEmpty(creatorData.followerSnapchat)
                          ? creatorData.followerSnapchat
                          : null
                      }
                    />
                  )}
                </div>
                <div className="creator_mobile_body_followernumber_small">
                  Follower
                </div>
              </div>
              <div className="creator_mobile_body_embeds">
                <div className="creator_mobile_body_embeds_element">
                  {!isEmpty(creatorData) &&
                    !isEmpty(creatorData.instagramProfileURL) &&
                    !isEmpty(creatorData.followerInstagram) && (
                      <div className="creator_embededs_element">
                        <iframe
                          className="creator_embededs_element_insta"
                          src={
                            "https://www.instagram.com/" +
                            creatorData.instagramProfileURL +
                            "/embed/"
                          }
                          scrolling="no"
                        ></iframe>
                      </div>
                    )}
                </div>
                <div className="creator_mobile_body_embeds_element">
                  {!isEmpty(creatorData) &&
                    !isEmpty(creatorData.tiktokProfileURL) &&
                    !isEmpty(creatorData.followerTiktok) && (
                      <TikTokEmbed
                        accountname={creatorData.tiktokProfileURL}
                        isMobile={isMobile}
                      />
                    )}
                </div>
              </div>
            </div>
          )}{" "}
          {progressMobile == 1 && (
            <div className="creator_mobile_body">
              <div className="creator_mobile_body_title">
                Wähle die passende Werbung
              </div>
              <div className="creator_ads">
                {productTemplates.map((product) => {
                  return (
                    <div className="creator_ads_ad">
                      <div className="creator_ads_ad_left">
                        <MediaPlayer
                          url={product.img}
                          autoplay={true}
                          type={"card"}
                          className={"creator_ads_ad_left_img"}
                        />
                      </div>
                      <div className="creator_ads_ad_right">
                        <div className="creator_ads_ad_right_top">
                          <div className="creator_ads_ad_right_top_title">
                            {product.title}
                          </div>
                          <div className="creator_ads_ad_right_top_description">
                            {product.description}
                          </div>
                        </div>
                        <div className="creator_ads_ad_right_bottom">
                          <select className="creator_ads_ad_right_bottom_select">
                            <option>Instagram Post - 150€</option>
                            <option>Instagram Story - 50€</option>
                            <option>TikTok Video Post - 150€</option>
                          </select>
                          <div
                            className="creator_ads_ad_right_bottom_cta"
                            onClick={() => addToCart(product, "a")}
                          >
                            hinzufügen
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {progressMobile == 2 && (
            <div className="creator_mobile_body">
              <div className="creator_mobile_body_title">Deine Auswahl</div>
              <div className="creator_adsSelected">
                {cart.map((element, index) => (
                  <div className="creator_adsSelected_element">
                    <div className="creator_adsSelected_element_left">
                      <DeleteIcon />
                    </div>
                    <div className="creator_adsSelected_element_middle">
                      {element.title}
                    </div>
                    <div className="creator_adsSelected_element_right">
                      <DateInput daysToAdd={index * 7} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {progressMobile == 3 && (
            <div className="creator_mobile_body">
              <div className="creator_mobile_body_title">
                Starte die Kampagne
              </div>
              <div className="creator_mobile_body_payment">
                <div className="creator_mobile_body_payment_section">
                  <div className="creator_mobile_body_title_paymenttitle">
                    <div className="creator_mobile_body_title_paymenttitle_icon">
                      <HomeIcon />
                    </div>
                    <div className="creator_mobile_body_title_paymenttitle_text">
                      Rechnungsdaten
                    </div>
                  </div>
                  <div className="creator_mobile_body_title_paymentFullRow">
                    <div className="creator_mobile_body_title_paymentrow_inputRow">
                      <input
                        type="text"
                        className="creator_mobile_body_title_paymentrow_inputRow_input"
                        placeholder="Firmenname (optional)"
                      />
                    </div>
                  </div>
                  <div className="creator_mobile_body_title_paymentHalfRow">
                    <div className="creator_mobile_body_title_paymentrow_inputRow">
                      <input
                        type="text"
                        className="creator_mobile_body_title_paymentrow_inputRow_input"
                        placeholder="Vorname"
                      />
                    </div>
                    <div className="creator_mobile_body_title_paymentrow_inputRow">
                      <input
                        type="text"
                        className="creator_mobile_body_title_paymentrow_inputRow_input"
                        placeholder="Nachname"
                      />
                    </div>
                  </div>
                  <div className="creator_mobile_body_title_paymentHalfRow">
                    <div className="creator_mobile_body_title_paymentrow_inputRow">
                      <input
                        type="text"
                        className="creator_mobile_body_title_paymentrow_inputRow_input"
                        placeholder="Straße"
                      />
                    </div>
                    <div className="creator_mobile_body_title_paymentrow_inputRow">
                      <input
                        type="number"
                        className="creator_mobile_body_title_paymentrow_inputRow_input"
                        placeholder="Hausnummer"
                      />
                    </div>
                  </div>
                  <div className="creator_mobile_body_title_paymentHalfRow">
                    <div className="creator_mobile_body_title_paymentrow_inputRow">
                      <input
                        type="number"
                        className="creator_mobile_body_title_paymentrow_inputRow_input"
                        placeholder="Postleitzahl"
                      />
                    </div>
                    <div className="creator_mobile_body_title_paymentrow_inputRow">
                      <input
                        type="text"
                        className="creator_mobile_body_title_paymentrow_inputRow_input"
                        placeholder="Stadt"
                      />
                    </div>
                  </div>
                </div>
                <div className="creator_mobile_body_payment_section">
                  <div className="creator_mobile_body_title_paymenttitle">
                    <div className="creator_mobile_body_title_paymenttitle_icon">
                      <PaymentIcon />
                    </div>
                    <div className="creator_mobile_body_title_paymenttitle_text">
                      Rechnungsdaten
                    </div>
                  </div>
                  <div className="creator_mobile_body_title_paymentFullRow">
                    <div className="creator_mobile_body_title_paymentrow_inputRow">
                      <select
                        type="text"
                        className="creator_mobile_body_title_paymentrow_inputRow_input"
                      >
                        <option>VISA</option>
                        <option>Mastercard</option>
                        <option>Apple Pay</option>
                        <option>Google Pay</option>
                        <option>Paypal</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="creator_mobile_body_payment_section">
                  <div className="creator_mobile_body_title_text">
                    Indem ich auf den unten stehenden Button klicke, stimme ich
                    Allgemeinen Geschäftsbedingungen zu. Es gelten die
                    Zahlungsbedingungen zwischen dir und Servist Software GmbH.
                  </div>
                </div>
                <div className="creator_mobile_body_payment_section">
                  <div className="creator_mobile_body_payment_cta">
                    Bezahlen
                  </div>
                </div>
              </div>
            </div>
          )}
          {progressMobile == 4 && (
            <div className="creator_mobile_body">
              <div className="creator_mobile_body_title">
                Sende dein Produkt
              </div>
              <div className="creator_mobile_body_shipping">
                <div className="creator_mobile_body_shipping_adress">
                  <div className="creator_mobile_body_shipping_adress_text">
                    Sende dein Paket an die folgende Adresse:
                  </div>
                  <div className="creator_mobile_body_shipping_adress_adress">
                    <div className="creator_mobile_body_shipping_adress_adress_row">
                      Vorname Nachname
                    </div>
                    <div className="creator_mobile_body_shipping_adress_adress_row">
                      Straße Hausnummer
                    </div>
                    <div className="creator_mobile_body_shipping_adress_adress_row">
                      Postleitzahl, Stadt
                    </div>
                  </div>
                </div>
                <div className="creator_mobile_body_shipping_sent">
                  <div className="creator_mobile_body_shipping_sent_text">
                    Bitte bestätige für die Influencer:in den Versand deines
                    Pakets
                  </div>
                  <div className="creator_mobile_body_shipping_sent_cta">
                    Paket wurde versandt
                  </div>
                </div>
              </div>
            </div>
          )}
          {progressMobile == 5 && (
            <div className="creator_mobile_body">
              <div className="creator_mobile_body_title">
                Warte auf deine Auslieferung
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="home">
          <DesktopTopNavbar
            topnavBusiness={topnavBusiness}
            setTopnavBusiness={setTopnavBusiness}
            authToken={authToken}
            companyData={companyData}
          />

          <div className="home_left">
            <div className="home_left_title">
              <div className="home_left_title_left">
                <div
                  className="home_left_title_button"
                  onClick={() => navigate("/")}
                >
                  <ArrowBackIcon />
                </div>
                <div className="home_left_title_headline"></div>
              </div>
              <div className="home_left_title_right"></div>
            </div>
            <div className="home_left_scrollbar">
              <div className="creator_profilerow">
                <div className="creator_profilerow_left">
                  <div className="creator_profilerow_left_images">
                    {!isEmpty(creatorData) && (
                      <div className="">
                        <img
                          className="creator_profilerow_left_images_image"
                          src={
                            !isEmpty(creatorData) &&
                            JSON.parse(creatorData.pictures)[0].url
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="creator_profilerow_left_description">
                    <div className="creator_profilerow_left_description_top">
                      {!isEmpty(creatorData) && creatorData.companyname}
                    </div>
                    <div className="creator_profilerow_left_description_bottom">
                      {!isEmpty(creatorData) && creatorData.description}
                    </div>
                  </div>
                </div>

                <div className="creator_profilerow_right">
                  <div className="creator_profilerow_right_headline">
                    <div className="creator_profilerow_right_headline_top">
                      Influencer Kooperation
                    </div>
                    <div className="creator_profilerow_right_headline_bottom">
                      Beauftrage{" "}
                      {!isEmpty(creatorData) && creatorData.companyname} als
                      deinen Influencer.
                    </div>
                    <div className="creator_profilerow_right_price">
                      {!isEmpty(creatorData) && creatorData.priceInstagramStory}
                      €/Woche
                    </div>
                    <div
                      className="creator_profilerow_right_cta"
                      onClick={() => handleUpload()}
                    >
                      Kampagne planen
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile_coopconfig">
                <div className="profile_coopconfig_headline">
                  <div className="profile_coopconfig_headline_left">
                    <div className="profile_coopconfig_headline_big">
                      {!isEmpty(creatorData) && (
                        <FollowerNumber
                          followerInstagram={
                            !isEmpty(creatorData.followerInstagram)
                              ? creatorData.followerInstagram
                              : null
                          }
                          followerTiktok={
                            !isEmpty(creatorData.followerTiktok)
                              ? creatorData.followerTiktok
                              : null
                          }
                          followerYoutube={
                            !isEmpty(creatorData.followerYoutube)
                              ? creatorData.followerYoutube
                              : null
                          }
                          followerFacebook={
                            !isEmpty(creatorData.followerFacebook)
                              ? creatorData.followerFacebook
                              : null
                          }
                          followerLinkedIn={
                            !isEmpty(creatorData.followerLinkedIn)
                              ? creatorData.followerLinkedIn
                              : null
                          }
                          followerSnapchat={
                            !isEmpty(creatorData.followerSnapchat)
                              ? creatorData.followerSnapchat
                              : null
                          }
                        />
                      )}{" "}
                    </div>
                    <div className="profile_coopconfig_headline_small">
                      Follower
                    </div>
                  </div>
                  <div className="profile_coopconfig_headline_right"></div>
                </div>
                <div className="profile_embededs">
                  {!isEmpty(creatorData) &&
                    !isEmpty(creatorData.instagramProfileURL) &&
                    !isEmpty(creatorData.followerInstagram) && (
                      <div className="profile_embededs_element">
                        <iframe
                          className="profile_embededs_element_insta"
                          src={
                            "https://www.instagram.com/" +
                            creatorData.instagramProfileURL +
                            "/embed/"
                          }
                          scrolling="no"
                        ></iframe>
                      </div>
                    )}

                  {!isEmpty(creatorData) &&
                    !isEmpty(creatorData.tiktokProfileURL) &&
                    !isEmpty(creatorData.followerTiktok) && (
                      <TikTokEmbed accountname={creatorData.tiktokProfileURL} />
                    )}
                </div>
              </div>
              <div className="placeholder120px"></div>
              <Footerbar />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const HomeIcon = styled(MdHome)`
  font-size: 33px;
  color: #575757;
`;

const PaymentIcon = styled(MdCreditCard)`
  font-size: 33px;
  color: #575757;
`;

const DeleteIcon = styled(MdDelete)`
  font-size: 20px;
  color: #575757;
`;

const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: 1px;
  margin-left: 10px;
`;

const ArrowTopIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  transform: rotateY(180deg) rotate(90deg);
  margin-bottom: -10px;
  margin-left: 10px;
`;

const ArrowBottomIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  transform: rotateY(180deg) rotate(-90deg);
  margin-bottom: 10px;
  margin-left: 10px;
`;

const AddIcon = styled(MdAdd)`
  font-size: 38px;
  color: #ffffff;
  margin-top: 4px;
`;

const RoundAddIcon = styled(MdAddCircle)`
  font-size: 36px;
  color: #f58700;
  margin-top: 4px;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 26px;
  color: #575757;
`;

const PlayIcon = styled(MdPlayArrow)`
  font-size: 33px;
  color: #575757;
`;

const PersonIcon = styled(MdPerson)`
  font-size: 33px;
  color: #575757;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #575757;
`;

const CampaignIcon = styled(MdCampaign)`
  font-size: 33px;
  color: #575757;
`;

const ContentIcon = styled(MdPlayCircle)`
  font-size: 33px;
  color: #575757;
`;

const WebsiteIcon = styled(MdWeb)`
  font-size: 33px;
  color: #575757;
`;

const UploadIcon = styled(MdUploadFile)`
  font-size: 33px;
  color: #575757;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const AdIcon = styled(MdVideocam)`
  font-size: 22px;
  color: #ffffff;
`;

const CheckIcon = styled(MdCheck)`
  font-size: 22px;
  color: #ffffff;
`;

const ShipmentIcon = styled(MdLocalShipping)`
  font-size: 22px;
  color: #ffffff;
`;

const LiveICon = styled(MdCampaign)`
  font-size: 22px;
  color: #ffffff;
`;

const ProductIcon = styled(MdShoppingCart)`
  font-size: 33px;
  color: #575757;
`;
