import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdImage,
  MdMenu,
  MdSearch,
  MdSettings,
} from "react-icons/md";

import "../styles/Profile.css";
import TopNavbar from "../components/navbars/DesktopTopNavbar";
import { ServicesBar } from "../components/cards/ServicesBar";
import { isEmpty } from "../functions/isEmpty";
import { useNavigate } from "react-router";
import Bottomnavbar from "../components/navbars/Bottomnavbar";
import Spinner from "../components/CTA/Spinner";
import DesktopTopNavbar from "../components/navbars/DesktopTopNavbar";
import Privacy from "../texts/Privacy";
import Imprint from "../texts/Imprint";
import Footerbar from "../components/navbars/Footerbar";

export default function Legal({
  isMobile,
  apiURL,
  authToken,
  services,
  companyData,
  serviceCategories,
  destroySession,
  reload,
  setReload,
  topnavBusiness,
  setTopnavBusiness,
}) {
  const [profile, setProfile] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(companyData)) {
      setProfile(companyData);
    }

    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [companyData, authToken]);

  const logout = () => {
    destroySession();
  };

  return (
    <>
      <div className="profile">
        <DesktopTopNavbar
          topnavBusiness={topnavBusiness}
          setTopnavBusiness={setTopnavBusiness}
          authToken={authToken}
          companyData={companyData}
        />

        <div className="home_left">
          <div className="profile_toprow">
            <div className="profile_toprow_left"></div>
            <div className="profile_toprow_right">
              {/*
            <div
              className="profile_toprow_right_nav"
              onClick={() => navigate("/profile")}>
              Zurück zum Profil
            </div>  
  */}
            </div>
          </div>
          <div className="profile_settings">
            <Privacy />
            <Imprint />
          </div>
          <Footerbar />
        </div>
      </div>
    </>
  );
}

const SettingsIcon = styled(MdSettings)`
  font-size: 25px;
  color: #575757;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const CheckIcon = styled(MdCheckCircleOutline)`
  font-size: 33px;
  color: #757575;
`;

const CheckIconFilled = styled(MdCheckCircle)`
  font-size: 33px;
  color: #f58700;
`;
