import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowBackIos,
  MdBusiness,
  MdBusinessCenter,
  MdCampaign,
  MdChildCare,
  MdFavoriteBorder,
  MdFitnessCenter,
  MdLanguage,
  MdLightMode,
  MdMenu,
  MdMonetizationOn,
  MdOutlineCampaign,
  MdOutlineMonetizationOn,
  MdOutlineShoppingCart,
  MdPersonOutline,
  MdPets,
  MdPlayCircleFilled,
  MdPlayCircleOutline,
  MdRestaurant,
  MdSearch,
  MdShield,
  MdShoppingCart,
  MdSports,
  MdSportsMma,
} from "react-icons/md";
import "../../styles/Topnavbar.css";
import { useLocation, useNavigate } from "react-router";
import {
  FaBlog,
  FaDollarSign,
  FaGamepad,
  FaLanguage,
  FaTshirt,
} from "react-icons/fa";
import { FaPerson, FaSnapchat } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";

import { useLanguage } from "../../contexts/LanguageContext";
import texts from "../../texts/Texts";
import Bottomnavbar from "./Bottomnavbar";
import { isEmpty } from "../../functions/isEmpty";

export default function Footerbar({
  isMobile,
  filterBranch,
  setFilterBranch,
  authToken,
  setFilterProductStart,
  setFilterProductEnd,
  filterProductOrService,
  setFilterProductOrService,
  setOpenFilter,
  cart,
  topnavBusiness,
  setTopnavBusiness,
  companyData,
}) {
  let navigate = useNavigate();
  const { language, switchLanguage } = useLanguage();
  const location = useLocation();
  const [dropdownShown, setDropdownShown] = useState(false);

  return <div className="footerbar">© letsfluence | Servist Software GmbH</div>;
}

const ArrowBack = styled(MdArrowBack)`
  font-size: 22px;
  color: #575757;
  margin-top: 1px;
  margin-right: 5px;
`;

const ArrowDropdown = styled(MdArrowBackIos)`
  font-size: 18px;
  color: #222222;
  transform: rotate(-90deg);
  margin-left: 2px;
`;

const MenuIcon = styled(MdMenu)`
  font-size: 29px;
  color: #222222;
`;

const DropDownIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;

const FavoriteIcon = styled(MdFavoriteBorder)`
  font-size: 26px;
  color: #8f8f8f;
`;

const OrderIcon = styled(MdOutlineCampaign)`
  font-size: 26px;
  color: #8f8f8f;
`;

const PersonIcon = styled(MdPersonOutline)`
  font-size: 26px;
  color: #3b3b3b;
`;

const ShoppingCartIcon = styled(MdOutlineShoppingCart)`
  font-size: 26px;
  color: #3b3b3b;
`;

const FashionIcon = styled(FaTshirt)`
  font-size: 26px;
  color: #8f8f8f;
`;

const LanguageIcon = styled(MdLanguage)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BeautyIcon = styled(BsStars)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BusinessIcon = styled(FaDollarSign)`
  font-size: 26px;
  color: #8f8f8f;
`;

const GamingIcon = styled(FaGamepad)`
  font-size: 26px;
  color: #8f8f8f;
`;

const FoodIcon = styled(MdRestaurant)`
  font-size: 26px;
  color: #8f8f8f;
`;

const FamilyIcon = styled(MdChildCare)`
  font-size: 26px;
  color: #8f8f8f;
`;

const SportIcon = styled(MdFitnessCenter)`
  font-size: 26px;
  color: #8f8f8f;
`;

const AssetIcon = styled(MdBusiness)`
  font-size: 26px;
  color: #8f8f8f;
`;

/* ACTIVE */

const FashionIconActive = styled(FaTshirt)`
  font-size: 26px;
  color: #f58700;
`;

const BeautyIconActive = styled(BsStars)`
  font-size: 26px;
  color: #f58700;
`;

const BusinessIconActive = styled(FaDollarSign)`
  font-size: 26px;
  color: #f58700;
`;

const GamingIconActive = styled(FaGamepad)`
  font-size: 26px;
  color: #f58700;
`;

const FoodIconActive = styled(MdRestaurant)`
  font-size: 26px;
  color: #f58700;
`;

const FamilyIconActive = styled(MdChildCare)`
  font-size: 26px;
  color: #f58700;
`;

const SportIconActive = styled(MdFitnessCenter)`
  font-size: 26px;
  color: #f58700;
`;

const AssetIconActive = styled(MdBusiness)`
  font-size: 26px;
  color: #f58700;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 26px;
  color: #8f8f8f;
`;

const SearchIconActive = styled(MdSearch)`
  font-size: 26px;
  color: #f58700;
`;

const PlayIcon = styled(MdPlayCircleOutline)`
  font-size: 26px;
  color: #8f8f8f;
`;

const PlayIconActive = styled(MdPlayCircleFilled)`
  font-size: 26px;
  color: #f58700;
`;

const MoneyIcon = styled(MdOutlineMonetizationOn)`
  font-size: 26px;
  color: #8f8f8f;
`;

const MoneyIconActive = styled(MdMonetizationOn)`
  font-size: 26px;
  color: #f58700;
`;

/* OLD



  const [coopcategories, setCoopcategories] = useState([
    { id: 1, title: "Fashion", active: 0 },
    { id: 2, title: "Beauty", active: 0 },
    { id: 3, title: "Gaming", active: 0 },
    { id: 4, title: "Finance", active: 0 },
    { id: 5, title: "Sports", active: 0 },
    { id: 6, title: "Food", active: 0 },
    { id: 7, title: "Real Estate", active: 0 },
    { id: 8, title: "Cars & Transport", active: 0 },
    { id: 9, title: "Health", active: 0 },
  ]);
  const [dropdownShown, setDropdownShown] = useState(false);

  useEffect(() => {
    if (dropdownShown) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
  }, [dropdownShown]);

  function handleClickOutside(event) {
    let dropdown = document.getElementById("dropdown");
    setDropdownShown(false);
    document.removeEventListener("click", handleClickOutside);
  }

  const showOptions = () => {
    let dropdown = document.getElementById("dropdown");
    if (dropdown.classList.contains("d-none")) {
      setDropdownShown(true);
    } else {
      setDropdownShown(false);
    }
  };

  const pickABusiness = (business) => {
    setTopnavBusiness(business);
    let dropdown = document.getElementById("dropdown");
    dropdown.classList.add("d-none");
  };

  return(
 <>
      <div className="hometopnav">
        <div className="hometopnav_logo" onClick={() => navigate("/")}>
          letsfluence
        </div>
        <div className="hometopnav_search" onClick={() => showOptions()}>
          <div className="hometopnav_search_input">
            {topnavBusiness == "" ? "Content Kategorie" : topnavBusiness}
          </div>
          <div className="hometopnav_search_input_iconbox">
            <DropDownIcon />
          </div>
        </div>
        <div
          id="dropdown"
          className={
            dropdownShown ? "hometopnav_dropdown" : "hometopnav_dropdown d-none"
          }>
          {coopcategories.map((cat) => (
            <div
              className="hometopnav_dropdown_item"
              onClick={() => setTopnavBusiness(cat.title)}>
              {cat.title}
            </div>
          ))}
        </div>

        <div className="hometopnav_navlinks">
          <div
            className="hometopnav_navlinks_navlink"
            onClick={() => navigate("/")}>
            Influencer
          </div>
          <div
            className="hometopnav_navlinks_navlink"
            onClick={() => navigate("/content")}>
            Kooperationen
          </div>
          <div
            className="hometopnav_navlinks_navlink"
            onClick={() => navigate("/profile")}>
            Profil
          </div>
        </div>
      </div>
    </>
        )
*/
