import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdAddCircle,
  MdAddCircleOutline,
  MdArrowBack,
  MdArrowBackIos,
  MdBusiness,
  MdBusinessCenter,
  MdCampaign,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdClose,
  MdDelete,
  MdEdit,
  MdLocalShipping,
  MdMenu,
  MdOutlinePerson,
  MdPerson,
  MdPerson2,
  MdPerson3,
  MdPersonOutline,
  MdPersonPinCircle,
  MdPlayArrow,
  MdPlayCircle,
  MdSearch,
  MdUploadFile,
  MdWeb,
} from "react-icons/md";

import "../styles/Creator.css";
import "../styles/Home.css";
import "../styles/Content.css";
import TopNavbar from "../components/navbars/DesktopTopNavbar";
import { ServicesBar } from "../components/cards/ServicesBar";
import { FaInstagram } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router";
import MediaPlayer from "../components/mediaPlayer/MediaPlayer";
import { isEmpty } from "../functions/isEmpty";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import { ProductData } from "../contexts/ProductData";
import StoryElement from "../components/CatalogComponent";
import CatalogComponent from "../components/CatalogComponent";
import useFetch from "../functions/useFetch";
import DesktopTopNavbar from "../components/navbars/DesktopTopNavbar";
import Footerbar from "../components/navbars/Footerbar";

export default function Content({
  isMobile,
  apiURL,
  authToken,
  services,
  creators,
  activeProductID,
  cart,
  setCart,
  setGlobalActiveCreator,
  templates,
  topnavBusiness,
  setTopnavBusiness,
  companyData,
  localCollabos,
  setLocalCollabos,
}) {
  const [active, setActive] = useState(1);
  const [started, setStarted] = useState(false);
  const [isInitialized, setIsInizialized] = useState(false);
  const [subPage, setSubPage] = useState("");
  const [instaReelURLOfThisWeek, setInstaReelURLOfThisWeek] = useState("");
  const [instaStoryURLOfThisWeek, setInstaStoryURLOfThisWeek] = useState("");
  const [tiktokPostURLOfThisWeek, setTiktokPostURLOfThisWeek] = useState("");
  const [tiktokStoryURLOfThisWeek, setTiktokStoryURLOfThisWeek] = useState("");
  const [clipboardText, setClipboardText] = useState("");
  const [activeProduct, setActiveProduct] = useState({});
  const [editProduct, setEditProduct] = useState({});
  const [editIndex, setEditIndex] = useState(0);
  const [lastSavedWeeknumber, setLastSavedWeeknumber] = useState(1);
  const [totalNumberPlan, setTotalNumberPlan] = useState(1);
  const [checkedNumberPlan, setcheckedNumberPlan] = useState(0);

  const [localCollab, setLocalCollab] = useState([]);

  // new product
  const [newProduct, setNewProduct] = useState({
    title: "",
    description: "",
    deliverytype: "",
    deliveryurl: "",
    deliverydiscountcode: "",
  });

  const [plan, setPlan] = useState();

  let location = useLocation().pathname;
  let navigate = useNavigate();
  const { id } = useParams();

  const {
    data: collabo,
    loading: loadingCollabo,
    error: errorCollabo,
  } = useFetch(
    `${apiURL}/api/collabos/?id=` + id,
    authToken,
    "GET",
    null,
    [id] // Additional dependencies, if needed
  );

  useEffect(() => {
    if (!isEmpty(collabo)) {
      setPlan(JSON.parse(collabo[0].plan));
    }
    if (!isEmpty(companyData)) {
      if (
        !isEmpty(collabo) &&
        !isEmpty(companyData) &&
        collabo[0].buyerID == companyData.id
      ) {
        setActive(1);
      }
    }
  }, [collabo, companyData]);

  useEffect(() => {
    if (id.substring(0, 5) == "local") {
      let collnew = JSON.parse(
        localStorage.getItem("@letsfluencelocalCollabos")
      );
      if (!isEmpty(collnew)) {
        setPlan(JSON.parse(collnew[id.substring(5, 6) - 1].plan));
        setLocalCollab(collnew[id.substring(5, 6) - 1]);
        setIsInizialized(true);
      }
    }
  }, [localCollabos]);

  useEffect(() => {
    if (!isEmpty(collabo)) {
      quickSaveUpload();
    }
    if (
      id.substring(0, 5) == "local" &&
      !isEmpty(localCollabos) &&
      isInitialized &&
      plan !=
        {
          collabo: "requested",
          preperation: {
            products: [],
            services: [],
            company: [],
          },
          weeks: [
            {
              weeknumber: 1,
              content: {},
              note: "",
              adobject: {},
              started: 0,
              deliveryInstagramReels: "",
              deliveryInstagramStory: "",
              deliveryTiktokPost: "",
              deliveryTiktokStory: "",
            },
          ],
        }
    ) {
      let collnew = JSON.parse(
        localStorage.getItem("@letsfluencelocalCollabos")
      );
      if (!isEmpty(collnew)) {
        console.log(
          JSON.parse(collnew[id.substring(5, 6) - 1].plan).preperation.products
        );
      }

      updateLocal();

      //problem ist, dass beim initialisieren der lokale state mit leeren daten überschrieben wird.
      // ich will dass er erst überschreibt wenn "später" eine änderung durchgeführt wird.
      // mit fetch ist das kein problem weil es paar sek dauert bis der fetch da ist
    }
  }, [plan]);

  const updateLocal = () => {
    if (!isEmpty(localCollabos)) {
      const updatedLocalCollabos = [...localCollabos];
      updatedLocalCollabos[id.substring(5, 6) - 1].plan = JSON.stringify(plan);
      localStorage.setItem(
        "@letsfluencelocalCollabos",
        JSON.stringify(updatedLocalCollabos)
      );
    }
  };

  const quickSaveUpload = async () => {
    const resa = await fetch(`${apiURL}/api/collabos/?id=` + id, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        plan: JSON.stringify(plan),
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = () => {};

  const addOneMoreWeek = () => {
    const updatedPlan = { ...plan }; // Shallow copy of plan object
    updatedPlan.weeks.push({
      weeknumber: updatedPlan.weeks.length + 1,
      content: {},
      note: "",
      adobject: plan.weeks[active - 1].adobject,
      started: 0,

      deliveryInstagramReels: "",
      deliveryInstagramStory: "",
      deliveryTiktokPost: "",
      deliveryTiktokStory: "",
    });
    setPlan(updatedPlan); // Update state with the modified plan object
  };

  const removeWeek = () => {
    const updatedWeeks = plan.weeks.filter(
      (week) => week.weeknumber !== active
    );
    const updatedPlan = {
      ...plan,
      weeks: updatedWeeks,
    };

    //wochennummern neu sortieren
    let number = 1;
    updatedPlan.weeks.map((week) => {
      week.weeknumber = number;
      number++;
    });

    if (active != 1) {
      setActive(active - 1);
      setPlan(updatedPlan);
    } else {
      setActive("prep");
    }
  };

  const start = () => {
    if (id.substring(0, 5) == "local") {
      let cartObject = localCollab;
      cartObject.plan = JSON.stringify(plan);
      setCart(cartObject);

      navigate("/login");
    } else {
      let cartObject = collabo[0];
      cartObject.plan = JSON.stringify(plan);
      setCart(cartObject);

      const updatedPlan = { ...plan };
      updatedPlan.weeks.forEach((week) => {
        week.started = 1;
      });

      setPlan(updatedPlan);

      navigate("/checkout");
    }
  };

  const setNote = (noteString) => {
    const updatedPlan = { ...plan };
    updatedPlan.weeks.forEach((week) => {
      if (active === week.weeknumber) {
        week.note = noteString;
      }
    });
    setPlan(updatedPlan);
  };

  const addObjectToPrep = (destination) => {
    const updatedPlan = { ...plan }; // Shallow copy of plan object

    if (destination == "products") {
      updatedPlan.preperation.products.push({
        ...newProduct,
        category: "products",
      });

      setActiveProduct({
        ...newProduct,
        category: "products",
      });

      if (isEmpty(plan.weeks[0].adobject)) {
        updatedPlan.weeks[0].adobject = {
          ...newProduct,
          category: "products",
        };
      }
    } else if (destination == "services") {
      updatedPlan.preperation.services.push({
        ...newProduct,
        category: "services",
      });

      setActiveProduct({
        ...newProduct,
        category: "services",
      });

      if (isEmpty(plan.weeks[0].adobject)) {
        updatedPlan.weeks[0].adobject = {
          ...newProduct,
          category: "services",
        };
      }
    } else if (destination == "company") {
      updatedPlan.preperation.company.push({
        ...newProduct,
        category: "company",
      });

      setActiveProduct({
        ...newProduct,
        category: "company",
      });
      if (isEmpty(plan.weeks[0].adobject)) {
        updatedPlan.weeks[0].adobject = {
          ...newProduct,
          category: "company",
        };
      }
    }

    setPlan(updatedPlan); // Update state with the modified plan object
    setSubPage("");
    setActive(1);
  };

  const editObjectinPrep = (destination) => {
    const updatedPlan = { ...plan }; // Shallow copy of plan object
    if (destination == "products") {
      updatedPlan.preperation.products[editIndex] = editProduct;
    } else if (destination == "services") {
      updatedPlan.preperation.services[editIndex] = editProduct;
    } else if (destination == "company") {
      updatedPlan.preperation.company[editIndex] = editProduct;
    }

    setPlan(updatedPlan); // Update state with the modified plan object
    setSubPage("");
    setActive(1);
  };

  useEffect(() => {
    if (!isEmpty(plan)) {
      setTotalNumberPlan(plan.weeks.length);
      let count = 0;

      // ist ein produkt angelegt?

      // wurde der content geschrieben
      for (let i = 0; i < plan.weeks.length; i++) {
        if (!isEmpty(plan.weeks[i].adobject) && !isEmpty(plan.weeks[i].note)) {
          count++;
        }

        let started = true;
        for (let i = 0; i < plan.weeks.length; i++) {
          if (plan.weeks[i].started != 1) {
            started = false;
          }
        }

        setStarted(started);
        setcheckedNumberPlan(count);
      }
    }
  }, [plan]);

  const addContentToWeek = (content, adobject, category, text) => {
    const updatedPlan = { ...plan };
    updatedPlan.weeks.forEach((week) => {
      if (active === week.weeknumber) {
        week.content = { title: content, category: category };
        week.adobject = adobject;
      }
    });

    setNote(text);
    setPlan(updatedPlan);
  };

  useEffect(() => {
    if (!isEmpty(plan)) {
      let initializerProduct = {};

      if (!isEmpty(plan.preperation.products[0])) {
        initializerProduct = plan.preperation.products[0];
      }
      initializerProduct = plan.preperation.products[0];

      // find the current week
      let adObjectFound = false;
      for (let i = 0; i < plan.weeks.length; i++) {
        if (active == plan.weeks[i].weeknumber) {
          // set adobject to activeProduct
          setActiveProduct(plan.weeks[i].adobject);
          adObjectFound = true;
        }
      }
      if (!adObjectFound) {
        setActiveProduct(initializerProduct);
      }

      if (active != "prep") {
        setInstaReelURLOfThisWeek(
          plan.weeks[active - 1].deliveryInstagramReels
        );
        setInstaStoryURLOfThisWeek(
          plan.weeks[active - 1].deliveryInstagramStory
        );
        setTiktokPostURLOfThisWeek(plan.weeks[active - 1].deliveryTiktokPost);
        setTiktokStoryURLOfThisWeek(plan.weeks[active - 1].deliveryTikTokStory);
      }
    }
  }, [active, plan]);

  const changeActiveProductOfTheWeek = (adObject) => {
    // ich will planArray mutaten, dass in der passenden woche das product das adobject ist
    // erstmal finden

    let helpArray = { ...plan };
    // set adobject to activeProduct
    helpArray.weeks[active - 1].adobject = adObject;

    setPlan(helpArray);
    setActiveProduct(adObject);
  };

  // only allow products connected to product-content, when product changes
  useEffect(() => {
    if (!isEmpty(plan)) {
      let helpArray = { ...plan };

      for (let i = 0; i < helpArray.weeks.length; i++) {
        if (active == helpArray.weeks[i].weeknumber) {
          // set adobject to activeProduct
          if (
            helpArray.weeks[i].content.category !=
            helpArray.weeks[i].adobject.category
          ) {
            helpArray.weeks[i].content = {};
          }
        }
      }
      setPlan(helpArray);
    }
  }, [activeProduct]);

  const changeURL = (textString, field) => {
    const updatedPlan = { ...plan };
    updatedPlan.weeks.forEach((week) => {
      if (active === week.weeknumber) {
        week.deliveryInstagramReels = instaReelURLOfThisWeek;
        week.deliveryInstagramStory = instaStoryURLOfThisWeek;
        week.deliveryTiktokPost = tiktokPostURLOfThisWeek;
        week.deliveryTikTokStory = tiktokStoryURLOfThisWeek;
      }
    });

    setPlan(updatedPlan);
  };

  const setRequest = (answer) => {
    const updatedPlan = { ...plan };
    updatedPlan.collabo = answer;
    setPlan(updatedPlan);
  };

  const openEditProduct = (product, givenIndex) => {
    setLastSavedWeeknumber(active);
    setEditIndex(givenIndex);
    setEditProduct(product);
    setSubPage("editProduct");
  };

  const handleButtonBack = () => {
    if (subPage != "") {
      setSubPage("");
    } else if (active == "prep") {
      setActive(lastSavedWeeknumber);
    } else navigate("/content");
  };

  const openLinkInNewTab = (link) => {
    if (link.substring(0, 5) != "https" && link.substring(0, 3) != "www") {
      link = "https://www." + link;
    } else if (link.substring(0, 3) == "www") {
      link = "https://" + link;
    } else {
      link = link;
    }
    window.open(link, "_blank").focus();
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setClipboardText("ok!");
  };

  return (
    <div className="home">
      <DesktopTopNavbar
        topnavBusiness={topnavBusiness}
        setTopnavBusiness={setTopnavBusiness}
        authToken={authToken}
        companyData={companyData}
      />

      {!isEmpty(collabo) &&
      !isEmpty(companyData) &&
      collabo[0].buyerID != companyData.id ? (
        <div className="home_left">
          <div className="contentflex">
            <div className="contentflex_left">
              <div className="contentflex_left_topbar">
                <div
                  className="home_left_title_button"
                  onClick={() => handleButtonBack()}
                >
                  <ArrowBackIcon />
                </div>
                <div className="home_left_title_headline">
                  {id.substring(0, 5) != "local" && !isEmpty(collabo) && (
                    <img
                      src={collabo[0].buyerImageURL}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "9999px",
                      }}
                    />
                  )}
                  {id.substring(0, 5) == "local" && !isEmpty(localCollabos) && (
                    <img
                      src={localCollab.buyerImageURL}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "9999px",
                      }}
                    />
                  )}
                  Influencer Kampagne
                </div>
              </div>
              <div className="contentflex_left_body">
                <div className="deliverycontent_week">
                  Woche{" "}
                  {!isEmpty(plan) &&
                    active != "prep" &&
                    plan.weeks[active - 1].weeknumber}
                </div>

                <div className="deliverycontent_heading">
                  Erfülle vier Schritte um die Woche abzuschließen und bezahlt
                  zu werden
                </div>

                <div className="deliverycontent_box">
                  <div className="deliverycontent_box_left">
                    <div className="deliverycontent_box_left_number">1</div>
                  </div>
                  <div className="deliverycontent_box_right">
                    <div className="deliverycontent_box_right_title">
                      Erhalte das Produkt
                    </div>

                    {!isEmpty(plan) &&
                      active != "prep" &&
                      !isEmpty(plan.weeks[active - 1].adobject) &&
                      plan.weeks[active - 1].adobject.deliverytype ==
                        "webshop" && (
                        <div className="deliverycontent_box_right_content">
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_left">
                              1. Gehe auf den Webshop
                            </div>
                            <div className="deliverycontent_box_right_content_listelement_middle">
                              {plan.weeks[active - 1].adobject.deliveryurl}
                            </div>
                            <div className="deliverycontent_box_right_content_listelement_right">
                              <div
                                className="deliverycontent_box_right_content_listelement_right_button"
                                onClick={() =>
                                  openLinkInNewTab(
                                    plan.weeks[active - 1].adobject.deliveryurl
                                  )
                                }
                              >
                                Öffnen in neuem Tab
                              </div>
                            </div>
                          </div>
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_left">
                              2. Lege das Produkt in deinen Warenkorb
                            </div>
                            <div className="deliverycontent_box_right_content_listelement_middle"></div>
                            <div className="deliverycontent_box_right_content_listelement_right"></div>
                          </div>
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_left">
                              3. Gib in dem Bestellprozess dein 100% Rabattcode
                              ein:
                            </div>
                            <div className="deliverycontent_box_right_content_listelement_middle">
                              {!isEmpty(plan) && plan.collabo == "accepted"
                                ? plan.weeks[active - 1].adobject
                                    .deliverydiscountcode
                                : "Akzeptieren um Rabattcode zu zeigen"}
                            </div>
                            <div className="deliverycontent_box_right_content_listelement_right">
                              <div
                                className="deliverycontent_box_right_content_listelement_right_button"
                                onClick={() =>
                                  copyToClipboard(
                                    plan.weeks[active - 1].adobject
                                      .deliverydiscountcode
                                  )
                                }
                              >
                                {clipboardText == "" ? "Kopieren" : "Kopiert!"}
                              </div>
                            </div>
                          </div>
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_left">
                              4. Bestelle das Produkt für 0€ zu dir
                            </div>
                            <div className="deliverycontent_box_right_content_listelement_middle"></div>
                            <div className="deliverycontent_box_right_content_listelement_right"></div>
                          </div>
                        </div>
                      )}
                    {!isEmpty(plan) &&
                      active != "prep" &&
                      !isEmpty(plan.weeks[active - 1].adobject) &&
                      plan.weeks[active - 1].adobject.deliverytype ==
                        "mail" && (
                        <div className="">
                          Gib deine Adresse ein und das Produkt wird dorthin
                          gesandt
                        </div>
                      )}
                  </div>
                </div>

                <div className="deliverycontent_box">
                  <div className="deliverycontent_box_left">
                    <div className="deliverycontent_box_left_number">2</div>
                  </div>
                  <div className="deliverycontent_box_right">
                    <div className="deliverycontent_box_right_title">
                      Erstelle den Video Content
                    </div>
                    <div className="deliverycontent_box_right_content">
                      <div className="deliverycontent_box_right_content_textelement">
                        {!isEmpty(plan) &&
                          active != "prep" &&
                          plan.weeks[active - 1].note}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="deliverycontent_box">
                  <div className="deliverycontent_box_left">
                    <div className="deliverycontent_box_left_number">3</div>
                  </div>
                  <div className="deliverycontent_box_right">
                    <div className="deliverycontent_box_right_title">
                      Veröffentliche das Video auf
                    </div>
                    <div className="deliverycontent_box_right_content">
                      {!isEmpty(plan) &&
                        !isEmpty(collabo) &&
                        active != "prep" &&
                        !isEmpty(plan.weeks[active - 1].adobject) &&
                        JSON.parse(collabo[0].channels).includes(
                          "Instagram"
                        ) && (
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_left">
                              1. Instagram Reels im Haupt und Reels Raster
                            </div>
                            <div className="deliverycontent_box_right_content_listelement_right">
                              <div className="deliverycontent_box_right_content_listelement_right_button">
                                Instagram öffnen
                              </div>
                            </div>
                          </div>
                        )}
                      {!isEmpty(plan) &&
                        !isEmpty(collabo) &&
                        active != "prep" &&
                        !isEmpty(plan.weeks[active - 1].adobject) &&
                        JSON.parse(collabo[0].channels).includes(
                          "Instagram"
                        ) && (
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_left">
                              2. Instagram Story
                            </div>
                            <div className="deliverycontent_box_right_content_listelement_right">
                              <div className="deliverycontent_box_right_content_listelement_right_button">
                                Instagram öffnen
                              </div>
                            </div>
                          </div>
                        )}
                      {!isEmpty(plan) &&
                        !isEmpty(collabo) &&
                        active != "prep" &&
                        !isEmpty(plan.weeks[active - 1].adobject) &&
                        JSON.parse(collabo[0].channels).includes("Tiktok") && (
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_left">
                              3. TikTok Post
                            </div>
                            <div className="deliverycontent_box_right_content_listelement_right">
                              <div className="deliverycontent_box_right_content_listelement_right_button">
                                TikTok öffnen
                              </div>
                            </div>
                          </div>
                        )}
                      {!isEmpty(plan) &&
                        !isEmpty(collabo) &&
                        active != "prep" &&
                        !isEmpty(plan.weeks[active - 1].adobject) &&
                        JSON.parse(collabo[0].channels).includes("Tiktok") && (
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_left">
                              4. TikTok Story
                            </div>
                            <div className="deliverycontent_box_right_content_listelement_right">
                              <div className="deliverycontent_box_right_content_listelement_right_button">
                                TikTok öffnen
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="deliverycontent_box">
                  <div className="deliverycontent_box_left">
                    <div className="deliverycontent_box_left_number">4</div>
                  </div>
                  <div className="deliverycontent_box_right">
                    <div className="deliverycontent_box_right_title">
                      Teile den Link der veröffentlichten Videos
                    </div>
                    <div className="deliverycontent_box_right_content">
                      {!isEmpty(plan) &&
                        active != "prep" &&
                        !isEmpty(plan.weeks[active - 1].adobject) &&
                        JSON.parse(collabo[0].channels).includes(
                          "Instagram"
                        ) && (
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_input">
                              1. Instagram Feed URL eingeben
                              <div className="deliverycontent_inputrow">
                                <input
                                  placeholder="URL des Posts"
                                  value={instaReelURLOfThisWeek}
                                  type="text"
                                  className="deliverycontent_inputrow_input"
                                  onChange={(e) =>
                                    setInstaReelURLOfThisWeek(e.target.value)
                                  }
                                />
                                {!isEmpty(plan) &&
                                active != "prep" &&
                                plan.weeks[active - 1].deliveryInstagramReels !=
                                  instaReelURLOfThisWeek ? (
                                  <div
                                    className="deliverycontent_inputrow_savebutton"
                                    onClick={() =>
                                      changeURL(
                                        instaReelURLOfThisWeek,
                                        "instagramReels"
                                      )
                                    }
                                  >
                                    Speichern
                                  </div>
                                ) : (
                                  <>
                                    {!isEmpty(plan) &&
                                    active != "prep" &&
                                    plan.weeks[active - 1]
                                      .deliveryInstagramReels == "" ? (
                                      <div
                                        className="deliverycontent_inputrow_savebutton_inactive"
                                        onClick={() =>
                                          changeURL(
                                            instaReelURLOfThisWeek,
                                            "instagramReels"
                                          )
                                        }
                                      >
                                        URL eingeben
                                      </div>
                                    ) : (
                                      <div
                                        className="deliverycontent_inputrow_savebutton_inactive"
                                        onClick={() =>
                                          changeURL(
                                            instaReelURLOfThisWeek,
                                            "instagramReels"
                                          )
                                        }
                                      >
                                        Gespeichert
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      {!isEmpty(plan) &&
                        active != "prep" &&
                        !isEmpty(plan.weeks[active - 1].adobject) &&
                        JSON.parse(collabo[0].channels).includes(
                          "Instagram"
                        ) && (
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_input">
                              2. Instagram Story URL eingeben
                              <div className="deliverycontent_inputrow">
                                <input
                                  placeholder="URL des Posts"
                                  value={instaStoryURLOfThisWeek}
                                  type="text"
                                  className="deliverycontent_inputrow_input"
                                  onChange={(e) =>
                                    setInstaStoryURLOfThisWeek(e.target.value)
                                  }
                                />
                                {!isEmpty(plan) &&
                                active != "prep" &&
                                plan.weeks[active - 1].deliveryInstagramStory !=
                                  instaStoryURLOfThisWeek ? (
                                  <div
                                    className="deliverycontent_inputrow_savebutton"
                                    onClick={() =>
                                      changeURL(
                                        instaStoryURLOfThisWeek,
                                        "instagramStory"
                                      )
                                    }
                                  >
                                    Speichern
                                  </div>
                                ) : (
                                  <>
                                    {!isEmpty(plan) &&
                                    active != "prep" &&
                                    plan.weeks[active - 1]
                                      .deliveryInstagramStory == "" ? (
                                      <div
                                        className="deliverycontent_inputrow_savebutton_inactive"
                                        onClick={() =>
                                          changeURL(
                                            instaStoryURLOfThisWeek,
                                            "instagramStory"
                                          )
                                        }
                                      >
                                        URL eingeben
                                      </div>
                                    ) : (
                                      <div
                                        className="deliverycontent_inputrow_savebutton_inactive"
                                        onClick={() =>
                                          changeURL(
                                            instaStoryURLOfThisWeek,
                                            "instagramStory"
                                          )
                                        }
                                      >
                                        Gespeichert
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      {!isEmpty(plan) &&
                        active != "prep" &&
                        !isEmpty(plan.weeks[active - 1].adobject) &&
                        JSON.parse(collabo[0].channels).includes("Tiktok") && (
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_input">
                              3. TikTok Post URL eingeben
                              <div className="deliverycontent_inputrow">
                                <input
                                  placeholder="URL des Posts"
                                  value={tiktokPostURLOfThisWeek}
                                  type="text"
                                  className="deliverycontent_inputrow_input"
                                  onChange={(e) =>
                                    setTiktokPostURLOfThisWeek(e.target.value)
                                  }
                                />
                                {!isEmpty(plan) &&
                                active != "prep" &&
                                plan.weeks[active - 1].deliveryTiktokPost !=
                                  tiktokPostURLOfThisWeek ? (
                                  <div
                                    className="deliverycontent_inputrow_savebutton"
                                    onClick={() =>
                                      changeURL(
                                        tiktokPostURLOfThisWeek,
                                        "tiktokPost"
                                      )
                                    }
                                  >
                                    Speichern
                                  </div>
                                ) : (
                                  <>
                                    {!isEmpty(plan) &&
                                    active != "prep" &&
                                    plan.weeks[active - 1].deliveryTiktokPost ==
                                      "" ? (
                                      <div
                                        className="deliverycontent_inputrow_savebutton_inactive"
                                        onClick={() =>
                                          changeURL(
                                            tiktokPostURLOfThisWeek,
                                            "tiktokPost"
                                          )
                                        }
                                      >
                                        URL eingeben
                                      </div>
                                    ) : (
                                      <div
                                        className="deliverycontent_inputrow_savebutton_inactive"
                                        onClick={() =>
                                          changeURL(
                                            tiktokPostURLOfThisWeek,
                                            "tiktokPost"
                                          )
                                        }
                                      >
                                        Gespeichert
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                      {!isEmpty(plan) &&
                        active != "prep" &&
                        !isEmpty(plan.weeks[active - 1].adobject) &&
                        JSON.parse(collabo[0].channels).includes("Tiktok") && (
                          <div className="deliverycontent_box_right_content_listelement">
                            <div className="deliverycontent_box_right_content_listelement_input">
                              4. TikTok Story URL eingeben
                              <div className="deliverycontent_inputrow">
                                <input
                                  placeholder="URL des Posts"
                                  value={tiktokStoryURLOfThisWeek}
                                  type="text"
                                  className="deliverycontent_inputrow_input"
                                  onChange={(e) =>
                                    setTiktokStoryURLOfThisWeek(e.target.value)
                                  }
                                />
                                {!isEmpty(plan) &&
                                active != "prep" &&
                                plan.weeks[active - 1].deliveryTikTokStory !=
                                  tiktokStoryURLOfThisWeek ? (
                                  <div
                                    className="deliverycontent_inputrow_savebutton"
                                    onClick={() =>
                                      changeURL(
                                        tiktokStoryURLOfThisWeek,
                                        "tiktokStory"
                                      )
                                    }
                                  >
                                    Speichern
                                  </div>
                                ) : (
                                  <>
                                    {!isEmpty(plan) &&
                                    active != "prep" &&
                                    plan.weeks[active - 1]
                                      .deliveryTikTokStory == "" ? (
                                      <div
                                        className="deliverycontent_inputrow_savebutton_inactive"
                                        onClick={() =>
                                          changeURL(
                                            tiktokStoryURLOfThisWeek,
                                            "tiktokStory"
                                          )
                                        }
                                      >
                                        URL eingeben
                                      </div>
                                    ) : (
                                      <div
                                        className="deliverycontent_inputrow_savebutton_inactive"
                                        onClick={() =>
                                          changeURL(
                                            tiktokStoryURLOfThisWeek,
                                            "tiktokStory"
                                          )
                                        }
                                      >
                                        Gespeichert
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="padding120px"></div>
              </div>
            </div>
            <div className="contentflex_right">
              {!isEmpty(plan) && plan.collabo == "requested" && (
                <>
                  <div className="contentflex_right_requestbar">
                    <div className="contentflex_right_requestbar_title">
                      Anfrage
                    </div>
                    <div className="contentflex_right_requestbuttons">
                      <div
                        className="contentflex_right_requestbuttons_decline"
                        onClick={() => setRequest("declined")}
                      >
                        Ablehnen
                      </div>
                      <div
                        className="contentflex_right_requestbuttons_accept"
                        onClick={() => setRequest("accepted")}
                      >
                        Annehmen
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="contentflex_right_topbar">
                <div className="contentflex_right_topbar_left">
                  Content Plan
                </div>
                <div className="contentflex_right_topbar_right"></div>
              </div>
              <div className="contentflex_right_weeks">
                {!isEmpty(plan) &&
                  plan.weeks.map((week, index) => (
                    <>
                      {week.started == 1 && active == week.weeknumber ? (
                        <div className="contentflex_right_weeks_week_active">
                          <div className="contentflex_right_weeks_week_toprow">
                            <div className="contentflex_right_weeks_week_toprow_left">
                              Woche {index + 1}
                            </div>
                            <div className="contentflex_right_weeks_week_toprow_right">
                              {week.delivery != "" ? (
                                <CheckIconFilled />
                              ) : (
                                <CheckIcon />
                              )}
                            </div>
                          </div>
                          <div className="contentflex_right_weeks_week_bottomrow">
                            {week.started == 1 ? "Gestartet" : "Content wählen"}
                          </div>
                        </div>
                      ) : (
                        <>
                          {week.started == 1 && (
                            <div
                              className="contentflex_right_weeks_week"
                              onClick={() => setActive(week.weeknumber)}
                            >
                              <div className="contentflex_right_weeks_week_toprow">
                                <div className="contentflex_right_weeks_week_toprow_left">
                                  Woche {index + 1}
                                </div>
                                <div className="contentflex_right_weeks_week_toprow_right">
                                  {week.delivery != "" ? (
                                    <CheckIconFilled />
                                  ) : (
                                    <CheckIcon />
                                  )}
                                </div>
                              </div>
                              <div className="contentflex_right_weeks_week_bottomrow">
                                {week.started == 1
                                  ? "Gestartet"
                                  : "Content wählen"}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="home_left">
          <div className="contentflex">
            <div className="contentflex_left">
              <div className="contentflex_left_topbar">
                <div
                  className="home_left_title_button"
                  onClick={() => handleButtonBack()}
                >
                  <ArrowBackIcon />
                </div>
                <div className="home_left_title_headline">
                  {id.substring(0, 5) != "local" && !isEmpty(collabo) && (
                    <img
                      src={collabo[0].sellerImageURL}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "9999px",
                      }}
                    />
                  )}
                  {id.substring(0, 5) == "local" && !isEmpty(localCollabos) && (
                    <img
                      src={localCollab.sellerImageURL}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "9999px",
                      }}
                    />
                  )}
                  Influencer Kampagne
                </div>
              </div>
              <div className="contentflex_left_body">
                {subPage == "" ? (
                  <>
                    {active == "prep" ? (
                      <div className="contentflex_left_body_prep">
                        <div className="contentflex_left_body_prep_topbar">
                          <div className="contentflex_left_body_prep_topbar_left">
                            <div className="contentflex_left_body_prep_title">
                              Werbemedium anlegen
                            </div>
                            <div className="contentflex_left_body_prep_description">
                              Was soll beworben werden?
                            </div>
                          </div>
                        </div>

                        <div className="contentflex_left_body_prep_cards">
                          <div
                            className="contentflex_left_body_prep_cards_card"
                            onClick={() => setSubPage("newproduct")}
                          >
                            <div className="contentflex_left_body_prep_cards_card_top">
                              <ProductIcon />
                            </div>
                            <div className="contentflex_left_body_prep_cards_card_bottom">
                              Produkt hinzufügen
                            </div>
                          </div>
                          {/*
                          <div
                            className="contentflex_left_body_prep_cards_card"
                            onClick={() => setSubPage("newservice")}>
                            <div className="contentflex_left_body_prep_cards_card_top">
                              <ServiceIcon />
                            </div>
                            <div className="contentflex_left_body_prep_cards_card_bottom">
                              Dienstleistung hinzufügen
                            </div>
                          </div>
                          <div
                            className="contentflex_left_body_prep_cards_card"
                            onClick={() => setSubPage("newbusiness")}>
                            <div className="contentflex_left_body_prep_cards_card_top">
                              <BusinessIcon />
                            </div>
                            <div className="contentflex_left_body_prep_cards_card_bottom">
                              Unternehmen hinzufügen
                            </div>
                          </div>
                    */}
                        </div>
                        {/*
                        <div className="">
                          <div className="">Deine Produkte</div>
                          <div className="contentflex_left_body_prep_cards">
                            {!isEmpty(plan) &&
                              plan.preperation.products.map((product) => (
                                <div className="contentflex_left_body_prep_cards_card">
                                  <div className="contentflex_left_body_prep_cards_card_top">
                                    <BusinessIcon />
                                  </div>
                                  <div className="contentflex_left_body_prep_cards_card_bottom">
                                    {product.title}
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="">Deine Dienstleistungen</div>
                          <div className="contentflex_left_body_prep_cards">
                            {!isEmpty(plan) &&
                              plan.preperation.services.map((product) => (
                                <div className="contentflex_left_body_prep_cards_card">
                                  <div className="contentflex_left_body_prep_cards_card_top">
                                    <BusinessIcon />
                                  </div>
                                  <div className="contentflex_left_body_prep_cards_card_bottom">
                                    {product.title}
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="">Dein Unternehmen</div>
                          <div className="contentflex_left_body_prep_cards">
                            {!isEmpty(plan) &&
                              plan.preperation.company.map((product) => (
                                <div className="contentflex_left_body_prep_cards_card">
                                  <div className="contentflex_left_body_prep_cards_card_top">
                                    <BusinessIcon />
                                  </div>
                                  <div className="contentflex_left_body_prep_cards_card_bottom">
                                    {product.title}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                    */}
                      </div>
                    ) : (
                      <>
                        {!isEmpty(plan) &&
                        plan.weeks[active - 1].started == 1 ? (
                          <div className="content_started">
                            <div className="content_started_topline">
                              <div className="content_started_topline_top">
                                Woche :{" "}
                                {!isEmpty(plan) &&
                                  plan.weeks[active - 1].weeknumber}
                              </div>
                              <div className="content_started_topline_bottom">
                                {!isEmpty(plan) &&
                                  plan.collabo == "requested" && (
                                    <>
                                      <div className="content_started_topline_bottom_top">
                                        Status: Angefragt
                                      </div>
                                      <div className="content_started_topline_bottom_bottom">
                                        Warte bis{" "}
                                        {!isEmpty(collabo) &&
                                          collabo[0].sellerName}
                                        die Anfrage angenommen hat
                                      </div>
                                    </>
                                  )}
                                {!isEmpty(plan) &&
                                  plan.collabo == "accepted" && (
                                    <>
                                      <div className="content_started_topline_bottom_top">
                                        Status: Angenommen
                                      </div>
                                      <div className="content_started_topline_bottom_bottom">
                                        {!isEmpty(collabo) &&
                                          collabo[0].sellerName}{" "}
                                        hat die Anfrage angenommen und liefert
                                        deinen Content bald
                                      </div>
                                    </>
                                  )}
                                {!isEmpty(plan) &&
                                  plan.collabo == "declined" && (
                                    <>
                                      <div className="content_started_topline_bottom_top">
                                        Status: Abgelehnt
                                      </div>
                                      <div className="content_started_topline_bottom_bottom">
                                        Tim Waldmann hat deine Frage abgelehnt
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                            {!isEmpty(plan) &&
                              !isEmpty(collabo) &&
                              active != "prep" &&
                              !isEmpty(plan.weeks[active - 1].adobject) &&
                              JSON.parse(collabo[0].channels).includes(
                                "Instagram"
                              ) && (
                                <div className="content_started_deliverbox">
                                  <div className="content_started_deliverbox_topline">
                                    Auslieferung Instagram Feed
                                  </div>

                                  <div className="content_started_deliverbox_deliverrow">
                                    <div className="content_started_deliverbox_deliverrow_left">
                                      {!isEmpty(
                                        plan.weeks[active - 1]
                                          .deliveryInstagramReels
                                      ) ? (
                                        <img
                                          src={
                                            plan.weeks[active - 1]
                                              .deliveryInstagramReels
                                          }
                                          className="content_started_deliverbox_deliverrow_left_img"
                                        />
                                      ) : (
                                        <div className="content_started_deliverbox_deliverrow_left_placeholder"></div>
                                      )}
                                    </div>
                                    <div className="content_started_deliverbox_deliverrow_middle">
                                      {!isEmpty(
                                        plan.weeks[active - 1]
                                          .deliveryInstagramReels
                                      ) ? (
                                        <div className="content_started_deliverbox_deliverrow_middle_button">
                                          In Instagram öffnen
                                        </div>
                                      ) : (
                                        "Hier wird dein Content bereitgestellt, sobald der Influencer ihn hochgeladen hat"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            {!isEmpty(plan) &&
                              !isEmpty(collabo) &&
                              active != "prep" &&
                              !isEmpty(plan.weeks[active - 1].adobject) &&
                              JSON.parse(collabo[0].channels).includes(
                                "Instagram"
                              ) && (
                                <div className="content_started_deliverbox">
                                  <div className="content_started_deliverbox_topline">
                                    Auslieferung Instagram Story
                                  </div>

                                  <div className="content_started_deliverbox_deliverrow">
                                    <div className="content_started_deliverbox_deliverrow_left">
                                      {!isEmpty(
                                        plan.weeks[active - 1]
                                          .deliveryInstagramStory
                                      ) ? (
                                        <img
                                          src={
                                            plan.weeks[active - 1]
                                              .deliveryInstagramStory
                                          }
                                          className="content_started_deliverbox_deliverrow_left_img"
                                        />
                                      ) : (
                                        <div className="content_started_deliverbox_deliverrow_left_placeholder"></div>
                                      )}
                                    </div>
                                    <div className="content_started_deliverbox_deliverrow_middle">
                                      {!isEmpty(
                                        plan.weeks[active - 1]
                                          .deliveryInstagramStory
                                      ) ? (
                                        <div className="content_started_deliverbox_deliverrow_middle_button">
                                          In Instagram öffnen
                                        </div>
                                      ) : (
                                        "Hier wird dein Content bereitgestellt, sobald der Influencer ihn hochgeladen hat"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            <div className="content_started_yourbriefing">
                              <div className="content_started_yourbriefing_headline">
                                Dein Briefing
                              </div>
                              <div className="content_started_yourbriefing_product">
                                <div className="content_started_yourbriefing_product_headline">
                                  Dein Werbemedium
                                </div>
                                <div className="content_started_yourbriefing_product_text">
                                  {!isEmpty(plan) &&
                                    plan.weeks[active - 1].adobject.title}
                                </div>
                              </div>
                              <div className="content_started_yourbriefing_description">
                                <div className="content_started_yourbriefing_description_headline">
                                  Deine Anforderung an den Content
                                </div>
                                <div className="content_started_yourbriefing_description_text">
                                  {!isEmpty(plan) &&
                                    plan.weeks[active - 1].note}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="contentflex_left_body_content">
                            <div className="contentflex_left_body_prep_title">
                              Woche {active}
                            </div>
                            <div className="contentflex_left_body_prep_description">
                              {!isEmpty(plan) &&
                                plan.weeks[active - 1].started != 1 &&
                                active != 1 && (
                                  <div
                                    className=""
                                    onClick={() => removeWeek()}
                                  >
                                    Woche entfernen
                                  </div>
                                )}
                            </div>
                            <div className="contentflex_left_body_prep_title">
                              Was soll beworben werden
                            </div>
                            <div className="contentflex_left_body_prep_cards">
                              {!isEmpty(plan) &&
                                plan.preperation.products.map(
                                  (product, index) => (
                                    <>
                                      {activeProduct.title == product.title ? (
                                        <div className="contentflex_left_body_prep_cards_productcard_active">
                                          <div className="contentflex_left_body_prep_cards_productcard_left">
                                            <div
                                              className="contentflex_left_body_prep_cards_productcard_top"
                                              onClick={() =>
                                                changeActiveProductOfTheWeek(
                                                  product
                                                )
                                              }
                                            >
                                              <ProductIcon />
                                            </div>
                                            <div
                                              className="contentflex_left_body_prep_cards_productcard_bottom"
                                              onClick={() =>
                                                changeActiveProductOfTheWeek(
                                                  product
                                                )
                                              }
                                            >
                                              {product.title}
                                            </div>
                                          </div>

                                          <div
                                            className="contentflex_left_body_prep_cards_productcard_right"
                                            onClick={() =>
                                              openEditProduct(product, index)
                                            }
                                          >
                                            <EditIcon />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="contentflex_left_body_prep_cards_productcard">
                                          <div className="contentflex_left_body_prep_cards_productcard_left">
                                            <div
                                              className="contentflex_left_body_prep_cards_productcard_top"
                                              onClick={() =>
                                                changeActiveProductOfTheWeek(
                                                  product
                                                )
                                              }
                                            >
                                              <ProductIcon />
                                            </div>
                                            <div
                                              className="contentflex_left_body_prep_cards_productcard_bottom"
                                              onClick={() =>
                                                changeActiveProductOfTheWeek(
                                                  product
                                                )
                                              }
                                            >
                                              {product.title}
                                            </div>
                                          </div>

                                          <div
                                            className="contentflex_left_body_prep_cards_productcard_right"
                                            onClick={() =>
                                              openEditProduct(product, index)
                                            }
                                          >
                                            <EditIcon />
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )
                                )}
                              {!isEmpty(plan) &&
                                plan.preperation.services.map((product) => (
                                  <>
                                    {activeProduct?.title == product?.title ? (
                                      <div
                                        className="contentflex_left_body_prep_cards_productcard_active"
                                        onClick={() =>
                                          changeActiveProductOfTheWeek(product)
                                        }
                                      >
                                        <div className="contentflex_left_body_prep_cards_productcard_top">
                                          <ServiceIcon />
                                        </div>
                                        <div className="contentflex_left_body_prep_cards_productcard_bottom">
                                          {product.title}
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="contentflex_left_body_prep_cards_productcard"
                                        onClick={() =>
                                          changeActiveProductOfTheWeek(product)
                                        }
                                      >
                                        <div className="contentflex_left_body_prep_cards_productcard_top">
                                          <ServiceIcon />
                                        </div>
                                        <div className="contentflex_left_body_prep_cards_productcard_bottom">
                                          {product.title}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ))}
                              {!isEmpty(plan) &&
                                plan.preperation.company.map((product) => (
                                  <>
                                    {activeProduct?.title == product.title ? (
                                      <div
                                        className="contentflex_left_body_prep_cards_productcard_active"
                                        onClick={() =>
                                          changeActiveProductOfTheWeek(product)
                                        }
                                      >
                                        <div className="contentflex_left_body_prep_cards_productcard_top">
                                          <BusinessIcon />
                                        </div>
                                        <div className="contentflex_left_body_prep_cards_productcard_bottom">
                                          {product.title}
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="contentflex_left_body_prep_cards_productcard"
                                        onClick={() =>
                                          changeActiveProductOfTheWeek(product)
                                        }
                                      >
                                        <div className="contentflex_left_body_prep_cards_productcard_top">
                                          <BusinessIcon />
                                        </div>
                                        <div className="contentflex_left_body_prep_cards_productcard_bottom">
                                          {product.title}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ))}
                              <div
                                className="new_product"
                                onClick={() => setActive("prep")}
                              >
                                + Werbemittel hinzufügen
                              </div>
                            </div>
                            <div
                              className="contentflex_left_body_prep_title"
                              style={{
                                marginTop: "32px",
                                marginBottom: "-14px",
                              }}
                            >
                              Wie soll beworben werden
                            </div>
                            <div className="contentflex_left_body_prep_inputbox">
                              <input
                                type="text"
                                className="contentflex_left_body_prep_inputbox_input"
                                placeholder="Beschreibe deinen Content oder wähle eine Vorlage"
                                value={
                                  !isEmpty(plan)
                                    ? plan.weeks[active - 1].note
                                    : ""
                                }
                                onChange={(e) => setNote(e.target.value)}
                                //      uncontrolled="true"
                              />
                            </div>

                            <div className="contentflex_left_body_prep_cards">
                              {templates.map((template) => (
                                <>
                                  {!isEmpty(plan) &&
                                  plan.weeks[active - 1].content.title ==
                                    template.title_en ? (
                                    <div
                                      className="contentflex_left_body_prep_cards_card_active productcontent"
                                      onClick={() =>
                                        addContentToWeek(
                                          template.title_en,
                                          activeProduct,
                                          "products",
                                          template.description_en
                                        )
                                      }
                                    >
                                      <div className="contentflex_left_body_prep_cards_card_top">
                                        <ProductIcon />
                                      </div>
                                      <div className="contentflex_left_body_prep_cards_card_bottom">
                                        {template.title_en}
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="contentflex_left_body_prep_cards_card productcontent"
                                      onClick={() =>
                                        addContentToWeek(
                                          template.title_en,
                                          activeProduct,
                                          "products",
                                          template.description_en
                                        )
                                      }
                                    >
                                      <div className="contentflex_left_body_prep_cards_card_top">
                                        <ProductIcon />
                                      </div>
                                      <div className="contentflex_left_body_prep_cards_card_bottom">
                                        {template.title_en}
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {subPage == "newproduct" && (
                      <div className="contentflex_left_body_prep">
                        <div className="contentflex_left_body_prep_title">
                          Neues Produkt anlegen
                        </div>
                        <div className="newproduct_wrapper">
                          <div className="contentflex_left_body_prep_inputrow">
                            Produktname
                            <input
                              type="text"
                              className="input_small"
                              placeholder="Produktname"
                              value={newProduct.title}
                              onChange={(e) =>
                                setNewProduct((prevData) => ({
                                  ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                  title: e.target.value, // Aktualisiere den gewünschten Schlüssel
                                }))
                              }
                            />
                          </div>
                          <div className="contentflex_left_body_prep_inputrow">
                            Das Besondere an dem Produkt
                            <input
                              type="text"
                              className="input_large"
                              placeholder="Beschreibe das Besondere"
                              value={newProduct.description}
                              onChange={(e) =>
                                setNewProduct((prevData) => ({
                                  ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                  description: e.target.value, // Aktualisiere den gewünschten Schlüssel
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="newproduct_delivery">
                          Wie erhält der Influencer das Produkt?
                          <div
                            className="newproduct_delivery_element"
                            onClick={() =>
                              setNewProduct((prevData) => ({
                                ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                deliverytype: "mail", // Aktualisiere den gewünschten Schlüssel
                              }))
                            }
                          >
                            <div className="newproduct_delivery_element_title">
                              <div className="newproduct_delivery_element_title_left">
                                Ich sende es per Post
                              </div>
                              <div className="newproduct_delivery_element_title_right">
                                {newProduct.deliverytype == "mail" ? (
                                  <CheckIconFilled />
                                ) : (
                                  <CheckIcon />
                                )}
                              </div>
                            </div>
                            <div className="newproduct_delivery_element_description">
                              Sendeadresse wird mitgeteilt, sobald die
                              Kooperation angenommen wurde
                            </div>
                            <div className="newproduct_delivery_element_inputs"></div>
                          </div>
                          <div
                            className="newproduct_delivery_element"
                            onClick={() =>
                              setNewProduct((prevData) => ({
                                ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                deliverytype: "webshop", // Aktualisiere den gewünschten Schlüssel
                              }))
                            }
                          >
                            <div className="newproduct_delivery_element_title">
                              <div className="newproduct_delivery_element_title_left">
                                Influencer bestellt es kostenfrei in meinem
                                Webshop (empfohlen)
                              </div>
                              <div className="newproduct_delivery_element_title_right">
                                {newProduct.deliverytype == "webshop" ? (
                                  <CheckIconFilled />
                                ) : (
                                  <CheckIcon />
                                )}
                              </div>
                            </div>
                            <div className="newproduct_delivery_element_description">
                              100% Rabbattcode erforderlich
                            </div>
                            <div className="newproduct_delivery_element_inputs">
                              <input
                                type="text"
                                className="input_small"
                                placeholder="Bestell-Link"
                                value={newProduct.deliveryurl}
                                onChange={(e) =>
                                  setNewProduct((prevData) => ({
                                    ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                    deliveryurl: e.target.value, // Aktualisiere den gewünschten Schlüssel
                                  }))
                                }
                              />
                              <input
                                type="text"
                                className="input_small"
                                placeholder="100% Rabattcode"
                                value={newProduct.deliverydiscountcode}
                                onChange={(e) =>
                                  setNewProduct((prevData) => ({
                                    ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                    deliverydiscountcode: e.target.value, // Aktualisiere den gewünschten Schlüssel
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="contentflex_left_body_prep_ctarow">
                          <div
                            className="contentflex_left_body_prep_ctarow_cancelbtn"
                            onClick={() => setSubPage("")}
                          >
                            Abbrechen
                          </div>
                          <div
                            className="contentflex_left_body_prep_ctarow_savebtn"
                            onClick={() => addObjectToPrep("products")}
                          >
                            Anlegen
                          </div>
                        </div>
                      </div>
                    )}
                    {subPage == "newservice" && <></>}
                    {subPage == "newbusiness" && <></>}
                    {subPage == "editProduct" && (
                      <div className="contentflex_left_body_prep">
                        <div className="contentflex_left_body_prep_title">
                          Produkt ändern
                        </div>
                        <div className="newproduct_wrapper">
                          <div className="contentflex_left_body_prep_inputrow">
                            Produktname
                            <input
                              type="text"
                              className="input_small"
                              placeholder="Produktname"
                              value={editProduct.title}
                              onChange={(e) =>
                                setEditProduct((prevData) => ({
                                  ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                  title: e.target.value, // Aktualisiere den gewünschten Schlüssel
                                }))
                              }
                            />
                          </div>
                          <div className="contentflex_left_body_prep_inputrow">
                            Das Besondere an dem Produkt
                            <input
                              type="text"
                              className="input_large"
                              placeholder="Beschreibe das Besondere"
                              value={editProduct.description}
                              onChange={(e) =>
                                setEditProduct((prevData) => ({
                                  ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                  description: e.target.value, // Aktualisiere den gewünschten Schlüssel
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="newproduct_delivery">
                          Wie erhält der Influencer das Produkt?
                          <div
                            className="newproduct_delivery_element"
                            onClick={() =>
                              setEditProduct((prevData) => ({
                                ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                deliverytype: "mail", // Aktualisiere den gewünschten Schlüssel
                              }))
                            }
                          >
                            <div className="newproduct_delivery_element_title">
                              <div className="newproduct_delivery_element_title_left">
                                Ich sende es per Post
                              </div>
                              <div className="newproduct_delivery_element_title_right">
                                {editProduct.deliverytype == "mail" ? (
                                  <CheckIconFilled />
                                ) : (
                                  <CheckIcon />
                                )}
                              </div>
                            </div>
                            <div className="newproduct_delivery_element_description">
                              Sendeadresse wird mitgeteilt, sobald die
                              Kooperation angenommen wurde
                            </div>
                            <div className="newproduct_delivery_element_inputs"></div>
                          </div>
                          <div
                            className="newproduct_delivery_element"
                            onClick={() =>
                              setEditProduct((prevData) => ({
                                ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                deliverytype: "webshop", // Aktualisiere den gewünschten Schlüssel
                              }))
                            }
                          >
                            <div className="newproduct_delivery_element_title">
                              <div className="newproduct_delivery_element_title_left">
                                Influencer bestellt es kostenfrei in meinem
                                Webshop (empfohlen)
                              </div>
                              <div className="newproduct_delivery_element_title_right">
                                {editProduct.deliverytype == "webshop" ? (
                                  <CheckIconFilled />
                                ) : (
                                  <CheckIcon />
                                )}
                              </div>
                            </div>
                            <div className="newproduct_delivery_element_description">
                              100% Rabbattcode erforderlich
                            </div>
                            <div className="newproduct_delivery_element_inputs">
                              <input
                                type="text"
                                className="input_small"
                                placeholder="Bestell-Link"
                                value={editProduct.deliveryurl}
                                onChange={(e) =>
                                  setEditProduct((prevData) => ({
                                    ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                    deliveryurl: e.target.value, // Aktualisiere den gewünschten Schlüssel
                                  }))
                                }
                              />
                              <input
                                type="text"
                                className="input_small"
                                placeholder="100% Rabattcode"
                                value={editProduct.deliverydiscountcode}
                                onChange={(e) =>
                                  setEditProduct((prevData) => ({
                                    ...prevData, // Kopiere alle anderen Schlüssel-Wert-Paare
                                    deliverydiscountcode: e.target.value, // Aktualisiere den gewünschten Schlüssel
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="contentflex_left_body_prep_ctarow">
                          <div
                            className="contentflex_left_body_prep_ctarow_cancelbtn"
                            onClick={() => setSubPage("")}
                          >
                            Abbrechen
                          </div>
                          <div
                            className="contentflex_left_body_prep_ctarow_savebtn"
                            onClick={() => editObjectinPrep("products")}
                          >
                            Speichern
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="contentflex_right">
              <div className="contentflex_right_topbar">
                <div className="contentflex_right_topbar_left">
                  Content Plan
                </div>
                <div className="contentflex_right_topbar_right">
                  {checkedNumberPlan == totalNumberPlan ? (
                    <>
                      {started ? (
                        <div className="">Gestartet</div>
                      ) : (
                        <div
                          className="contentflex_right_topbar_right_pay"
                          onClick={() => start()}
                        >
                          Starten
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {started ? (
                        <div>
                          {" "}
                          {checkedNumberPlan}/{totalNumberPlan}
                        </div>
                      ) : (
                        <div>
                          {" "}
                          {checkedNumberPlan}/{totalNumberPlan}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="contentflex_right_weeks">
                {/*
                active == "prep" ? (
                  <div className="contentflex_right_weeks_week_active">
                    <div className="contentflex_right_weeks_week_toprow">
                      <div className="contentflex_right_weeks_week_toprow_left">
                        Vorbereitung
                      </div>
                      <div className="contentflex_right_weeks_week_toprow_right">
                        {!isEmpty(plan) &&
                        (!isEmpty(plan.preperation.products) ||
                          !isEmpty(plan.preperation.services) ||
                          !isEmpty(plan.preperation.company)) ? (
                          <CheckIconFilled />
                        ) : (
                          <CheckIcon />
                        )}
                      </div>
                    </div>
                    <div className="contentflex_right_weeks_week_bottomrow">
                      Werbemedium anlegen
                    </div>
                  </div>
                ) : (
                  <div
                    className="contentflex_right_weeks_week"
                    onClick={() => setActive("prep")}>
                    <div className="contentflex_right_weeks_week_toprow">
                      <div className="contentflex_right_weeks_week_toprow_left">
                        Vorbereitung
                      </div>
                      <div className="contentflex_right_weeks_week_toprow_right">
                        {!isEmpty(plan) &&
                        (!isEmpty(plan.preperation.products) ||
                          !isEmpty(plan.preperation.services) ||
                          !isEmpty(plan.preperation.company)) ? (
                          <CheckIconFilled />
                        ) : (
                          <CheckIcon />
                        )}
                      </div>
                    </div>
                    <div className="contentflex_right_weeks_week_bottomrow">
                      Werbemedium anlegen
                    </div>
                  </div>
                )
              */}

                {!isEmpty(plan) &&
                  plan.weeks.map((week, index) => (
                    <>
                      {active == week.weeknumber ? (
                        <div className="contentflex_right_weeks_week_active">
                          <div className="contentflex_right_weeks_week_toprow">
                            <div className="contentflex_right_weeks_week_toprow_left">
                              Woche {index + 1}
                            </div>
                            <div className="contentflex_right_weeks_week_toprow_right">
                              {!isEmpty(week.adobject) &&
                              !isEmpty(week.note) ? (
                                <CheckIconFilled />
                              ) : (
                                <CheckIcon />
                              )}
                            </div>
                          </div>
                          <div className="contentflex_right_weeks_week_bottomrow">
                            {week.started == 1 ? "Gestartet" : "Content wählen"}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="contentflex_right_weeks_week"
                          onClick={() => setActive(week.weeknumber)}
                        >
                          <div className="contentflex_right_weeks_week_toprow">
                            <div className="contentflex_right_weeks_week_toprow_left">
                              Woche {index + 1}
                            </div>
                            <div className="contentflex_right_weeks_week_toprow_right">
                              {!isEmpty(week.adobject) &&
                              !isEmpty(week.note) ? (
                                <CheckIconFilled />
                              ) : (
                                <CheckIcon />
                              )}
                            </div>
                          </div>
                          <div className="contentflex_right_weeks_week_bottomrow">
                            {week.started == 1 ? "Gestartet" : "Content wählen"}
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                <div
                  className="contentflex_right_weeks_add"
                  onClick={() => addOneMoreWeek()}
                >
                  + Weitere Woche hinzufügen
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="padding120px"></div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const DeleteIcon = styled(MdDelete)`
  font-size: 20px;
  color: #575757;
`;

const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: 1px;
  margin-left: 10px;
`;

const ArrowTopIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  transform: rotateY(180deg) rotate(90deg);
  margin-bottom: -10px;
  margin-left: 10px;
`;

const ArrowBottomIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  transform: rotateY(180deg) rotate(-90deg);
  margin-bottom: 10px;
  margin-left: 10px;
`;

const AddIcon = styled(MdAdd)`
  font-size: 38px;
  color: #ffffff;
  margin-top: 4px;
`;

const RoundAddIcon = styled(MdAddCircle)`
  font-size: 36px;
  color: #f58700;
  margin-top: 4px;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 26px;
  color: #575757;
`;

const PlayIcon = styled(MdPlayArrow)`
  font-size: 33px;
  color: #575757;
`;

const PersonIcon = styled(MdPerson)`
  font-size: 33px;
  color: #575757;
`;

const EditIcon = styled(MdEdit)`
  font-size: 20px;
  color: #575757;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #575757;
`;

const CampaignIcon = styled(MdCampaign)`
  font-size: 33px;
  color: #575757;
`;

const ContentIcon = styled(MdPlayCircle)`
  font-size: 33px;
  color: #575757;
`;

const WebsiteIcon = styled(MdWeb)`
  font-size: 33px;
  color: #575757;
`;

const UploadIcon = styled(MdUploadFile)`
  font-size: 33px;
  color: #575757;
`;

const CheckIcon = styled(MdCheckCircleOutline)`
  font-size: 33px;
  color: #757575;
`;

const CheckIconFilled = styled(MdCheckCircle)`
  font-size: 33px;
  color: #f58700;
`;

const ProductIcon = styled(MdLocalShipping)`
  font-size: 45px;
  color: #757575;
`;

const ServiceIcon = styled(MdBusinessCenter)`
  font-size: 45px;
  color: #757575;
`;

const BusinessIcon = styled(MdBusiness)`
  font-size: 45px;
  color: #757575;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;
