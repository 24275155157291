import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdAddCircle,
  MdAddCircleOutline,
  MdArrowBack,
  MdArrowBackIos,
  MdClose,
  MdDelete,
  MdMenu,
  MdOutlinePerson,
  MdPerson2,
  MdPerson3,
  MdPersonOutline,
  MdPersonPinCircle,
  MdPlayArrow,
} from "react-icons/md";

import "../styles/Landing.css";
import TopNavbar from "../components/navbars/DesktopTopNavbar";
import { ServicesBar } from "../components/cards/ServicesBar";
import { FaInstagram } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import MediaPlayer from "../components/mediaPlayer/MediaPlayer";
import { isEmpty } from "../functions/isEmpty";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import { ProductData } from "../contexts/ProductData";
import StoryElement from "../components/CatalogComponent";
import CatalogComponent from "../components/CatalogComponent";

export default function Landingpage({
  isMobile,
  apiURL,
  authToken,
  services,
  creators,
  activeProductID,
  cart,
  setCart,
  setGlobalActiveCreator,
  templates,
}) {
  const [email, setEmail] = useState("");
  const [branche, setBranche] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const { id } = useParams();

  const submitForm = async () => {
    setLoading(true);

    const resa = await fetch(
      `https://www.startinfluencermarketing.com/API/mail.php`,
      {
        method: "POST",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          email: email,
          branche: branche,
        }),
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (string) => {
    if (string == "success") {
      setSuccess("success");
    } else {
      setSuccess("error");
    }

    setLoading(false);
  };

  return (
    <>
      {isMobile ? (
        <div className="landing"></div>
      ) : (
        <div className="landing">
          <div className="landing_header">Start Influencer Marketing</div>
          <div className="landing_section">
            <div className="landing_firsttitle">Influencer Marketing</div>
            <div className="landing_firstsubtitle">für nur 100€</div>
          </div>
          <div className="landing_section">
            <div className="landing_title">Wieso so günstig?</div>
            <div className="landing_subtitle">
              Wir haben kleine Influencer, die noch wenige bis keine
              Koorperationen hatten. Für diese gibt es dieses Kennenlern-Angebot
            </div>
          </div>
          <div className="landing_section">
            <div className="landing_title">Was beinhaltet das Angebot?</div>
            <div className="landing_subtitle">
              1x Videoerstellung
              <br />
              1x Teilen auf Instagram Story
              <br />
              1x Teilen auf Instagram Reels
            </div>
          </div>
          <div className="landing_section">
            <div className="landing_title">Was bringt mir das?</div>
            <div className="landing_subtitle">
              Das Video erreicht Follower und macht diese auf auf Ihr Angebot
              aufmerksam und bewegt diese zu einem Kauf oder einer
              Kontaktaufnahme
            </div>
          </div>
          <div className="landing_section">
            <div className="landing_title">Bei Unzufriedenheit?</div>
            <div className="landing_subtitle">
              Wenn Sie unzufrieden sind, müssen Sie nicht bezahlen.
            </div>
          </div>
          <div className="landing_section">
            <div className="landing_title">Wie ist der Ablauf?</div>
            <div className="landing_subtitle">
              1. Anmelden <br />
              2. Wir kontaktieren die ersten 10 Anmeldungen passenden
              Influencern <br />
              3. Bei Gefallen: Auftrag Bestätigen <br />
              4. Influencer erstellt Video und lädt diese auf Instagram <br />
              5. Nur bei Zufriedenheit 100€ bezahlen <br />
            </div>
          </div>
          <div className="landing_lastSection">
            <div className="landing_lastSection_title">Jetzt anmelden</div>
            <input
              type="text"
              className="landing_input"
              placeholder="Ihre Emailadresse eingeben"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              className="landing_input"
              placeholder="Ihre Branche eingeben"
              value={branche}
              onChange={(e) => setBranche(e.target.value)}
            />
            <div className="landing_cta" onClick={() => submitForm()}>
              {loading ? "..." : "Anmelden"}
            </div>
            {success == "" ? (
              ""
            ) : (
              <>
                {success == "success" ? (
                  <div className="landing_message_success">
                    Anmeldung erfolgreich!
                  </div>
                ) : (
                  <div className="landing_message_error">
                    Fehler. Bitte alle Felder ausfüllen!
                  </div>
                )}
              </>
            )}
          </div>
          <div className="landing_footer">Datenschutz | Impressum</div>
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const DeleteIcon = styled(MdDelete)`
  font-size: 20px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: 3px;
  margin-left: 10px;
`;

const ArrowTopIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  transform: rotateY(180deg) rotate(90deg);
  margin-bottom: -10px;
  margin-left: 10px;
`;

const ArrowBottomIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  transform: rotateY(180deg) rotate(-90deg);
  margin-bottom: 10px;
  margin-left: 10px;
`;

const AddIcon = styled(MdAdd)`
  font-size: 38px;
  color: #ffffff;
  margin-top: 4px;
`;

const RoundAddIcon = styled(MdAddCircle)`
  font-size: 36px;
  color: #f58700;
  margin-top: 4px;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 26px;
  color: #575757;
`;

const PlayIcon = styled(MdPlayArrow)`
  font-size: 33px;
  color: #575757;
`;

const PersonIcon = styled(MdOutlinePerson)`
  font-size: 33px;
  color: #575757;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #575757;
`;
