import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdDelete,
  MdEdit,
  MdImage,
  MdMenu,
  MdSearch,
  MdSettings,
} from "react-icons/md";
import { InstagramEmbed } from "react-social-media-embed";

import "../styles/Profile.css";
import TopNavbar from "../components/navbars/DesktopTopNavbar";
import { ServicesBar } from "../components/cards/ServicesBar";
import { isEmpty } from "../functions/isEmpty";
import { useNavigate } from "react-router";
import Bottomnavbar from "../components/navbars/Bottomnavbar";
import Spinner from "../components/CTA/Spinner";
import DesktopTopNavbar from "../components/navbars/DesktopTopNavbar";
import TikTokEmbed from "../components/cards/TikTokEmbed";
import FollowerNumber from "../components/cards/FolllowerNumber";
import Footerbar from "../components/navbars/Footerbar";

export default function Profile({
  isMobile,
  apiURL,
  authToken,
  services,
  companyData,
  serviceCategories,
  destroySession,
  reload,
  setReload,
  topnavBusiness,
  setTopnavBusiness,
  edit,
  setEdit,
}) {
  const [changesHaveBeenMade, setChangesHaveBeenMade] = useState(false);
  const [profile, setProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [imageUploads, setImageUploads] = useState([]);

  const [coopcategories, setCoopcategories] = useState([
    { id: 1, title: "Fashion", active: 0 },
    { id: 2, title: "Beauty", active: 0 },
    { id: 3, title: "Gaming", active: 0 },
    { id: 4, title: "Finance", active: 0 },
    { id: 5, title: "Sports", active: 0 },
    { id: 6, title: "Food", active: 0 },
    { id: 7, title: "Real Estate", active: 0 },
    { id: 8, title: "Cars & Transport", active: 0 },
    { id: 9, title: "Health", active: 0 },
  ]);
  let navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(companyData) && companyData.isACreator == 0) {
      navigate("/influencersetup");
    } else if (isEmpty(authToken)) {
      navigate("/login");
    }

    if (!isEmpty(companyData)) {
      setProfile(companyData);
      // jedes einzelne element bei profile.collabocat durchgehen und wenn bei active eine 1 steht,
      // dann bei coopcat bei derselben id auch ne 1 setzen
      // danach nur noch mitm localstate arbeiten
      let collabolist = JSON.parse(companyData.collabocat);
      collabolist.forEach((collabo) => {
        // Check if the collabo's ID exists in any element of coopcategories
        const foundCategory = coopcategories.find(
          (category) => category.id === collabo.id && collabo.active == 1
        );

        // If a matching category is found, set its 'active' property to 1
        if (foundCategory) {
          foundCategory.active = 1;
        }
      });
      setChangesHaveBeenMade(false);
    }
  }, [companyData, authToken]);

  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [authToken]);

  const toggleCreatorMode = () => {
    let helpObject = { ...profile };
    if (helpObject.isACreator == 0) {
      helpObject.isACreator = 1;
    } else {
      helpObject.isACreator = 0;
    }
    setProfile(helpObject);
  };

  const toogleActiveCollaboCat = (element) => {
    // Update the state based on the current state
    setCoopcategories((prevArray) => {
      return prevArray.map((item) =>
        item.id === element.id
          ? { ...item, active: item.active === 1 ? 0 : 1 }
          : item
      );
    });
  };

  useEffect(() => {
    if (!isEmpty(profile)) {
      let helpObject = { ...profile };
      helpObject.collabocat = JSON.stringify(coopcategories);
      setProfile(helpObject);
    }
  }, [coopcategories]);

  const changePrice = (price) => {
    let helpObject = { ...profile };
    helpObject.priceInstagramStory = price;
    setProfile(helpObject);
  };

  const changeName = (value) => {
    let helpObject = { ...profile };
    helpObject.companyname = value;
    setProfile(helpObject);
  };

  const changeDescription = (value) => {
    let helpObject = { ...profile };
    helpObject.description = value;
    setProfile(helpObject);
  };

  const changeInstagramProfileURL = (value) => {
    let helpObject = { ...profile };
    helpObject.instagramProfileURL = value;
    setProfile(helpObject);
  };

  const changefollowerInstagram = (value) => {
    let helpObject = { ...profile };
    helpObject.followerInstagram = value;
    setProfile(helpObject);
  };

  const changeTiktokProfileURL = (value) => {
    let helpObject = { ...profile };
    helpObject.tiktokProfileURL = value;
    setProfile(helpObject);
  };

  const changefollowerTiktok = (value) => {
    let helpObject = { ...profile };
    helpObject.followerTiktok = value;
    setProfile(helpObject);
  };

  const changeYoutubeProfileURL = (value) => {
    let helpObject = { ...profile };
    helpObject.youtubeProfileURL = value;
    setProfile(helpObject);
  };

  const changefollowerYoutube = (value) => {
    let helpObject = { ...profile };
    helpObject.followerYoutube = value;
    setProfile(helpObject);
  };

  const insertLocalImage = (e) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      let filetype = "image";
      let newArray = [];
      //  imageUploads.map((imgObj) => newArray.push(imgObj));
      newArray.push({
        localURL: URL.createObjectURL(file),
        files: file,
        filetype: filetype,
      });
      setImageUploads(newArray);
    } else {
      return;
    }

    return;
  };

  const handleUploadForImage = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    // const files = document.getElementById("imgInp").files;
    const formData = new FormData();

    for (let i = 0; i < imageUploads.length; i++) {
      let file = imageUploads[i].files;

      formData.append("files[]", file);
    }

    if (!isEmpty(imageUploads) && imageUploads.length != 0) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          //  Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => addToImageURL(value));
    } else {
      handleUpload();
    }
  };

  const addToImageURL = (urlstring) => {
    // Teile den String anhand des Musters "https" auf
    const parts = urlstring.split("https");

    // Entferne leere Teile und füge "https" wieder hinzu, um vollständige URLs zu erhalten
    const urls = parts
      .filter((part) => part !== "")
      .map((part) => `https${part}`);

    handleUpload(urls);
  };

  const handleUpload = async (urlArray) => {
    if (isLoading) {
      return;
    }
    let pics = [];
    if (!isEmpty(urlArray)) {
      urlArray.map((imageurl) => pics.push({ url: imageurl }));
    } else {
      JSON.parse(profile.pictures).map((img) => pics.push(img));
    }

    setIsLoading(true);
    const resa = await fetch(
      `${apiURL}/api/creators/index.php?id=` + companyData.id,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          pictures: JSON.stringify(pics),
          collabocat: profile.collabocat,
          coopsParallel: profile.coopsParallel,
          priceInstagramStory: profile.priceInstagramStory,
          isACreator: profile.isACreator,
          companyname: profile.companyname,
          instagramProfileURL: profile.instagramProfileURL,
          followerInstagram: profile.followerInstagram,
          tiktokProfileURL: profile.tiktokProfileURL,
          followerTiktok: profile.followerTiktok,
          youtubeProfileURL: profile.youtubeProfileURL,
          followerYoutube: profile.followerYoutube,
          description: profile.description,
        }),
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setImageUploads([]);
    setReload(!reload);
    setChangesHaveBeenMade(false);
  };

  useEffect(() => {
    if (!isEmpty(companyData) && !isEmpty(profile)) {
      if (companyData != profile || !isEmpty(imageUploads)) {
        setChangesHaveBeenMade(true);
      } else {
        setChangesHaveBeenMade(false);
      }
    }
  }, [profile, companyData, imageUploads]);

  const sortImage = (index, direction) => {
    let helpObject = { ...profile };
    let lst = JSON.parse(helpObject.pictures);

    if (direction === "vor") {
      if (index > 0 && index < lst.length) {
        const element = lst.splice(index, 1)[0];
        lst.splice(index - 1, 0, element);
      }
    } else if (direction === "zurück") {
      if (index >= 0 && index < lst.length - 1) {
        const element = lst.splice(index, 1)[0];
        lst.splice(index + 1, 0, element);
      }
    }
    helpObject.pictures = JSON.stringify(lst);
    setProfile(helpObject);
  };

  const removeImage = (index, direction) => {
    let helpObject = { ...profile };
    let lst = JSON.parse(helpObject.pictures);

    if (index >= 0 && index < lst.length) {
      lst.splice(index, 1);
    }

    helpObject.pictures = JSON.stringify(lst);
    setProfile(helpObject);
  };

  const editProfile = (value) => {
    setEdit(value);
    navigate("/edit");
  };

  return (
    <div className="profile">
      <DesktopTopNavbar
        topnavBusiness={topnavBusiness}
        setTopnavBusiness={setTopnavBusiness}
        authToken={authToken}
        companyData={companyData}
      />
      <div className="home_left">
        <div className="profile_toprow">
          <div className="profile_toprow_left">Dein Influencer Inserat</div>
          <div className="profile_toprow_right">
            {!isEmpty(profile) && profile.isACreator == 1 && (
              <div
                className="profile_toprow_right_nav"
                onClick={() => navigate("/payments")}
              >
                Auszahlungen
              </div>
            )}

            {changesHaveBeenMade && (
              <div
                className="profile_toprow_right_nav_upload"
                onClick={() => handleUploadForImage()}
              >
                {isLoading ? <Spinner /> : "Änderungen Speichern"}
              </div>
            )}
          </div>
        </div>
        <div className="profile_profilerow">
          <div className="profile_profilerow_left">
            <div className="profile_images">
              <img
                src={
                  isEmpty(profile) ? "" : JSON.parse(profile.pictures)[0].url
                }
                className="profile_images_image"
              />
            </div>
            <div className="profile_profilerow_left_description">
              <div className="profile_profilerow_left_description_left">
                <div className="profile_profilerow_left_description_top">
                  {!isEmpty(profile) && profile.companyname}
                </div>
                <div className="profile_profilerow_left_description_bottom">
                  {!isEmpty(profile) && profile.description}
                </div>
              </div>
              <div className="profile_profilerow_left_description_right">
                <div
                  className="profile_edit"
                  onClick={() => editProfile("profile")}
                >
                  <EditIcon />
                </div>
              </div>
            </div>
          </div>

          <div className="profile_profilerow_right">
            <div className="profile_profilerow_right_headline">
              <div className="profile_profilerow_right_headline_left">
                Kooperationen
              </div>
              <div className="profile_profilerow_right_headline_right">
                <div
                  className="profile_edit"
                  onClick={() => editProfile("coop")}
                >
                  <EditIcon />
                </div>
              </div>
            </div>
            <div className="profile_profilerow_right_description">
              Kooperation angeboten für
            </div>
            <div className="profile_profilerow_right_price">
              {!isEmpty(profile) && profile.priceInstagramStory}€/Woche
            </div>
          </div>
        </div>

        <div className="profile_coopconfig">
          <div className="profile_coopconfig_headline">
            <div className="profile_coopconfig_headline_left">
              <div className="profile_coopconfig_headline_big">
                {!isEmpty(profile) && (
                  <FollowerNumber
                    followerInstagram={
                      !isEmpty(profile.followerInstagram)
                        ? profile.followerInstagram
                        : null
                    }
                    followerTiktok={
                      !isEmpty(profile.followerTiktok)
                        ? profile.followerTiktok
                        : null
                    }
                    followerYoutube={
                      !isEmpty(profile.followerYoutube)
                        ? profile.followerYoutube
                        : null
                    }
                    followerFacebook={
                      !isEmpty(profile.followerFacebook)
                        ? profile.followerFacebook
                        : null
                    }
                    followerLinkedIn={
                      !isEmpty(profile.followerLinkedIn)
                        ? profile.followerLinkedIn
                        : null
                    }
                    followerSnapchat={
                      !isEmpty(profile.followerSnapchat)
                        ? profile.followerSnapchat
                        : null
                    }
                  />
                )}{" "}
              </div>
              <div className="profile_coopconfig_headline_small">Follower</div>
            </div>
            <div className="profile_coopconfig_headline_right">
              <div
                className="profile_edit"
                onClick={() => editProfile("social")}
              >
                <EditIcon />
              </div>
            </div>
          </div>
          <div className="profile_embededs">
            {!isEmpty(profile) &&
              !isEmpty(profile.instagramProfileURL) &&
              !isEmpty(profile.followerInstagram) && (
                <div className="profile_embededs_element">
                  <iframe
                    className="profile_embededs_element_insta"
                    src={
                      "https://www.instagram.com/" +
                      profile.instagramProfileURL +
                      "/embed/"
                    }
                    scrolling="no"
                  ></iframe>
                </div>
              )}

            {!isEmpty(profile) &&
              !isEmpty(profile.tiktokProfileURL) &&
              !isEmpty(profile.followerTiktok) && (
                <TikTokEmbed accountname={profile.tiktokProfileURL} />
              )}
          </div>
          <div className="placeholder120px"></div>
        </div>
        <Footerbar />
      </div>
    </div>
  );
}

const SettingsIcon = styled(MdSettings)`
  font-size: 25px;
  color: #575757;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const CheckIcon = styled(MdCheckCircleOutline)`
  font-size: 33px;
  color: #757575;
`;

const CheckIconFilled = styled(MdCheckCircle)`
  font-size: 30px;
  color: #f58700;
`;

const LeftArrow = styled(MdArrowBackIos)`
  font-size: 33px;
  color: #ffffff;
`;

const RightArrow = styled(MdArrowForwardIos)`
  font-size: 33px;
  color: #ffffff;
`;

const DeleteIcon = styled(MdDelete)`
  font-size: 33px;
  color: #ffffff;
`;

const EditIcon = styled(MdEdit)`
  font-size: 25px;
  color: #474747;
`;
