import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdDelete,
  MdEdit,
  MdImage,
  MdMenu,
  MdSearch,
  MdSettings,
} from "react-icons/md";

import "../styles/Profile.css";
import TopNavbar from "../components/navbars/DesktopTopNavbar";
import { ServicesBar } from "../components/cards/ServicesBar";
import { isEmpty } from "../functions/isEmpty";
import { useNavigate } from "react-router";
import Bottomnavbar from "../components/navbars/Bottomnavbar";
import Spinner from "../components/CTA/Spinner";
import DesktopTopNavbar from "../components/navbars/DesktopTopNavbar";
import { mdiInstagram } from "@mdi/js";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import Footerbar from "../components/navbars/Footerbar";

export default function Influencersetup({
  isMobile,
  apiURL,
  authToken,
  services,
  companyData,
  serviceCategories,
  destroySession,
  reload,
  setReload,
  topnavBusiness,
  setTopnavBusiness,
}) {
  const [progress, setProgress] = useState(0);

  const [changesHaveBeenMade, setChangesHaveBeenMade] = useState(false);
  const [profile, setProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [imageUploads, setImageUploads] = useState([]);

  const [coopcategories, setCoopcategories] = useState([
    { id: 1, title: "Fashion", active: 0 },
    { id: 2, title: "Beauty", active: 0 },
    { id: 3, title: "Gaming", active: 0 },
    { id: 4, title: "Finance", active: 0 },
    { id: 5, title: "Sports", active: 0 },
    { id: 6, title: "Food", active: 0 },
    { id: 7, title: "Real Estate", active: 0 },
    { id: 8, title: "Cars & Transport", active: 0 },
    { id: 9, title: "Health", active: 0 },
  ]);

  const cities = [
    "Hamburg",
    "Berlin",
    "Bayern",
    "Bremen",
    "Schleswig-Holstein",
    "Hessen",
    "Brandenburg",
    "Thüringen",
    "Saarland",
    "Sachsen-Anhalt",
    "Niedersachsen",
    "Nordrhein-Westfalen",
    "Mecklemburg-Vorpommern",
    "Niedersachsen",
    "Sachsen",
    "Rheinland-Pfalz",
  ];

  let navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(authToken)) {
      // setSignUpAsACreator(true)
      navigate("/login");
    }

    if (!isEmpty(companyData)) {
      setProfile(companyData);
      // jedes einzelne element bei profile.collabocat durchgehen und wenn bei active eine 1 steht,
      // dann bei coopcat bei derselben id auch ne 1 setzen
      // danach nur noch mitm localstate arbeiten
      let collabolist = JSON.parse(companyData.collabocat);
      collabolist.forEach((collabo) => {
        // Check if the collabo's ID exists in any element of coopcategories
        const foundCategory = coopcategories.find(
          (category) => category.id === collabo.id && collabo.active == 1
        );

        // If a matching category is found, set its 'active' property to 1
        if (foundCategory) {
          foundCategory.active = 1;
        }
      });
      setChangesHaveBeenMade(false);
    }
  }, [companyData, authToken]);

  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [authToken]);

  const toggleCreatorMode = () => {
    let helpObject = { ...profile };
    if (helpObject.isACreator == 0) {
      helpObject.isACreator = 1;
    } else {
      helpObject.isACreator = 0;
    }
    setProfile(helpObject);
  };

  const toogleActiveCollaboCat = (element) => {
    // Update the state based on the current state
    setCoopcategories((prevArray) => {
      return prevArray.map((item) =>
        item.id === element.id
          ? { ...item, active: item.active === 1 ? 0 : 1 }
          : item
      );
    });
  };

  useEffect(() => {
    if (!isEmpty(profile)) {
      let helpObject = { ...profile };
      helpObject.collabocat = JSON.stringify(coopcategories);
      setProfile(helpObject);
    }
  }, [coopcategories]);

  const changePrice = (price) => {
    let helpObject = { ...profile };
    helpObject.priceInstagramStory = price;
    setProfile(helpObject);
  };

  const changeName = (value) => {
    let helpObject = { ...profile };
    helpObject.companyname = value;
    setProfile(helpObject);
  };

  const changeDescription = (value) => {
    let helpObject = { ...profile };
    helpObject.description = value;
    setProfile(helpObject);
  };

  const changeInstagramProfileURL = (value) => {
    let helpObject = { ...profile };
    helpObject.instagramProfileURL = value;
    setProfile(helpObject);
  };

  const changefollowerInstagram = (value) => {
    let helpObject = { ...profile };
    helpObject.followerInstagram = value;
    setProfile(helpObject);
  };

  const changeTiktokProfileURL = (value) => {
    let helpObject = { ...profile };
    helpObject.tiktokProfileURL = value;
    setProfile(helpObject);
  };

  const changefollowerTiktok = (value) => {
    let helpObject = { ...profile };
    helpObject.followerTiktok = value;
    setProfile(helpObject);
  };

  const changeYoutubeProfileURL = (value) => {
    let helpObject = { ...profile };
    helpObject.youtubeProfileURL = value;
    setProfile(helpObject);
  };

  const changefollowerYoutube = (value) => {
    let helpObject = { ...profile };
    helpObject.followerYoutube = value;
    setProfile(helpObject);
  };

  const changeGender = (value) => {
    let helpObject = { ...profile };
    helpObject.gender = value;
    setProfile(helpObject);
  };

  const changeHometown = (value) => {
    let helpObject = { ...profile };
    helpObject.hometown = value;
    setProfile(helpObject);
  };

  const insertLocalImage = (e) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      let filetype = "image";
      let newArray = [];
      //  imageUploads.map((imgObj) => newArray.push(imgObj));
      newArray.push({
        localURL: URL.createObjectURL(file),
        files: file,
        filetype: filetype,
      });
      setImageUploads(newArray);
    } else {
      return;
    }

    return;
  };

  const handleUploadForImage = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    // const files = document.getElementById("imgInp").files;
    const formData = new FormData();

    for (let i = 0; i < imageUploads.length; i++) {
      let file = imageUploads[i].files;

      formData.append("files[]", file);
    }

    if (!isEmpty(imageUploads) && imageUploads.length != 0) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          //  Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => addToImageURL(value));
    } else {
      handleUpload();
    }
  };

  const addToImageURL = (urlstring) => {
    // Teile den String anhand des Musters "https" auf
    const parts = urlstring.split("https");

    // Entferne leere Teile und füge "https" wieder hinzu, um vollständige URLs zu erhalten
    const urls = parts
      .filter((part) => part !== "")
      .map((part) => `https${part}`);

    handleUpload(urls);
  };

  const handleUpload = async (urlArray) => {
    if (isLoading) {
      return;
    }
    let pics = [];
    if (!isEmpty(urlArray)) {
      urlArray.map((imageurl) => pics.push({ url: imageurl }));
    } else {
      JSON.parse(profile.pictures).map((img) => pics.push(img));
    }

    setIsLoading(true);
    const resa = await fetch(
      `${apiURL}/api/creators/index.php?id=` + companyData.id,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          pictures: JSON.stringify(pics),
          priceInstagramStory: profile.priceInstagramStory,
          isACreator: 1,
          companyname: profile.companyname,
          instagramProfileURL: profile.instagramProfileURL,
          followerInstagram: profile.followerInstagram,
          tiktokProfileURL: profile.tiktokProfileURL,
          followerTiktok: profile.followerTiktok,
          youtubeProfileURL: profile.youtubeProfileURL,
          followerYoutube: profile.followerYoutube,
          description: profile.description,
          gender: profile.gender,
          hometown: profile.hometown,
          categories: profile.categories,
        }),
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (value) => {
    setImageUploads([]);
    setReload(!reload);
    setChangesHaveBeenMade(false);
  };

  useEffect(() => {
    if (!isEmpty(companyData) && !isEmpty(profile)) {
      if (companyData != profile || !isEmpty(imageUploads)) {
        setChangesHaveBeenMade(true);
      } else {
        setChangesHaveBeenMade(false);
      }
    }
  }, [profile, companyData, imageUploads]);

  const sortImage = (index, direction) => {
    let helpObject = { ...profile };
    let lst = JSON.parse(helpObject.pictures);

    if (direction === "vor") {
      if (index > 0 && index < lst.length) {
        const element = lst.splice(index, 1)[0];
        lst.splice(index - 1, 0, element);
      }
    } else if (direction === "zurück") {
      if (index >= 0 && index < lst.length - 1) {
        const element = lst.splice(index, 1)[0];
        lst.splice(index + 1, 0, element);
      }
    }
    helpObject.pictures = JSON.stringify(lst);
    setProfile(helpObject);
  };

  const removeImage = (index, direction) => {
    let helpObject = { ...profile };
    let lst = JSON.parse(helpObject.pictures);

    if (index >= 0 && index < lst.length) {
      lst.splice(index, 1);
    }

    helpObject.pictures = JSON.stringify(lst);
    setProfile(helpObject);
  };

  useEffect(
    async (urlArray) => {
      if (progress >= 4 && !isLoading) {
        handleUploadForImage();
      }

      if (companyData.isACreator == 1) {
        setIsLoading(false);

        navigate("/profile");
      }
    },

    [progress, companyData]
  );

  const categories = [
    "Alltag",
    "Lifestyle",
    "Reisen",
    "Sport",
    "Comedy",
    "Ernährung",
    "Finanzen",
    "Immobilien",
    "Autos",
  ];

  const addRemoveCategorie = (addOrRemove, value) => {
    let helpObject = { ...profile };
    if (addOrRemove == "add") {
      if (!isEmpty(profile.categories)) {
        let helpArrray = [];
        JSON.parse(helpObject.categories).map((object) =>
          helpArrray.push(object)
        );
        helpArrray.push(value);
        helpObject.categories = JSON.stringify(helpArrray);
      } else {
        let helpArrray = [];
        helpArrray.push(value);
        helpObject.categories = JSON.stringify(helpArrray);
      }
    } else if (addOrRemove == "remove") {
      if (!isEmpty(profile.categories)) {
        let helpArrray = [];
        let creatorCategories = JSON.parse(profile.categories);
        for (let y = 0; y < creatorCategories.length; y++) {
          if (creatorCategories[y] != value) {
            helpArrray.push(creatorCategories[y]);
          }
        }
        helpObject.categories = JSON.stringify(helpArrray);
      }
    }
    setProfile(helpObject);
  };

  const checkIfItsInArray = (value) => {
    if (!isEmpty(profile.categories)) {
      let categoryArray = JSON.parse(profile.categories);
      for (let i = 0; i < categoryArray.length; i++) {
        if (categoryArray[i] == value) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div className="profile">
      <DesktopTopNavbar
        topnavBusiness={topnavBusiness}
        setTopnavBusiness={setTopnavBusiness}
        authToken={authToken}
        companyData={companyData}
      />

      <div className="home_left">
        <div className="influencersetup_toprow">
          <div className="influencersetup_toprow_progressbar">
            <div
              className="influencersetup_toprow_iconbox"
              onClick={() => setProgress(1)}>
              <div className="influencersetup_toprow_iconbox_icon">
                {progress > 0 ? <CheckIconFilled /> : <CheckIcon />}
              </div>
              <div className="influencersetup_toprow_iconbox_text">
                Social Media verbinden
              </div>
            </div>
            {progress > 1 ? (
              <div className="influencersetup_toprow_bar_active"></div>
            ) : (
              <div className="influencersetup_toprow_bar"></div>
            )}{" "}
            <div
              className="influencersetup_toprow_iconbox"
              onClick={() => setProgress(2)}>
              <div className="influencersetup_toprow_iconbox_icon">
                {progress > 1 ? <CheckIconFilled /> : <CheckIcon />}
              </div>
              <div className="influencersetup_toprow_iconbox_text">
                Influencer Inserat
              </div>
            </div>
            {progress > 2 ? (
              <div className="influencersetup_toprow_bar_active"></div>
            ) : (
              <div className="influencersetup_toprow_bar"></div>
            )}
            <div
              className="influencersetup_toprow_iconbox"
              onClick={() => setProgress(3)}>
              <div className="influencersetup_toprow_iconbox_icon">
                {progress > 2 ? <CheckIconFilled /> : <CheckIcon />}
              </div>
              <div className="influencersetup_toprow_iconbox_text">
                Profildaten vervollständigen
              </div>
            </div>
          </div>

          <div
            className="influencersetup_toprow_cta"
            onClick={() => setProgress(progress + 1)}>
            {isLoading ? (
              <Spinner />
            ) : (
              <>{progress > 0 ? "Weiter" : "Influencer:in werden"}</>
            )}
          </div>
        </div>
        {progress == 0 && (
          <>
            {" "}
            <div className="influencersetup_main">
              <div className="influencersetup_main_left">
                <div className="influencersetup_main_left_headline">
                  Als Influencer:in Geld verdienen
                </div>
                <div className="influencersetup_main_left_text">
                  Unternehmen bezahlen dich für Content Creation. Lege los und
                  erhalte deine erste Kooperation mit einem Unternehmen.
                </div>
                <div
                  className="influencersetup_main_left_cta"
                  onClick={() => setProgress(2)}>
                  Influencer:in werden
                </div>
              </div>
              <div className="influencersetup_main_right">
                <img
                  src="https://framerusercontent.com/images/9aIk2UuYEJHkekCJd8uUYlnjCw.jpg?scale-down-to=1024"
                  className="influencersetup_main_right_img"
                />
              </div>
            </div>
            <div className="influencersetup_faq"></div>
          </>
        )}
        {progress == 1 && (
          <>
            <div className="influencersetup_main">
              <div className="influencersetup_main_left">
                <div className="influencersetup_main_left_headline">
                  Verbinde dein Social Media Profil
                </div>
                <div className="influencersetup_main_left_text">
                  Trage Accountname und Followerzahl von allen Accounts die du
                  hast ein. Lasse die Felder leer, wo du keinen Account oder
                  Follower hast.
                </div>
                <div className="influencersetup_main_inputrow">
                  <div className="influencersetup_main_inputrow_left">
                    <InstagramIcon />
                    Instagram
                  </div>
                  <div className="influencersetup_main_inputrow_right">
                    <div className="influencersetup_main_inputrow_right_inputbox_large">
                      <input
                        className="influencersetup_main_inputrow_right_inputbox_input_large"
                        type="text"
                        placeholder="Accountname"
                        value={!isEmpty(profile) && profile.instagramProfileURL}
                        onChange={(e) =>
                          changeInstagramProfileURL(e.target.value)
                        }
                      />
                    </div>
                    <div className="influencersetup_main_inputrow_right_inputbox_small">
                      <input
                        className="influencersetup_main_inputrow_right_inputbox_input_small"
                        type="number"
                        placeholder="Followerzahl"
                        value={!isEmpty(profile) && profile.followerInstagram}
                        onChange={(e) =>
                          changefollowerInstagram(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="influencersetup_main_inputrow">
                  <div className="influencersetup_main_inputrow_left">
                    <TikTokIcon />
                    TikTok
                  </div>
                  <div className="influencersetup_main_inputrow_right">
                    <div className="influencersetup_main_inputrow_right_inputbox_large">
                      <input
                        className="influencersetup_main_inputrow_right_inputbox_input_large"
                        type="text"
                        placeholder="Accountname"
                        value={!isEmpty(profile) && profile.tiktokProfileURL}
                        onChange={(e) => changeTiktokProfileURL(e.target.value)}
                      />
                    </div>
                    <div className="influencersetup_main_inputrow_right_inputbox_small">
                      <input
                        className="influencersetup_main_inputrow_right_inputbox_input_small"
                        type="number"
                        placeholder="Followerzahl"
                        value={!isEmpty(profile) && profile.followerTiktok}
                        onChange={(e) => changefollowerTiktok(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="influencersetup_main_inputrow">
                  <div className="influencersetup_main_inputrow_left">
                    <YouTubeIcon />
                    Youtube
                  </div>
                  <div className="influencersetup_main_inputrow_right">
                    <div className="influencersetup_main_inputrow_right_inputbox_large">
                      <input
                        className="influencersetup_main_inputrow_right_inputbox_input_large"
                        type="text"
                        placeholder="Accountname"
                        value={!isEmpty(profile) && profile.youtubeProfileURL}
                        onChange={(e) =>
                          changeYoutubeProfileURL(e.target.value)
                        }
                      />
                    </div>
                    <div className="influencersetup_main_inputrow_right_inputbox_small">
                      <input
                        className="influencersetup_main_inputrow_right_inputbox_input_small"
                        type="number"
                        placeholder="Followerzahl"
                        value={!isEmpty(profile) && profile.followerYoutube}
                        onChange={(e) => changefollowerYoutube(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {progress == 2 && (
          <>
            <div className="influencersetup_main">
              <div className="influencersetup_main_left">
                <div className="influencersetup_main_left_headline">
                  Influencer Inserat
                </div>
                <div className="influencersetup_main_left_text">
                  Unternehmen bezahlen wöchentlich für einen Beitrag den du
                  erstellen sollst. Was möchtest du wöchentlich dafür verdienen?
                </div>
                <div className="influencersetup_main_pricing">
                  <div className="influencersetup_main_pricing_left">
                    <div className="influencersetup_main_pricing_left_headline">
                      Dein Verdienst bei einer Kooperation
                    </div>
                    <div className="influencersetup_main_pricing_left_inputbox">
                      <input
                        className="influencersetup_main_pricing_left_inputbox_input"
                        type="number"
                        placeholder="Zahl eingeben"
                        value={!isEmpty(profile) && profile.priceInstagramStory}
                        onChange={(e) => changePrice(e.target.value)}
                      />
                      <div className="influencersetup_main_pricing_left_inputbox_text">
                        €/Woche
                      </div>
                      <div className="influencersetup_main_pricing_left_inputbox_icon">
                        <EditIcon />
                      </div>
                    </div>
                    <div className="influencersetup_main_pricing_left_bar"></div>
                  </div>
                  <div className="influencersetup_main_pricing_right">
                    <div className="influencersetup_main_pricing_right_headline">
                      Verdienstmöglichkeiten
                    </div>
                    <div className="influencersetup_main_pricing_right_row">
                      <div className="influencersetup_main_pricing_right_row_toptext">
                        Bei einer Kooperation
                      </div>
                      <div className="influencersetup_main_pricing_right_row_bottomtext">
                        <div className="influencersetup_main_pricing_right_row_bottomtext_large">
                          {!isEmpty(profile)
                            ? profile.priceInstagramStory
                            : isEmpty(profile.followerInstagram)
                            ? 0
                            : profile.followerInstagram +
                              isEmpty(profile.followerTiktok)
                            ? 0
                            : profile.followerTiktok +
                              isEmpty(profile.followerYoutube)
                            ? 0
                            : profile.followerYoutube * 0.1}
                          €
                        </div>
                        <div className="influencersetup_main_pricing_right_row_bottomtext_small">
                          /Woche
                        </div>
                      </div>
                    </div>
                    <div className="influencersetup_main_pricing_right_row">
                      <div className="influencersetup_main_pricing_right_row_toptext">
                        Bei zwei Kooperationen
                      </div>
                      <div className="influencersetup_main_pricing_right_row_bottomtext">
                        <div className="influencersetup_main_pricing_right_row_bottomtext_large">
                          {!isEmpty(profile)
                            ? profile.priceInstagramStory * 2
                            : isEmpty(profile.followerInstagram)
                            ? 0
                            : profile.followerInstagram +
                              isEmpty(profile.followerTiktok)
                            ? 0
                            : profile.followerTiktok +
                              isEmpty(profile.followerYoutube)
                            ? 0
                            : profile.followerYoutube * 0.2}
                          €
                        </div>
                        <div className="influencersetup_main_pricing_right_row_bottomtext_small">
                          /Woche
                        </div>
                      </div>
                    </div>
                    <div className="influencersetup_main_pricing_right_row">
                      <div className="influencersetup_main_pricing_right_row_toptext">
                        Bei drei Kooperationen
                      </div>
                      <div className="influencersetup_main_pricing_right_row_bottomtext">
                        <div className="influencersetup_main_pricing_right_row_bottomtext_large">
                          {!isEmpty(profile)
                            ? profile.priceInstagramStory * 3
                            : isEmpty(profile.followerInstagram)
                            ? 0
                            : profile.followerInstagram +
                              isEmpty(profile.followerTiktok)
                            ? 0
                            : profile.followerTiktok +
                              isEmpty(profile.followerYoutube)
                            ? 0
                            : profile.followerYoutube * 0.3}
                          €
                        </div>
                        <div className="influencersetup_main_pricing_right_row_bottomtext_small">
                          /Woche
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {progress >= 3 && (
          <>
            <div className="influencersetup_main">
              <div className="influencersetup_main_left">
                <div className="influencersetup_main_left_headline">
                  Profildaten vervollständigen
                </div>
                <div className="influencersetup_main_profildata">
                  <div className="influencersetup_main_profildata_left">
                    <div className="influencersetup_main_left_subarea_text">
                      Stelle dich und deinen Content vor, damit Unternehmen es
                      einfacher haben eine Kooperation bei dir anzufragen.
                    </div>

                    <div className="profile_images">
                      <label htmlFor={"imgInp"} className="">
                        {isEmpty(imageUploads) ? (
                          <>
                            {" "}
                            <div className="profile_images_box_deletebar">
                              <div
                                className="profile_images_box_deletebar_icon"
                                onClick={() => removeImage("a")}>
                                <EditIcon />
                              </div>
                            </div>{" "}
                            <img
                              src={
                                isEmpty(profile)
                                  ? ""
                                  : JSON.parse(profile.pictures)[0].url
                              }
                              className="profile_images_image"
                            />
                          </>
                        ) : (
                          <>
                            {imageUploads.map((creatorimg) => (
                              <>
                                <div className="profile_images_box_deletebar">
                                  <div
                                    className="profile_images_box_deletebar_icon"
                                    onClick={() => setImageUploads([])}>
                                    <DeleteIcon />
                                  </div>
                                </div>
                                <div className="">
                                  <img
                                    className="profile_images_image"
                                    src={creatorimg.localURL}
                                  />
                                </div>
                              </>
                            ))}
                          </>
                        )}
                      </label>

                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        id={"imgInp"}
                        name="files[]"
                        className="createService_inputBtn d-none"
                        onChange={(e) => insertLocalImage(e)}
                      />
                    </div>
                    <div className="influencersetup_main_left_subarea_inputbox">
                      <input
                        className="influencersetup_main_left_subarea_inputbox_input"
                        type="text"
                        placeholder="Accountname"
                        value={!isEmpty(profile) && profile.companyname}
                        onChange={(e) => changeName(e.target.value)}
                      />
                    </div>
                    <div className="influencersetup_main_left_subarea_inputbox">
                      <input
                        className="influencersetup_main_left_subarea_inputbox_input"
                        type="text"
                        placeholder="Text über dich"
                        value={!isEmpty(profile) && profile.description}
                        onChange={(e) => changeDescription(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="influencersetup_main_profildata_right">
                    <div className="influencersetup_main_right_box">
                      <div className="influencersetup_main_right_box_headline">
                        Content Kategorien
                      </div>
                      <div className="influencersetup_main_right_box_options">
                        {categories.map((category) => (
                          <>
                            {!isEmpty(profile) &&
                            checkIfItsInArray(category) ? (
                              <div
                                className="influencersetup_main_right_box_options_option_active"
                                onClick={() =>
                                  addRemoveCategorie("remove", category)
                                }>
                                {category}
                              </div>
                            ) : (
                              <div
                                className="influencersetup_main_right_box_options_option"
                                onClick={() =>
                                  addRemoveCategorie("add", category)
                                }>
                                {category}
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                    <div className="influencersetup_main_right_box">
                      <div className="influencersetup_main_right_box_headline">
                        Geschlecht
                      </div>
                      <div className="influencersetup_main_right_box_options">
                        {!isEmpty(profile) && profile.gender == "female" ? (
                          <div
                            className="influencersetup_main_right_box_options_option_active"
                            onClick={() => changeGender("female")}>
                            weiblich
                          </div>
                        ) : (
                          <div
                            className="influencersetup_main_right_box_options_option"
                            onClick={() => changeGender("female")}>
                            weiblich
                          </div>
                        )}
                        {!isEmpty(profile) && profile.gender == "male" ? (
                          <div
                            className="influencersetup_main_right_box_options_option_active"
                            onClick={() => changeGender("male")}>
                            männlich
                          </div>
                        ) : (
                          <div
                            className="influencersetup_main_right_box_options_option"
                            onClick={() => changeGender("male")}>
                            männlich
                          </div>
                        )}
                        {!isEmpty(profile) && profile.gender == "none" ? (
                          <div
                            className="influencersetup_main_right_box_options_option_active"
                            onClick={() => changeGender("none")}>
                            ohne Angabe
                          </div>
                        ) : (
                          <div
                            className="influencersetup_main_right_box_options_option"
                            onClick={() => changeGender("none")}>
                            ohne Angabe
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="influencersetup_main_right_box">
                      <div className="influencersetup_main_right_box_headline">
                        Wohnort
                      </div>
                      <div className="influencersetup_main_right_box_options">
                        {cities.map((city) => {
                          return !isEmpty(profile) &&
                            profile.hometown == city ? (
                            <div className="influencersetup_main_right_box_options_option_active">
                              {city}
                            </div>
                          ) : (
                            <div
                              className="influencersetup_main_right_box_options_option"
                              onClick={() => changeHometown(city)}>
                              {city}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footerbar />
    </div>
  );
}

const SettingsIcon = styled(MdSettings)`
  font-size: 25px;
  color: #575757;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const CheckIcon = styled(MdCheckCircle)`
  font-size: 50px;
  color: #ffeedb;
`;

const CheckIconFilled = styled(MdCheckCircle)`
  font-size: 50px;
  color: #f58700;
`;

const LeftArrow = styled(MdArrowBackIos)`
  font-size: 33px;
  color: #ffffff;
`;

const RightArrow = styled(MdArrowForwardIos)`
  font-size: 33px;
  color: #ffffff;
`;

const DeleteIcon = styled(MdDelete)`
  font-size: 33px;
  color: #ffffff;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 40px;
  color: #222222;
`;

const TikTokIcon = styled(FaTiktok)`
  font-size: 40px;
  color: #222222;
`;

const YouTubeIcon = styled(FaYoutube)`
  font-size: 40px;
  color: #222222;
`;

const EditIcon = styled(MdEdit)`
  font-size: 40px;
  color: #222222;
`;
