import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdArrowBack, MdArrowBackIos, MdMenu } from "react-icons/md";

import "../styles/Login.css";
import { useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";
import Spinner from "../components/CTA/Spinner";
import DesktopTopNavbar from "../components/navbars/DesktopTopNavbar";
import Footerbar from "../components/navbars/Footerbar";

export default function Login({
  authToken,
  email,
  setEmail,
  password,
  setPassword,
  signIn,
  signUp,
  username,
  setUsername,
  creatorData,
  shoppingCard,
  topnavBusiness,
  setTopnavBusiness,
  isUploading,
  setIsUploading,
  companyData,
}) {
  const [page, setPage] = useState("register");
  const [formIsFilled, setFormIsFilled] = useState(false);

  let navigate = useNavigate();

  // Attach an event listener when the component mounts
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is the Enter key (key code 13)
      if (event.keyCode === 13 && formIsFilled && page == "register") {
        // Call the function when Enter key is pressed
        setIsUploading(true);
        signUp();
      } else if (event.keyCode === 13 && formIsFilled && page != "register") {
        setIsUploading(true);
        signIn();
      }
    };

    // Add event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Clean up event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [formIsFilled, page, username, email, password]);

  // userdata in state packen
  useEffect(() => {
    if (authToken) {
      if (!isEmpty(shoppingCard) && !isEmpty(creatorData)) {
        navigate("/creator/" + creatorData.id + "/collab");
      } else {
        navigate("/");
      }
    }
  }, [authToken, shoppingCard, creatorData]);

  useEffect(() => {
    if (
      (page == "register" && isEmpty(username)) ||
      isEmpty(email) ||
      isEmpty(password) ||
      !validateEmail(email) ||
      password.length < 6
    ) {
      setFormIsFilled(false);
    } else {
      setFormIsFilled(true);
    }
  }, [username, email, password, page]);

  const signUpAndDisableButton = () => {
    setIsUploading(true);
    signUp();
  };

  const signInAndDisableButton = () => {
    setIsUploading(true);
    signIn();
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="login">
      <DesktopTopNavbar
        topnavBusiness={topnavBusiness}
        setTopnavBusiness={setTopnavBusiness}
        authToken={authToken}
        companyData={companyData}
      />

      <div className="collabodetail_topnav_bottom">
        {page == "login" ? (
          <>
            <div className="collabodetail_topnav_bottom_tab_active">
              Einloggen
            </div>
            <div
              className="collabodetail_topnav_bottom_tab"
              onClick={() => setPage("register")}>
              Neu Registrieren
            </div>
          </>
        ) : (
          <>
            <div
              className="collabodetail_topnav_bottom_tab"
              onClick={() => setPage("login")}>
              Einloggen
            </div>
            <div className="collabodetail_topnav_bottom_tab_active">
              Neu Registrieren
            </div>
          </>
        )}
      </div>

      {page == "register" ? (
        <div className="login_registerform">
          <div className="login_registerform_title">Neu Registrieren</div>

          <input
            type="text"
            className="login_input"
            placeholder="Name"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <input
            type="email"
            className="login_input"
            placeholder="Email-Adresse"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="password"
            className="login_input"
            placeholder="Passwort Mind. 6 Zeichen"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {formIsFilled ? (
            <>
              {!isUploading ? (
                <div
                  className="login_ctabtn"
                  onClick={() => signUpAndDisableButton()}>
                  Registrieren{" "}
                </div>
              ) : (
                <div className="login_ctabtn_disabled" disabled={"disabled"}>
                  <Spinner />
                </div>
              )}
            </>
          ) : (
            <div className="login_ctabtn_disabled" disabled={"disabled"}>
              Registrieren
            </div>
          )}
          <div className="">
            {/* username == "" ? "Benutzername muss ausgefüllt werden" : "" */}
          </div>
          {/*
          <div className="login_divider">oder</div>
          <div className="login_sociallogin">Mit Google registrieren</div>
          <div className="login_sociallogin">Mit Facebook registrieren</div>
          */}
        </div>
      ) : (
        <div className="login_registerform">
          <div className="login_registerform_title">Einloggen</div>

          <input
            type="email"
            className="login_input"
            placeholder="Email-Adresse"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="password"
            className="login_input"
            placeholder="Passwort"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {formIsFilled ? (
            <>
              {!isUploading ? (
                <div
                  className="login_ctabtn"
                  onClick={() => signInAndDisableButton()}>
                  Einloggen{" "}
                </div>
              ) : (
                <div className="login_ctabtn_disabled" disabled={"disabled"}>
                  <Spinner />
                </div>
              )}
            </>
          ) : (
            <div className="login_ctabtn_disabled" disabled={"disabled"}>
              Einloggen{" "}
            </div>
          )}
          {/*
                    <div className="login_divider">oder</div>
          <div className="login_sociallogin">Mit Google einloggen</div>
          <div className="login_sociallogin">Mit Facebook einloggen</div>
        
          */}
        </div>
      )}
      <Footerbar />
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBack)`
  font-size: 20px;
  color: #636363;
  margin-top: 2px;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;
