import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdBookmark,
  MdBookmarkBorder,
  MdBorderAll,
  MdCampaign,
  MdCheck,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdLocalShipping,
  MdMenu,
  MdPerson,
  MdPlayCircle,
  MdSearch,
  MdUpload,
  MdUploadFile,
  MdVideocam,
  MdWeb,
} from "react-icons/md";

import "../styles/Home.css";
import { ServicesBar } from "../components/cards/ServicesBar";
import { isEmpty } from "../functions/isEmpty";
import HomeTopNav from "../components/navbars/DesktopTopNavbar";
import Card from "../components/cards/Card";
import { ProductData } from "../contexts/ProductData";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import MobileCreatorRow from "../components/MobileCreatorRow";
import { useLocation, useNavigate } from "react-router";
import DesktopTopNavbar from "../components/navbars/DesktopTopNavbar";
import FollowerNumber from "../components/cards/FolllowerNumber";
import Footerbar from "../components/navbars/Footerbar";

export default function Home({
  isMobile,
  apiURL,
  authToken,
  creators,
  templates,
  topnavBusiness,
  setTopnavBusiness,
  navigation,
  setNavigation,
  companyData,
  categories,
  favorites,
  setFavorites,
  subPage,
  setSubPage,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  const [filteredCreators, setFilteredCreators] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");

  useEffect(() => {
    if (navigation != "") {
      navigate(navigation);
    }
    setNavigation("");
  }, [navigation]);

  useEffect(() => {
    let helpArrray = [];
    if (activeCategory == "") {
      setFilteredCreators(creators);
      return;
    } else if (activeCategory != "") {
      for (let i = 0; i < creators.length; i++) {
        if (!isEmpty(creators[i].categories)) {
          let creatorCategories = JSON.parse(creators[i].categories);
          for (let y = 0; y < creatorCategories.length; y++) {
            if (creatorCategories[y] == activeCategory) {
              helpArrray.push(creators[i]);
            }
          }
        }
      }
    }
    setFilteredCreators(helpArrray);
  }, [activeCategory, creators]);

  /* dummy */
  const [progressMobile, setProgressMobile] = useState(0);
  const [adsSelected, setAdsSelected] = useState({
    products: [],
    servies: [],
    gastro: [],
  });

  const handleNavigationClick = (backOrForward) => {
    if (backOrForward == "forward") {
      if (progressMobile == 3) {
        return;
      } else {
        setProgressMobile(progressMobile + 1);
      }
    } else {
      if (progressMobile == 0) {
        return;
      } else {
        setProgressMobile(progressMobile - 1);
      }
    }
  };

  const checkIfItsInArray = (value) => {
    let helpObject = { ...adsSelected };
    if (!isEmpty(helpObject)) {
      for (let i = 0; i < helpObject.products.length; i++) {
        if (helpObject.products[i].title == value) {
          return true;
        }
      }
    }
    return false;
  };

  const addToList = (object) => {
    let helpObject = [...favorites];
    helpObject.push(object);
    setFavorites(helpObject);
  };

  const removeFromList = (object) => {
    let helpArray = [];
    for (let i = 0; i < favorites.length; i++) {
      if (favorites[i].id != object.id) {
        helpArray.push(favorites[i]);
      }
    }
    setFavorites(helpArray);
  };

  const checkIfItsInFavorites = (object) => {
    for (let i = 0; i < favorites.length; i++) {
      if (favorites[i].id == object.id) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {isMobile ? (
        <div className="hello">
          <div className="home_mobile_topnav">
            <div className="home_mobile_topnav_toprow">
              <div className="home_mobile_topnav_toprow_left">
                <div className="home_mobile_topnav_toprow_left_searchbar">
                  <input
                    className="home_mobile_topnav_toprow_left_searchbar_input"
                    type="text"
                    placeholder="Suchbegriff eingeben"
                  />
                </div>
              </div>
              <div className="home_mobile_topnav_toprow_right">
                <div className="home_mobile_topnav_toprow_right_box">
                  <MenuIcon />
                </div>
              </div>
            </div>
            {subPage == "search" ? (
              <div className="home_mobile_topnav_bottomrow">
                <div className="home_mobile_topnav_bottomrow_left_active">
                  Influencer suchen
                </div>
                <div
                  className="home_mobile_topnav_bottomrow_right"
                  onClick={() => setSubPage("myList")}
                >
                  {favorites.length != 0 && (
                    <div className="home_mobile_topnav_bottomrow_right_count">
                      {favorites.length}
                    </div>
                  )}
                  Deine Liste
                </div>
              </div>
            ) : (
              <div className="home_mobile_topnav_bottomrow">
                <div
                  className="home_mobile_topnav_bottomrow_left"
                  onClick={() => setSubPage("search")}
                >
                  Influencer suchen
                </div>
                <div className="home_mobile_topnav_bottomrow_right_active">
                  {favorites.length != 0 && (
                    <div className="home_mobile_topnav_bottomrow_right_count">
                      {favorites.length}
                    </div>
                  )}
                  Deine Liste
                </div>
              </div>
            )}
          </div>
          <div className="home_mobile_body">
            {subPage == "search" ? (
              <div className="home_mobile_body_cards">
                {filteredCreators.map((creator) => (
                  <div key={creator.id} className="card">
                    <div
                      className="card_top"
                      onClick={() => navigate("/creator/" + creator.id)}
                    >
                      <div className="card_top_imagebox">
                        <img
                          src={
                            isEmpty(creator.pictures)
                              ? ""
                              : JSON.parse(creator.pictures)[0].url
                          }
                          className="card_top_imagebox_img"
                        />
                      </div>
                    </div>
                    <div className="card_bottom">
                      <div className="card_bottom_top">
                        {creator.companyname}
                      </div>
                      <div className="card_bottom_middle">
                        <div
                          className="card_bottom_middle_left"
                          onClick={() => navigate("/creator/" + creator.id)}
                        >
                          <div className="card_bottom_middle_left_top">
                            {!isEmpty(creator) && (
                              <FollowerNumber
                                followerInstagram={
                                  !isEmpty(creator.followerInstagram)
                                    ? creator.followerInstagram
                                    : null
                                }
                                followerTiktok={
                                  !isEmpty(creator.followerTiktok)
                                    ? creator.followerTiktok
                                    : null
                                }
                                followerYoutube={
                                  !isEmpty(creator.followerYoutube)
                                    ? creator.followerYoutube
                                    : null
                                }
                                followerFacebook={
                                  !isEmpty(creator.followerFacebook)
                                    ? creator.followerFacebook
                                    : null
                                }
                                followerLinkedIn={
                                  !isEmpty(creator.followerLinkedIn)
                                    ? creator.followerLinkedIn
                                    : null
                                }
                                followerSnapchat={
                                  !isEmpty(creator.followerSnapchat)
                                    ? creator.followerSnapchat
                                    : null
                                }
                              />
                            )}{" "}
                            Follower
                          </div>
                          <div className="card_bottom_middle_left_bottom">
                            {creator.priceInstagramStory} €/Post
                          </div>
                        </div>
                        <div className="card_bottom_middle_right">
                          {checkIfItsInFavorites(creator) ? (
                            <div
                              className="card_bottom_middle_right_iconbox_active"
                              onClick={() => removeFromList(creator)}
                            >
                              <BookmarkedIconFilled />
                            </div>
                          ) : (
                            <div
                              className="card_bottom_middle_right_iconbox"
                              onClick={() => addToList(creator)}
                            >
                              <BookmarkedIcon />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="home_favorites_list">
                {favorites.map((creator) => (
                  <div
                    className="home_favorites_list_element"
                    onClick={() => navigate("/creator/" + creator.id)}
                  >
                    <div className="home_favorites_list_element_top">
                      <div className="home_favorites_list_element_top_left">
                        <div className="home_favorites_list_element_top_left_imagebox">
                          <img
                            src={
                              isEmpty(creator.pictures)
                                ? ""
                                : JSON.parse(creator.pictures)[0].url
                            }
                            className="home_favorites_list_element_top_left_imagebox_img"
                          />
                        </div>
                        <div className="home_favorites_list_element_top_left_text">
                          <div className="home_favorites_list_element_top_left_text_top">
                            {creator.companyname}
                          </div>
                          <div className="home_favorites_list_element_top_left_text_bottom">
                            Followername
                          </div>
                        </div>
                      </div>

                      <div className="home_favorites_list_element_top_right">
                        <div className="home_favorites_list_element_top_right_top">
                          <BookmarkedMyList />
                        </div>
                        <div className="home_favorites_list_element_top_right_bottom">
                          Saved
                        </div>
                      </div>
                    </div>
                    <div className="home_favorites_list_element_bottom">
                      <div className="hello_topnav_bottombar">
                        <div className="hello_topnav_bottombar_box">
                          <div className="hello_topnav_bottombar_box_icon">
                            <AdIcon />
                          </div>
                          <div className="hello_topnav_bottombar_box_text">
                            Werbung
                          </div>
                        </div>
                        <div className="hello_topnav_bottombar_bar"></div>
                        <div className="hello_topnav_bottombar_box">
                          <div className="hello_topnav_bottombar_box_icon">
                            <CheckIcon />
                          </div>
                          <div className="hello_topnav_bottombar_box_text">
                            Starten
                          </div>
                        </div>
                        <div className="hello_topnav_bottombar_bar"></div>
                        <div className="hello_topnav_bottombar_box">
                          <div className="hello_topnav_bottombar_box_icon">
                            <ShipmentIcon />
                          </div>
                          <div className="hello_topnav_bottombar_box_text">
                            Produkt
                          </div>
                        </div>
                        <div className="hello_topnav_bottombar_bar"></div>
                        <div className="hello_topnav_bottombar_box">
                          <div className="hello_topnav_bottombar_box_icon">
                            <LiveICon />
                          </div>
                          <div className="hello_topnav_bottombar_box_text">
                            Live!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="home">
          <DesktopTopNavbar
            topnavBusiness={topnavBusiness}
            setTopnavBusiness={setTopnavBusiness}
            authToken={authToken}
            companyData={companyData}
          />
          <div className="home_filterbar">
            {categories.map((category) => (
              <>
                {activeCategory == category ? (
                  <div
                    className="home_filterbar_element_active"
                    onClick={() => setActiveCategory("")}
                  >
                    {category}
                  </div>
                ) : (
                  <div
                    className="home_filterbar_element"
                    onClick={() => setActiveCategory(category)}
                  >
                    {category}
                  </div>
                )}
              </>
            ))}
          </div>
          <div className="home_left">
            <div className="home_left_scrollbar">
              {filteredCreators.map((creator) => (
                <div
                  key={creator.id}
                  className="card"
                  onClick={() => navigate("/creator/" + creator.id)}
                >
                  <div className="card_top">
                    <div className="card_top_imagebox">
                      <img
                        src={
                          isEmpty(creator.pictures)
                            ? ""
                            : JSON.parse(creator.pictures)[0].url
                        }
                        className="card_top_imagebox_img"
                      />
                    </div>
                  </div>
                  <div className="card_bottom">
                    <div className="card_bottom_top">{creator.companyname}</div>
                    <div className="card_bottom_middle">
                      {!isEmpty(creator) && (
                        <FollowerNumber
                          followerInstagram={
                            !isEmpty(creator.followerInstagram)
                              ? creator.followerInstagram
                              : null
                          }
                          followerTiktok={
                            !isEmpty(creator.followerTiktok)
                              ? creator.followerTiktok
                              : null
                          }
                          followerYoutube={
                            !isEmpty(creator.followerYoutube)
                              ? creator.followerYoutube
                              : null
                          }
                          followerFacebook={
                            !isEmpty(creator.followerFacebook)
                              ? creator.followerFacebook
                              : null
                          }
                          followerLinkedIn={
                            !isEmpty(creator.followerLinkedIn)
                              ? creator.followerLinkedIn
                              : null
                          }
                          followerSnapchat={
                            !isEmpty(creator.followerSnapchat)
                              ? creator.followerSnapchat
                              : null
                          }
                        />
                      )}{" "}
                      Follower
                    </div>
                    <div className="card_bottom_middle">
                      {creator.priceInstagramStory} €/Woche
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Footerbar />
          </div>
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const CampaignIcon = styled(MdCampaign)`
  font-size: 33px;
  color: #575757;
`;

const ContentIcon = styled(MdPlayCircle)`
  font-size: 33px;
  color: #575757;
`;

const WebsiteIcon = styled(MdWeb)`
  font-size: 33px;
  color: #575757;
`;

const UploadIcon = styled(MdUploadFile)`
  font-size: 33px;
  color: #575757;
`;

const PersonIcon = styled(MdPerson)`
  font-size: 33px;
  color: #575757;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const CheckIconFilled = styled(MdCheckCircle)`
  font-size: 22px;
  color: #f58700;
`;

const PlaceholderIcon = styled(MdBorderAll)`
  font-size: 44px;
  color: #c2c2c2;
`;

const BookmarkedIcon = styled(MdBookmarkBorder)`
  font-size: 30px;
  color: #ffffff;
`;

const BookmarkedIconFilled = styled(MdBookmark)`
  font-size: 22px;
  color: #f58700;
`;

const BookmarkedMyList = styled(MdBookmarkBorder)`
  font-size: 30px;
  color: #d6d6d6;
`;

const AdIcon = styled(MdVideocam)`
  font-size: 22px;
  color: #ffffff;
`;

const CheckIcon = styled(MdCheck)`
  font-size: 22px;
  color: #ffffff;
`;

const ShipmentIcon = styled(MdLocalShipping)`
  font-size: 22px;
  color: #ffffff;
`;

const LiveICon = styled(MdCampaign)`
  font-size: 22px;
  color: #ffffff;
`;
