import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdCampaign,
  MdMenu,
  MdPerson,
  MdPlayCircle,
  MdSearch,
  MdUpload,
  MdUploadFile,
  MdWeb,
} from "react-icons/md";

import "../styles/Home.css";
import { ServicesBar } from "../components/cards/ServicesBar";
import { isEmpty } from "../functions/isEmpty";
import HomeTopNav from "../components/navbars/DesktopTopNavbar";
import Card from "../components/cards/Card";
import { ProductData } from "../contexts/ProductData";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import MobileCreatorRow from "../components/MobileCreatorRow";
import { useLocation, useNavigate } from "react-router";
import useFetch from "../functions/useFetch";
import DesktopTopNavbar from "../components/navbars/DesktopTopNavbar";
import Footerbar from "../components/navbars/Footerbar";

export default function Collabos({
  isMobile,
  apiURL,
  authToken,
  creators,
  templates,
  companyData,
  topnavBusiness,
  setTopnavBusiness,
  localCollabos,
  setLocalCollabos,
  navigation,
  setNavigation,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  useEffect(() => {
    if (isEmpty(authToken)) {
    }
  }, [authToken, companyData]);

  useEffect(() => {
    if (navigation == "/content") {
      setNavigation("");
    }
  }, [authToken, companyData]);

  const {
    data: buyerCollabos,
    loading: loadingBuyerCollabos,
    error: errorBuyerCollabos,
  } = useFetch(
    `${apiURL}/api/collabos/?buyerID=` + companyData?.id,
    authToken,
    "GET",
    null,
    [companyData, authToken] // Additional dependencies, if needed
  );

  const {
    data: sellerCollabos,
    loading: loadingSellerCollabos,
    error: errorSellerCollabos,
  } = useFetch(
    `${apiURL}/api/collabos/?sellerID=` + companyData?.id,
    authToken,
    "GET",
    null,
    [companyData, authToken] // Additional dependencies, if needed
  );

  return (
    <>
      <div className="home">
        <DesktopTopNavbar
          topnavBusiness={topnavBusiness}
          setTopnavBusiness={setTopnavBusiness}
          authToken={authToken}
          companyData={companyData}
        />
        <div className="home_left">
          <div className="home_left_scrollbar">
            <div className="home_collabos">
              {localCollabos.map((collabo) => (
                <div key={collabo.id}>
                  <div
                    className="home_collabos_collabo"
                    onClick={() => navigate("/content/" + collabo.id)}
                  >
                    <div className="home_collabos_collabo_left">
                      <img
                        src={collabo.sellerImageURL}
                        className="home_collabos_collabo_left_img"
                      />
                    </div>
                    <div className="home_collabos_collabo_right">
                      {collabo.sellerName}
                      <br />
                      {collabo.type}
                      <br />
                      {JSON.parse(collabo.plan).collabo == "requested" &&
                        JSON.parse(collabo.plan).weeks[0].started == 1 &&
                        "Anfrage"}
                      {JSON.parse(collabo.plan).collabo == "accepted" &&
                        "Angenommen"}
                      {JSON.parse(collabo.plan).collabo == "declined" &&
                        "Abgelehnt"}
                    </div>
                  </div>
                </div>
              ))}

              {buyerCollabos.map((collabo) => (
                <div key={collabo.id}>
                  <div
                    className="home_collabos_collabo"
                    onClick={() => navigate("/content/" + collabo.id)}
                  >
                    <div className="home_collabos_collabo_left">
                      <img
                        src={collabo.sellerImageURL}
                        className="home_collabos_collabo_left_img"
                      />
                    </div>
                    <div className="home_collabos_collabo_right">
                      {collabo.sellerName}
                      <br />
                      {collabo.type}
                      <br />
                      {JSON.parse(collabo.plan).collabo == "requested" &&
                        JSON.parse(collabo.plan).weeks[0].started == 1 &&
                        "Anfrage"}
                      {JSON.parse(collabo.plan).collabo == "accepted" &&
                        "Angenommen"}
                      {JSON.parse(collabo.plan).collabo == "declined" &&
                        "Abgelehnt"}
                    </div>
                  </div>
                </div>
              ))}

              {sellerCollabos.map((collabo) => (
                <>
                  {JSON.parse(collabo.plan).collabo == "requested" &&
                  JSON.parse(collabo.plan).weeks[0].started != 1 ? (
                    <></>
                  ) : (
                    <div
                      className="home_collabos_collabo"
                      onClick={() => navigate("/content/" + collabo.id)}
                    >
                      <div className="home_collabos_collabo_left">
                        <img
                          src={collabo.buyerImageURL}
                          className="home_collabos_collabo_left_img"
                        />
                      </div>
                      <div className="home_collabos_collabo_right">
                        {collabo.buyerName}
                        <br />
                        {collabo.type}
                        <br />
                        {JSON.parse(collabo.plan).collabo == "requested" &&
                          JSON.parse(collabo.plan).weeks[0].started == 1 &&
                          "Anfrage"}
                        {JSON.parse(collabo.plan).collabo == "accepted" &&
                          "Angenommen"}
                        {JSON.parse(collabo.plan).collabo == "declined" &&
                          "Abgelehnt"}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
          <Footerbar />
        </div>
      </div>
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const CampaignIcon = styled(MdCampaign)`
  font-size: 33px;
  color: #575757;
`;

const ContentIcon = styled(MdPlayCircle)`
  font-size: 33px;
  color: #575757;
`;

const WebsiteIcon = styled(MdWeb)`
  font-size: 33px;
  color: #575757;
`;

const UploadIcon = styled(MdUploadFile)`
  font-size: 33px;
  color: #575757;
`;

const PersonIcon = styled(MdPerson)`
  font-size: 33px;
  color: #575757;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;
